import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useDebounce} from 'use-debounce';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  searchIcon: {
    color: grey[700]
  }
});

type Props = {
  classes: Object,
  placeholder?: string,
  debounce?: number,
  disableUnderline?: boolean,
  showIcon?: boolean,
  onSearch?: (q: string) => {}
}

const SearchInput = forwardRef((props: Props, ref) => {
  const {classes, placeholder, onSearch, debounce, disableUnderline = true, showIcon = true} = props;
  const _debounce = debounce || 500;
  const [text, setText] = useState('');
  const [value] = useDebounce(text, _debounce);
  const inputRef = useRef();

  useEffect(() => {
    if (onSearch) {
      onSearch(value);
    }

  }, [value, onSearch]);

  const clear = () => {
    setText('');
  };

  const focus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useImperativeHandle(ref, () => ({clear, focus}));

  return (
    <Grid container alignItems="center">
      {showIcon && <Grid item style={{marginRight: 5}}>
        <SearchIcon className={classes.searchIcon}/>
      </Grid>}
      <Grid item xs>
        <TextField
          inputRef={inputRef}
          fullWidth
          placeholder={placeholder}
          value={text}
          onChange={(e) => {
            setText(e.target.value)
          }}
          InputProps={{
            disableUnderline: disableUnderline,
            className: classes.searchInput,
          }}
        />
      </Grid>
    </Grid>
  );
});

export default withStyles(styles)(SearchInput);
