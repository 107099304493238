import React from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';


const ItemSkeleton = () => {
  return (
    <Grid item>
      <Grid container style={{padding: '10px 0'}} spacing={2} alignItems='center'>
        <Grid item>
          <Skeleton style={{marginLeft:15}} variant="circle" width={52} height={52}/>
        </Grid>
        <Grid item xs>
          <Grid item>
            <Skeleton height={10} width="60%"/>
          </Grid>
          <Grid item>
            <Skeleton height={8} width="80%"/>
          </Grid>
        </Grid>
        <Grid item>
          <Skeleton style={{marginRight:10}} height={40} width={70}/>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ItemSkeleton;
