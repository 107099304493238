import {makeStyles} from '@material-ui/styles';

const useDashboardStyles = makeStyles(theme => ({
  root: {
    maxWidth: '936px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  calendar: {
    "& .MuiPickersStaticWrapper-staticWrapperRoot": {
      borderRadius: 8,
      boxShadow: '0 2px 1px 0 rgba(0,0,0,.1)',
      padding: '12px 10px 0',
      '& .MuiPickersDatePickerRoot-toolbar': {
        display: 'none'
      }
    }
  },
  badge: {
    "& .MuiPickersDay-day p": {
      fontWeight: 500,
      color: theme.palette.primary.main
    },
    "& .MuiPickersDay-daySelected p": {
      fontWeight: 500,
      color: '#fff'
    }
  }
}));

export {useDashboardStyles};
