import React, {useState} from 'react';
import classNames from "classnames";
import Grid from '@material-ui/core/Grid';
import {grey} from "@material-ui/core/colors";
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import {makeStyles} from "@material-ui/styles";
import Amount from "./Amount";
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(() => ({
  activity: {
    padding: '5px 10px',
    overflow: 'hidden',
    position: 'relative'
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      background: '#ffccbc30'
    }
  },
  selected: {
    background: '#ffccbc60',
    '&:hover': {
      background: '#ffccbc60'
    }
  },
  avatar: {
    margin: 5,
    width: 48,
    height: 48,
    color: '#FF6131',
    background: '#FFF',
    border: '1px solid #cdcdcd'
  },
}));

type Props = {
  title?: string,
  description?: string | React.Element<any>,
  value?: string,
  icon?: string,
  textIcon?: string,
  selected?: boolean,
  actions?: [],
  onClick?: ()=>any
}

const Item = (props: Props) => {
  const classes = useStyles();
  const {title, description, value, icon, textIcon, selected = false, onClick, actions} = props;
  const [showActions, setShowActions] = useState(false);

  const handleActionClick = (action) => {
    action.onClick();
  };

  const itemClasses = classNames(
    classes.activity,
    selected ? classes.selected : null,
    onClick ? classes.clickable : null
  );

  console.log(icon);


  return (
    <Grid item
          className={itemClasses}
          onClick={onClick}
          onMouseOver={() => actions ? setShowActions(true) : null}
          onMouseOut={() => actions ? setShowActions(false) : null}>
      <Grid container style={{padding: '10px 0'}} spacing={2} alignItems='center'>
        <Grid item>
          {icon && icon.includes('http') &&
          <Avatar className={classes.avatar} src={icon}/>
          }
          {icon && !icon.includes('http') &&
          <Avatar className={classes.avatar}>
            <Icon>{icon}</Icon>
          </Avatar>
          }
          {!icon &&
          <Avatar className={classes.avatar}>
            {textIcon && <span>{textIcon}</span>}
          </Avatar>
          }
        </Grid>
        <Grid item xs>
          <Grid item className={classes.noteWrapper}>
            <Typography variant='h6'
                        style={{color: grey[800], fontSize: '16px'}}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2'>
              {description}
            </Typography>
          </Grid>
        </Grid>
        <Fade in={!showActions}>
          <Grid item>
            <Amount variant='h6' style={{color: grey[800]}}>{value}</Amount>
          </Grid>
        </Fade>
        <Slide direction='left' in={showActions}>
          <Grid item style={{position: 'absolute', right: 0}}>
            {actions && actions.filter(action => !!action).map(action => (
              <Tooltip key={action.title} title={action.title}>
                <IconButton onClick={() => handleActionClick(action)}>
                  <Icon>{action.icon}</Icon>
                </IconButton>
              </Tooltip>
            ))}
          </Grid>
        </Slide>
      </Grid>
    </Grid>
  );
};

export default Item;
