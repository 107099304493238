import {makeStyles} from '@material-ui/styles';

const useDebtStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'block',
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
    padding: 8,
  },
  paper: {
    padding: 40,
    minWidth: 310,
    maxWidth: 500,
    margin: '40px 0'
  },
  avatarIcon: {
    height: 42,
    width: 42,
    marginRight: 20
  },
  logoContainer: {
    display: 'flex',
    marginTop: 40,
    '& img': {
      marginRight: 10
    }
  }
}));

export {useDebtStyles};
