import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

const AppLoader = () => {
  return (
    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
      <CircularProgress/>
    </div>
  );
};

export default AppLoader;
