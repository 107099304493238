import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import {Box, Button, TextField, Typography} from '@material-ui/core';
import {AppContext} from "../../../../app";
import EmailImg from "../../../../common/images/email_verification.png";
import PhoneImg from "../../../../common/images/phone_verification.png";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Transition from "../../../../common/components/Transition";
import {useMutation} from "@apollo/react-hooks";
import {CREATE_USER_VERIFICATION, VERIFY_USER_CODE} from "../../../../common/models/User";
import {useSnackbar} from "notistack";

const Verify = () => {
  const {state, dispatch, actions} = useContext(AppContext);
  const {user} = state;
  const {t} = useTranslation();
  const [verificationTitle, setVerificationTitle] = useState();
  const [verificationDescription, setVerificationDescription] = useState();
  const [showVerificationDialog, setShowVerificationDialog] = useState(false);
  const [validationCode, setValidationCode] = useState('');
  const [createVerification] = useMutation(CREATE_USER_VERIFICATION);
  const [verifyCode] = useMutation(VERIFY_USER_CODE);
  const {enqueueSnackbar} = useSnackbar();

  const handleVerifyEmail = async () => {
    const {errors} = await createVerification({
      variables: {
        type: 'email',
      },
      errorPolicy: 'all',
    });

    if (errors) {
      const [error] = errors;
      enqueueSnackbar(t(`home.verification.dialog.email.error.${error.extensions.code}`));
    } else {
      setVerificationTitle(t('home.verification.dialog.email.title'));
      setVerificationDescription(t('home.verification.dialog.email.description'));
      setShowVerificationDialog(true);
    }
  };

  const handleVerifyPhone = async () => {
    const {errors} = await createVerification({
      variables: {
        type: 'phone',
      },
      errorPolicy: 'all',
    });

    if (errors) {
      const [error] = errors;
      enqueueSnackbar(t(`home.verification.dialog.phone.error.${error.extensions.code}`));
    } else {
      setVerificationTitle(t('home.verification.dialog.phone.title'));
      setVerificationDescription(t('home.verification.dialog.phone.description'));
      setShowVerificationDialog(true);
    }
  };

  const handleClose = () => {
    setValidationCode('');
    setShowVerificationDialog(false);
  };

  const handleValidationCodeChange = (e) => {
    setValidationCode(e.target.value);
  };

  const handleValidate = async () => {
    const {data, errors} = await verifyCode({
      variables: {code: validationCode},
      errorPolicy: 'all',
    });


    if (errors) {
      const [error] = errors;
      handleClose();
      enqueueSnackbar(t(`home.verification.feedback.error.${error.extensions.code}`));
    } else {
      if(data && data.verifyUserCode){
        dispatch(actions.setUser(data.verifyUserCode));
      }
      setShowVerificationDialog(false);
      enqueueSnackbar(t('home.verification.feedback.success'));
    }
  };

  const canSubmit = () => {
    return !!validationCode;
  };

  if (user.emailVerified && user.phoneVerified) {
    return null;
  }

  return (
    <>
      <Grid item>
        <Box mt={3}/>
        <Typography variant='h6'>{t('home.verification.section.title')}</Typography>
      </Grid>
      <Grid item style={{width: '100%'}}>
        <Box elevation={0} style={{padding: '0 10px', margin: '0 10px'}}>
          <Grid container direction='column' spacing={1}>
            {!user.emailVerified &&
            <Grid item>
              <Grid container alignItems='center'>
                <Grid item>
                  <img alt='' src={EmailImg} style={{width: 80}}/>
                </Grid>
                <Grid item>
                  <Button color='primary' onClick={handleVerifyEmail}>{t('home.verification.section.email')}</Button>
                </Grid>
              </Grid>
            </Grid>}
            {!user.phoneVerified &&
            <Grid item>
              <Grid container alignItems='center'>
                <Grid item>
                  <img alt='' src={PhoneImg} style={{width: 80}}/>
                </Grid>
                <Grid item>
                  <Button color='primary' onClick={handleVerifyPhone}>{t('home.verification.section.phone')}</Button>
                </Grid>
              </Grid>
            </Grid>}
          </Grid>
        </Box>
      </Grid>

      <Dialog
        TransitionComponent={Transition}
        open={showVerificationDialog}
        fullWidth={true}
        maxWidth='xs'
        onClose={handleClose}
      >
        <DialogTitle>{verificationTitle}</DialogTitle>
        <DialogContent>
          <Typography>{verificationDescription}</Typography>
          <Box mt={2}/>
          <TextField
            required
            autoFocus
            label={'Enter the verification code'}
            fullWidth
            value={validationCode}
            onChange={handleValidationCodeChange}/>
          <Box mt={2}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancel</Button>
          <Button disabled={!canSubmit()} onClick={handleValidate} color='primary'> Validate</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Verify;
