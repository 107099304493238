export const es = {
  translation: {
    common: {
      and: 'y',
      activity: "Actividad",
      addContacts: 'Agregar contactos',
      addNew: 'Añadir nuevo',
      all: 'Todo',
      amount: "Cantidad",
      biweekly: 'Quincenal',
      cancel: "Cancelar",
      completed: 'Completado',
      config: 'Configuracion',
      contact: 'Contacto',
      contacts: "Contactos",
      currency: 'Moneda',
      day: 'Dia',
      debt: "Deuda",
      debts: "Deudas",
      delete: 'Eliminar',
      disabled: 'Deshabilitado',
      edit: 'Editar',
      editSelection: 'Editar seleccion',
      email: 'Correo',
      emailNotifications: 'Notificar contactos por correo',
      for: 'de',
      language: 'Idioma',
      loadMore: 'Mostrar mas',
      monthly: 'Mensual',
      name: 'Nombre',
      next: 'Siguiente',
      notes: "Notas",
      pay: "Pagar",
      payment: 'Pago',
      pending: 'Pendiente',
      phone: 'Telefono',
      private: 'Privado',
      profile: 'Perfil',
      receipt: 'Recibo',
      recurrence: 'Recurrencia',
      reminders: 'Recordatorios',
      remindersEnd: 'Los recordatorios terminan el',
      remindersStart: 'Los recordatorios comienzan el',
      requests: 'Adeudado',
      requestsBy: 'Adeudado a',
      save: 'Guardar',
      saving: 'Guardando',
      schedule: 'Calendario',
      schedules: "Programar",
      smsNotifications: 'Notificar contactos por sms',
      summary: "Sumario",
      until: 'Hasta',
      total: 'Total',
      weekly: 'Semanal',
      welcome: "Bienvenido",
      yes: 'Si',
      error: {
        title: 'Ha ocurrido un error!',
        description: 'Ha ocurrido un error. Intente mas tarde!'
      },
      confirmation: "Confirmación",
      terms: 'Términos de Servicio',
      privacy: 'Política de Privacidad',
      userAvatar: {
        error: 'Error al subir la foto',
        title: 'Que desea hacer?',
        upload: 'Subir foto nueva',
        delete: 'Eliminar foto actual'
      }
    },
    languages: {
      en: 'Ingles',
      es: 'Español'
    },
    home: {
      addNew: 'Agregar nuevo',
      scheduledDebts: 'Deudas programadas',
      pendingPayments: 'Pagos pendientes',
      pendingDebts: 'Deudas pendientes',
      latestActivity: 'Actividad reciente',
      closeDebt: 'Cerrar deuda',
      confirmPayment: 'Confirmar pago',
      rejectPayment: 'Rechazar pago',
      balanceDescription: 'Este balance muestra cuanto se te debe',
      actions: 'Acciones pendientes',
      notifiedPayments: 'Pagos pendientes por revisar',
      declinedDebts: 'Deudas rechazadas por revisar',
      info: {
        actions: 'Acciones que necesitas completar',
        requests: 'Deudas creadas por otros usuarios y asignadas a mi',
        pendingPayments: 'Pagos notificados por los contactos que necesitas confirmar',
        pendingDebts: 'Deudas pendientes a pagar por los contactos'
      },
      error: {
        title: 'Ha ocurrido un error!',
        description: 'Ha ocurrido un error. Intente mas tarde!'
      },
      empty: {
        title: 'No tienes deudas activas',
        message: 'Empieza a añadir algunas deudas para enviar recordatorios, seguir deudores y ver tu balance.',
      },
      verification: {
        section: {
          title: 'Verificar',
          email: 'Verificar correo electrónico',
          phone: 'Verificar número telefónico'
        },
        dialog: {
          email: {
            title: 'Verificación de correo',
            description: 'Se le ha enviado un correo electrónico. Revise su buzón para más información.',
            error: {
              'INVALID_DATA':'Correo electronico no valido!',
              'INTERNAL_SERVER_ERROR': 'Ha ocurrido un error. Intente despues.'
            }
          },
          phone: {
            title: 'Verificación de número telefónico',
            description: 'Se le ha enviado un mensaje de texto. Revise su telefono para más información',
            error: {
              'INVALID_DATA':'Número telefónico no valido!',
              'INTERNAL_SERVER_ERROR': 'Ha ocurrido un error. Intente despues.'
            }
          }
        },
        feedback: {
          success: 'El código insertado se ha verificado correctamente!',
          error: {
            'INVALID_ID':'El código insertado no es válido!',
            'INVALID_DATA':'El código insertado no es válido!'
          }
        }
      }
    },
    debts: {
      debtDetails: 'Detalles de la deuda',
      debtCreation: 'La deuda será creada el',
      close: 'Cerrar deuda',
      restore: 'Restaurar deuda',
      confirmation: {
        paymentConfirm: {
          title: 'Confirmar pago',
          message: 'Está seguro que desea confirmar este pago?',
        },
        paymentReject: {
          title: 'Rechazar pago',
          message: 'Está seguro que desea rechazar este pago?',
        },
        payInFull: {
          title: 'Pagar completamente',
          message: 'Está seguro que desea pagar la deuda completamente?',
        },
        decline: {
          title: 'Rechazar deuda',
          message: 'Está seguro que desea rechazar esta deuda?',
        },
        close: {
          title: 'Cerrar deuda',
          message: 'Está seguro que desea cerrar esta deuda?',
        },
        restore: {
          title: 'Restaurar deuda',
          message: 'Está seguro que desea restaurar esta deuda?',
        }
      },
      feedback: {
        paymentConfirm: 'Pago confirmado!',
        paymentReject: 'Pago rechazado!',
        close: 'La deuda fue cerrada correctamente',
        restore: 'La deuda fue restaurada correctamente',
        declineDebt: 'La deuda ha sido declinada',
      },
      editor: {
        toggle: 'Selector para dividir el total entre contactos o asignar el total a cada contacto',
        private: 'Evita que aparezca en la cuenta de usuario del contacto'
      },
      deleteWarning: {
        title: 'Eliminar deuda',
        message: 'Está seguro que desea eliminar la deuda?',
      },
      empty: {
        title: 'No se encontraron deudas activas',
        message: 'Parece que no tienes deudas activas, regresa al inicio para crear algunas deudas.',
      }
    },
    schedules: {
      details: 'Detalles del calendario',
      startingAtDay: 'Empezando el dia',
      createdOn: 'La deuda sera creada el dia',
      notRepeat: 'No repetir',
      biweekly: 'Cada 2 semanas',
      monthly: 'Cada mes',
      recurrence: {
        future: 'La deuda sera creada el {{date}}',
        monthly: 'Deudas mensuales el dia {{day}}',
        biweekly: 'Deudas mensuales los dias {{day1}},  {{day2}}',
      },
      deleteWarning: {
        title: 'Eliminar calendario',
        message: 'Esta seguro que desea eliminar el calendario?',
      },
      empty: {
        title: 'No se encontraron calendarios',
        message: 'Para evitar crear deudas periódicamente, puedes crear calendarios que te crearan las deudas automáticamente.'
      }
    },
    activity: {
      created: "Deuda creada",
      updated: "Debt Actualizada",
      email_reminder: "Email reminder #{{reminder}} to {{contact}}",
      text_reminder: "Text reminder #{{reminder}} to {{contact}}",
      contact_payment_created: "Notificación de {{amount}} por {{contact}}",
      contact_payment_rejected: "Pago rechazado de {{amount}} a {{contact}}",
      contact_payment_confirmed: "Pago confirmado de {{amount}} a {{contact}}",
      contact_completed: "Deuda de {{amount}} terminada para {{contact}}",
      closed: "Deuda cerrada",
      removed: "Deuda eliminada",
      archived: "Deuda archivada",
      details: "Actividad Detallada",
      empty: {
        title: 'Sin actividad',
        message: 'Debe comenzar a agregar deudas para ver la actividad generada por sus acciones.',
      },
      status: {
        created: 'Deuda creada',
        updated: 'Deuda editada',
        email_reminder: 'Recordatorio por email #{{reminder}} a {{contact}}',
        text_reminder: 'Recordatorio por texto #{{reminder}} a {{contact}}',
        payment: 'Notificación de {{amount}} por {{contact}}',
        rejected: 'Pago rechazado por {{amount}} a {{contact}}',
        confirmation: 'Pago confirmado por {{amount}} a {{contact}}',
        closed_contact: 'Deuda de {{amount}} terminada para {{contact}}',
        closed: 'Deuda cerrada',
        removed: 'Deuda eliminada',
        archived: 'Deuda archivada',
      }
    },
    requests: {
      empty: {
        title: 'Adeudado',
        message: 'Deudas creadas por otros usuarios y asignadas a mi.',
      },
    },
    contacts: {
      find: 'Buscar contactos',
      details: 'Detalles del contacto',
      editor: {
        add: 'Agregar contacto',
        edit: 'Editar contacto',
      },
      deleteWarning: {
        title: 'Eliminar contacto',
        message: 'Esta seguro que desea eliminar el contacto?',
      },
    },
    contact: {
      hello: 'Hola {{contact}}',
      nameSubtitle: 'Tienes la siguiente deuda',
      disclaimer: 'Si crees que hay algo incorrecto puedes contactar directamente a {{user}} con la siguiente información.',
      completed: 'Nos place informarte que tu deuda esta completada.',
      notFound: 'Sentimos informarte que esta deuda no esta disponible.',
      stores: '¿No tienes el app aún?',
      pay: {
        full: 'Pagar completa',
        partial: 'Realizar un pago parcial',
        decline: 'Rechazar deuda',
        title: '¿Cuanto deseas pagar?',
        errorTitle: 'Ha ocurrido un error',
        successTitle: 'Listo!',
        invalid: 'Pago incorrecto!',
        success: 'Le notificaremos a tu deudor que has realizado este pago',
        confirmation: {
          title: 'Confirmar',
          description: 'Está seguro de que desea pagar la deuda completamente?'
        }
      },
      payments: {
        buttonTitle: 'Ver pagos anteriores',
        dialogTitle: 'Pagos anteriores',
      },
      unsubscribe: 'desuscribirte',
      unsubscribe_message: 'Si no deseas recibir estos mensajes de Debtrail en el futuro puedes  ',
      unsubscribe_dialog: '¿Estás seguro que no deseas recibir mas correos electrónicos de Debtrail?',
      unsubscribe_feedback: 'Te has desuscrito de las notificaciones por correo electrónico.',
      resubscribe: 'resuscribirte',
      resubscribe_message: ' para retomar las notificaciones por correo electrónico puedes ',
      resubscribe_feedback: 'The has suscrito correctamente a las notificaciones por correo electrónico.',
    },
    contact_hello: 'Hola {{contact}}',
    contact_name_subtitle: 'Tienes la siguiente deuda',
    contact_pay_full: 'Pagar completa',
    contact_pay_partial: 'Realizar un pago parcial',
    contact_pay_error_title: 'Ha ocurrido un error',
    contact_pay_success_title: 'Listo!',
    contact_pay_title: 'Cuanto deseas pagar?',
    contact_pay_invalid: 'Pago incorrecto!',
    contact_pay_success: 'Le notificaremos a tu deudor que has realizado este pago',
    contact_payments: 'Ver pagos anteriores',
    contact_payments_title: 'Pagos anteriores',
    contact_disclaimer: 'Si crees que hay algo incorrecto puedes contactar directamente a {{user}} con la siguiente información.',
    contact_not_found: 'Sentimos informarte que esta deuda no esta disponible.',
    contact_completed: 'Nos place informarte que tu deuda está completada.',
    contact_stores: '¿No tienes el app aún?'
  }
};
