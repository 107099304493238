import React from 'react';
import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import Select from "@material-ui/core/Select";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from "@material-ui/core/Icon";
import {useTranslation} from "react-i18next";

type Props = {
  value: string,
  onChange: ()=>{}
}
const RemainderSelector = (props: Props) => {
  const {value, onChange} = props;
  const {t} = useTranslation();

  return (
    <Grid item container alignItems='center' justify='center' spacing={3}>
      <Grid item>
        <Icon color='disabled'>timelapse</Icon>
      </Grid>
      <Grid item>
        <FormControl>
          <InputLabel htmlFor="age-simple">{t('common.reminders')}</InputLabel>
          <Select
            style={{width: 280}}
            inputProps={{
              name: 'remainders',
            }}
            value={value}
            onChange={onChange}
          >
            <MenuItem value='disabled'>{t('common.disabled')}</MenuItem>
            <MenuItem value='weekly'>{t('common.weekly')}</MenuItem>
            <MenuItem value='biweekly'>{t('common.biweekly')}</MenuItem>
            <MenuItem value='monthly'>{t('common.monthly')}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default RemainderSelector;
