import {gql} from "apollo-boost";

export const GET_EVENTS = gql`
query GetEvents($full:Boolean, $filter:EventsFilter, $limit:Int, $offset:Int){
  events (full: $full, filter: $filter, limit:$limit, offset:$offset) {
    count
    items{
      _id
      uid
      action
      debt
      total
      date
      data
      payment {
        amount
        payment
      }
      contact {
        contact {
          _id
          name
          picture
        }
        amount
        pending
      }
    }
  }
}`;

export default class Event {
  _id;
  uid;
  action = '';
  debt = '';
  total = '';
  date = new Date();
}
