import React, {Fragment, useContext, useState} from 'react';
import {Redirect} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";

import logoImg from '../../common/images/debtrail.png';
import Welcome from '../../common/components/Welcome'
import useRouter from "../../common/hooks/useRouter";

import {useForgotPasswordStyles} from "./ForgotPasswordStyles";
import {AppContext} from '../../app/AppContext';

function ForgotPassword(props) {
  const classes = useForgotPasswordStyles();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const {state, dispatch, actions} = useContext(AppContext);
  const {history} = useRouter();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const success = await dispatch(actions.forgotPassEmail(email));
    if (success) {
      setEmailSent(true);
    }
  };

  const handleSignIn = () => {
    dispatch(actions.clear());
    history.push('/signin');
  };

  let {from} = props.location.state || {from: {pathname: "/"}};
  if (state.user) return <Redirect to={from}/>;

  return (
    <Welcome>
      <img alt='' className={classes.avatar} src={logoImg}/>
      <Typography component="h1" variant="h5" color={"textSecondary"}>Forgot Password</Typography>
      {state.error !== '' &&
      <Typography component="h5" className={classes.errorMessage}>{state.error}</Typography>}
      {!emailSent && <Fragment>
        <Typography component="h2" variant="h6" style={{marginTop: 30}}>Dont remember your password?</Typography>
        <Typography component="p" variant="body2" color={"textSecondary"} align={"center"}>No Problem. We will email you
          instructions for resetting your password</Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="username">Email</InputLabel>
            <Input disabled={state.loading}
                   id="email"
                   name="email"
                   autoComplete="current-email"
                   autoFocus value={email}
                   onChange={(event) => setEmail(event.target.value)}/>
          </FormControl>
          <Grid container direction={"column"} className={classes.wrapper} justify={"center"}>
            <Button disabled={state.loading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
            >
              Reset Password
            </Button>
            <Button color="primary" className={classes.button} style={{marginTop: 10}} onClick={handleSignIn}> I
              REMEMBER, TAKE ME BACK
            </Button>
          </Grid>
        </form>
      </Fragment>}

      {emailSent && <Fragment>
        <Typography component="p" variant="h6" color={"textSecondary"} align={"center"} style={{marginTop: 30}}>
          We sent an email with instructions about how reset your password</Typography>
        <Button color="primary" className={classes.button} style={{marginTop: 40}} onClick={handleSignIn}> Sign
          In </Button>
      </Fragment>}
    </Welcome>
  );
}

export {ForgotPassword};
