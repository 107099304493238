import React, {Fragment, useEffect, useState} from 'react';
import Moment from 'react-moment';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {Link} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Paper from '@material-ui/core/Paper';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import {GET_DEBTS, REMOVE_DEBT} from '../../../common/models/Debt';
import {Content} from '../../../common/components/Content';
import Item from '../../../common/components/Item';
import Amount from '../../../common/components/Amount';
import Empty from '../../../common/components/Empty';

import {useDebtsStyles} from './DebtsStyles';

import EmptyDebtsImg from '../../../common/images/debts_empty.png';
import helpers from '../../../common/helpers';
import Alert from '../../../common/components/Alert';
import {useTranslation} from 'react-i18next';
import ItemSkeleton from '../../../common/components/ItemSkeleton';

const Debts = (props) => {
  const {history} = props;
  const classes = useDebtsStyles();
  const [selectedStatus, setSelectedStatus] = useState('all');
  const limit = 10;
  const filter = selectedStatus !== 'all' ? {status: selectedStatus} : null;
  const [offset, setOffset] = useState(0);
  const [debts, setDebts] = useState([]);
  const {loading, error, data, refetch} = useQuery(GET_DEBTS,
    {
      variables: {filter, limit, offset},
      onCompleted: (data) => {
        const _debts = data && data.debts && data.debts.items;
        setDebts([...debts, ..._debts]);
      },
    }
  );
  const [deleteDebt] = useMutation(REMOVE_DEBT);
  const totalDebts = data && data.debts && data.debts.count ? data.debts.count : 0;
  const [selected, setSelected] = useState();
  const [deletingDebt, setDeletingDebt] = useState();
  const [mounted, isMounted] = useState(false);
  const {t} = useTranslation();

  if (!mounted) {
    refetch().then();
  }

  useEffect(() => {
    isMounted(true);
  }, []);

  const handleChangeStatus = (status) => {
    setDebts([]);
    setOffset(0);
    setSelectedStatus(status);
  };

  const handleAddMore = () => {
    setOffset(debts.length);
  };

  const handleEdit = (id) => {
    history.push(`/user/debts/${id}`);
  };

  const handleDeleteDebt = async () => {
    await deleteDebt({
      variables: {id: deletingDebt},
      refetchQueries: [{query: GET_DEBTS, variables: {filter}}],
      awaitRefetchQueries: true
    });
    setDeletingDebt(null);
    setSelected(null);
  };

  return (
    <Content>
      <Grid container style={{maxWidth: 1024, margin: 'auto', marginBottom: 20}}>
        <Grid item xs style={{paddingRight: 20}}>
          <Grid item container direction='row' justify='space-between'>
            <Grid item>
              <Chip
                label={t('common.all')}
                className={classes.chip}
                component="a"
                clickable
                variant="outlined"
                onClick={() => handleChangeStatus('all')}
                color={selectedStatus === 'all' ? 'primary' : 'default'}
              />
              <Chip
                label={t('common.pending')}
                className={classes.chip}
                component="a"
                clickable
                variant="outlined"
                color={selectedStatus === 'pending' ? 'primary' : 'default'}
                onClick={() => handleChangeStatus('pending')}
              />
              <Chip
                label={t('common.completed')}
                className={classes.chip}
                component="a"
                clickable
                variant="outlined"
                color={selectedStatus === 'completed' ? 'primary' : 'default'}
                onClick={() => handleChangeStatus('completed')}
              />
            </Grid>
            <Grid item>
              <Link to='debts/new' className={classes.link}>
                <Button variant='contained' color='primary'>{t('common.addNew')}</Button>
              </Link>
            </Grid>
          </Grid>
          <Box p={2}/>
          <Paper elevation={0}>
            <Box p={2}>
              {debts.length === 0 && loading && <><ItemSkeleton/> <ItemSkeleton/> <ItemSkeleton/></>}
              {error && <p>Error :</p>}

              {debts.length > 0 && <Grid item container direction='column'>
                {debts && debts.map((debt, i) =>
                  <Fragment key={debt._id}>
                    <Item title={`${t('common.debt')} #${debt.uid}`}
                          description={<>{debt.notes} @ <Moment format='LL'>{debt.date}</Moment></>}
                          icon={helpers.getStatusIcon(debt.status)}
                          value={`$${debt.total.toFixed(2)}`}
                          selected={selected && debt._id === selected._id}
                          onClick={() => setSelected(debt)}
                    />
                    {!(i === (debts.length - 1)) && <Divider light/>}
                  </Fragment>
                )}
              </Grid>}

              {debts.length > 0 && loading && <><ItemSkeleton/> <ItemSkeleton/> <ItemSkeleton/></>}

              {!loading && debts.length === 0 && <Empty image={EmptyDebtsImg} section='debts'/>}
            </Box>
          </Paper>
          {debts && debts.length > 0 && totalDebts > debts.length &&
          <Box mt={4} textAlign='center'>
            <Button variant='outlined'
                    color='primary'
                    disabled={loading}
                    onClick={handleAddMore}>{t('common.loadMore')}...</Button>
          </Box>}
        </Grid>

        <Grid item xs={5} style={{paddingLeft: 20}}>
          {selected &&
          <Box position='fixed' minWidth='300px' height='calc(100% - 110px)'>
            <Grid container wrap='nowrap' direction='column' spacing={2} style={{height: '100%'}}>
              <Grid item container alignItems='center'>
                <Grid item xs>
                  <Typography variant='h6'>{t('debts.debtDetails')}</Typography>
                </Grid>
                <Grid item>
                  {!selected.locked && !(selected.receipt && selected.receipt.total > 0) &&
                  <IconButton onClick={() => handleEdit(selected._id)}>
                    <Icon>edit</Icon>
                  </IconButton>}
                  {!(selected.receipt && selected.receipt.total > 0) &&
                  <IconButton onClick={() => setDeletingDebt(selected._id)}>
                    <Icon>delete</Icon>
                  </IconButton>}
                </Grid>
              </Grid>
              <Grid item>
                <Typography>
                  {t('common.debt')} #{selected.uid}
                </Typography>
              </Grid>
              <Grid item>
                <Box m={2}/>
                <Amount variant='h4'>{selected.total}</Amount>
              </Grid>
              <Grid item>
                <Box m={2}/>
                <Divider light/>
              </Grid>
              <Grid item style={{height: '100%', overflow: 'auto'}}>
                <Box m={1}/>
                {selected.contacts.length > 0 && <Typography variant='subtitle1'>
                  {t('common.contacts')}
                </Typography>}
                <Box m={1}/>
                <List>
                  {selected.contacts.map(debtContact => (
                    <ListItem key={debtContact.uid}>
                      <ListItemAvatar>
                        <Avatar className={classes.sidebarAvatar}>
                          {debtContact.contact.name.charAt(0)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={debtContact.contact.name}
                                    secondary={debtContact.contact.phone ?
                                      debtContact.contact.phone :
                                      debtContact.contact.email}/>
                      <ListItemSecondaryAction>
                        <Amount variant='h6'>{debtContact.amount}</Amount>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Box>
          }
        </Grid>

        <Alert title={t('debts.deleteWarning.title')}
               show={!!deletingDebt}
               okAction={handleDeleteDebt}
               okActionText={t('common.yes')}
               cancelActionText='No'
               cancelAction={() => {
                 setDeletingDebt(null);
               }}>
          {t('debts.deleteWarning.message')}
        </Alert>

      </Grid>


    </Content>
  );
};

export default Debts;
