import React from 'react';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

type Props = {
  title: string,
  description?: string,
}

const WidgetHeader = (props: Props) => {
  const {title, description} = props;

  return (
    <Grid container alignItems='center'>
      <Grid item xs>
        <Typography variant='h6' color='textSecondary'>{title}</Typography>
      </Grid>
      {description && <Grid item>
        <Typography color='textSecondary'>
          <Tooltip title={description}>
            <Icon style={{transform: 'rotate(180deg)'}}>error_outline</Icon>
          </Tooltip>
        </Typography>
      </Grid>}
    </Grid>
  );
};

export default WidgetHeader;
