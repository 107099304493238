import React, {useEffect, useState} from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';

import FormField from "../../../common/components/FormField";
import helpers from "../../../common/helpers";
import Transition from "../../../common/components/Transition";
import {useTranslation} from "react-i18next";
import UploadAvatar from "../../../common/components/UploadAvatar";


const ContactEditor = (props) => {
  const {t} = useTranslation();

  const {
    show,
    contact: _contact = {
      _id: '',
      name: '',
      phone: '',
      email: '',
      language: 'en'
    },
    onSubmit,
    cancel
  } = props;

  const [contact, setContact] = useState(_contact);

  useEffect(() => {
    if (_contact && _contact._id !== contact._id) {
      setContact({...contact, ..._contact})
    }
  }, [_contact, contact, setContact]);

  const getErrors = (field) => {

    const checkNameErrors = (name) => {
      if (!name) {
        return 'Name is required'
      }
      return false
    };
    const checkEmailErrors = (email) => {
      const validateEmail = RegExp(/[^@]+@[^]+\..+/g);
      if (!email) {
        return 'Email is required'
      }
      if (!validateEmail.test(email)) {
        return 'Email is poorly formatted'
      }
      return false
    };
    const checkPhoneErrors = (phone) => {
      const nonFormatted = (phone)
        .replace('+', '')
        .replace('(', '')
        .replace(')', '')
        .replace(RegExp(/-/g), '')
        .replace(RegExp(/ /g), '');
      const validatePhone = RegExp(/^\d{10}$|^\d{11}$/g);

      if (phone && !validatePhone.test(nonFormatted)) {
        return 'Phone is poorly formatted'
      }
      return false
    };

    switch (field) {
      case 'name':
        return checkNameErrors(contact.name);
      case 'email':
        return checkEmailErrors(contact.email);
      case 'phone':
        return checkPhoneErrors(contact.phone);
      default:
        return {
          name: checkNameErrors(contact.name),
          email: checkEmailErrors(contact.email),
          phone: checkPhoneErrors(contact.phone),
        };
    }
  };

  return (
    <Dialog open={show} onClose={cancel}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth='sm'>
      <DialogTitle id="form-dialog-title">
        {_contact && _contact._id !== '' ?
          t('contacts.editor.edit') :
          t('contacts.editor.add')}
      </DialogTitle>
      <DialogContent>
        <Grid container direction='column' alignItems='center' spacing={2}
              style={{marginBottom: 10}}>

          <UploadAvatar onChange={(picture) => {
            setContact({...contact, picture})
          }} picture={contact.picture}/>
          <Box m={2}/>

          <FormField icon='person' error={getErrors('name')} align="flex-end"
          >
            <TextField
              margin="dense"
              label={`${t('common.name')}*`}
              fullWidth
              value={contact.name}
              onChange={(e) => {
                setContact({...contact, name: e.target.value})
              }}
            />
          </FormField>

          <FormField icon='email'
                     error={getErrors('email')}
                     align="flex-end">
            <TextField
              margin="dense"
              label={`${t('common.email')}*`}
              type="email"
              fullWidth
              value={contact.email}
              onChange={(e) => {
                setContact({...contact, email: e.target.value})
              }}
            />
          </FormField>

          <FormField icon='phone'
                     error={getErrors('phone')}
                     align="flex-end">
            <TextField
              margin="dense"
              label={`${t('common.phone')}`}
              fullWidth
              value={contact.phone}
              onChange={(e) => {
                setContact({...contact, phone: e.target.value})
              }}
            />
          </FormField>

          <FormField icon="language" error={false} align="flex-end">
            <InputLabel htmlFor="language">{`${t('common.language')}`}</InputLabel>
            <Select
              fullWidth
              inputProps={{
                name: 'language',
              }}
              value={contact.language}
              onChange={(e) => {
                setContact({...contact, language: e.target.value})
              }}
            >
              <MenuItem value="en">{t('languages.en')} (en)</MenuItem>
              <MenuItem value="es">{t('languages.es')} (es)</MenuItem>
            </Select>
          </FormField>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>
          {t('common.cancel')}
        </Button>
        <Button disabled={!helpers.canSubmit(getErrors())} onClick={() => {
          onSubmit(contact);
        }} color="primary">
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactEditor;
