import React, {Fragment, useState} from 'react';
import {useMutation, useQuery} from "@apollo/react-hooks";

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Paper from '@material-ui/core/Paper';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import SearchInput from "../../../common/components/SearchInput";
import Alert from "../../../common/components/Alert";
import Item from "../../../common/components/Item";
import {Content} from "../../../common/components/Content";
import {
  ContactInput,
  CREATE_CONTACT,
  DELETE_CONTACT,
  GET_CONTACTS,
  UPDATE_CONTACT
} from "../../../common/models/Contact";
import helpers from "../../../common/helpers";
import {GET_DEBTS} from "../../../common/models/Debt";
import Amount from "../../../common/components/Amount";

import ContactEditor from "./ContactEditor";
import {useContactsStyles} from "./ContactsStyles";
import {useTranslation} from "react-i18next";
import Moment from "react-moment";
import ItemSkeleton from "../../../common/components/ItemSkeleton";


const getTotalByContact = (debts, contact) => {
  let total = 0;
  if (debts) {
    debts.forEach(debt => {
      debt.contacts.forEach(debtContact => {
        if (debtContact.contact._id === contact._id) {
          total += debtContact.amount;
        }
      });
    });
  }

  return total;
};

const getAmountByContact = (debt, contact) => {
  let amount = 0;
  debt.contacts.forEach(debtContact => {
    if (debtContact.contact._id === contact._id) {
      amount = debtContact.amount;
    }
  });
  return amount;
};

const getContactDebts = (debts, contact) => {
  if (!debts) {
    return [];
  }

  return debts.filter(debt => {
    for (let debtContact of debt.contacts) {
      if (debtContact.contact._id === contact._id) {
        return true;
      }
    }
    return false;
  });
};

const filterContacts = (contacts, search) => {
  if (contacts.length) {
    return contacts.filter(contact =>
      contact.name.toLowerCase().indexOf(search) >= 0)
      .map(contact => {
        contact.actions = '';
        return contact;
      })
  }
  return [];
};

const Contacts = () => {
  const classes = useContactsStyles();

  const [createContact] = useMutation(CREATE_CONTACT);
  const [updateContact] = useMutation(UPDATE_CONTACT);
  const [deleteContact] = useMutation(DELETE_CONTACT);
  const {data: debtsData} = useQuery(GET_DEBTS, {variables: {active: true}});
  const {loading, error, data} = useQuery(GET_CONTACTS);
  const debts = debtsData && debtsData.debts ? debtsData.debts.items : [];
  const contacts = data && data.contacts ? data.contacts : [];

  const [search, setSearch] = useState('');
  const [addingContact, setAddingContact] = useState(false);
  const [editingContact, setEditingContact] = useState();
  const [deletingContact, setDeletingContact] = useState();
  const [selected, setSelected] = useState();

  const filteredContacts = filterContacts(contacts, search);
  const {t} = useTranslation();


  const handleAddContact = async (contact) => {
    delete contact._id;
    await createContact({
      variables: {contact: contact},
      refetchQueries: [{query: GET_CONTACTS}]
    });
    setAddingContact(null);
  };

  const handleUpdateContact = async (contact) => {
    await updateContact({
      variables: {id: contact._id, contact: new ContactInput(contact)},
      refetchQueries: [{query: GET_CONTACTS}]
    });
    setEditingContact(null);
  };

  const handleDeleteContact = async () => {
    await deleteContact({
      variables: {id: deletingContact},
      update: cache => {
        const {contacts} = cache.readQuery({query: GET_CONTACTS});
        cache.writeQuery({
          query: GET_CONTACTS,
          data: {contacts: contacts.filter(contact => contact._id !== deletingContact)},
        });
        setDeletingContact(null);
      }
    });
  };

  const getContactDetails = (contact) => {
    return `${contact.phone !== null ? contact.phone : ''}
    ${contact.phone && contact.email ? '|' : ''}
    ${contact.email}`
  };

  console.log(contacts)

  return (
    <Content>
      <ContactEditor
        show={!!addingContact}
        onSubmit={handleAddContact}
        cancel={() => {
          setAddingContact(false)
        }}/>

      <ContactEditor
        contact={editingContact}
        show={!!editingContact}
        onSubmit={handleUpdateContact}
        cancel={() => {
          setEditingContact(null);
        }}/>

      <Alert title={t('contacts.deleteWarning.title')}
             show={!!deletingContact}
             okAction={handleDeleteContact}
             cancelAction={() => {
               setDeletingContact(null);
             }}>
        {t('contacts.deleteWarning.message')}
      </Alert>

      <Grid container style={{maxWidth: 1024, margin: 'auto', marginBottom: 20}}>
        <Grid item xs style={{paddingRight: 20}}>
          <Grid item container direction='row' justify='space-between'>
            <Grid item>
              <SearchInput
                placeholder={t('contacts.find')}
                onSearch={(q) => {
                  setSearch(q)
                }}/>
            </Grid>
            <Grid item>
              <Button variant='contained'
                      color='primary'
                      onClick={() => setAddingContact(true)}>
                {t('common.addNew')}
              </Button>
            </Grid>
          </Grid>
          <Box p={2}/>
          {contacts && contacts.length > 1 &&
          <Paper elevation={0}>
            <Box p={2}>
              {loading && <><ItemSkeleton/> <ItemSkeleton/> <ItemSkeleton/></>}
              {error && <p>Error :</p>}

              <Grid item container direction='column'>
                {filteredContacts.map((contact, i) =>
                  <Fragment key={contact._id}>
                    {!contact.myself &&
                    <Item title={contact.name}
                          description={getContactDetails(contact)}
                          icon={contact.myself ? 'star' : contact.picture}
                          textIcon={!contact.myself ? contact.name.charAt(0) : null}
                          selected={selected && selected._id === contact._id}
                          onClick={() => setSelected(contact)}
                    />}
                    {!(i === (filteredContacts.length - 1)) && !contact.myself && <Divider light/>}
                  </Fragment>
                )}
              </Grid>
            </Box>
          </Paper>
          }
        </Grid>

        <Grid item xs={5} style={{paddingLeft: 20}}>
          {selected &&
          <Box position='fixed'
               minWidth='300px'
               maxWidth={360}
               width='100%'
               height='calc(100% - 110px)'>
            <Grid container wrap='nowrap' direction='column' spacing={2} style={{height: '100%'}}>
              <Grid item container alignItems='center' justify='space-between'>
                <Grid item xs>
                  <Typography variant='h6'>{t('contacts.details')}</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => setEditingContact(selected)}>
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton onClick={() => setDeletingContact(selected)}>
                    <Icon>delete</Icon>
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item>
                <Typography>
                  {selected.name}
                </Typography>
              </Grid>
              <Grid item>
                <Box m={2}/>
                <Amount variant='h4'>{getTotalByContact(debts, selected)}</Amount>
              </Grid>
              <Grid item>
                <Box m={2}/>
                <Divider light/>
              </Grid>
              <Grid item style={{height: '100%', overflow: 'auto'}}>
                <Box m={1}/>
                <Typography variant='subtitle1'>
                  {t('common.debts')}
                </Typography>
                <Box m={1}/>
                <List>
                  {getContactDebts(debts, selected).map(debt => (
                    <ListItem key={debt._id}>
                      <ListItemAvatar>
                        <Avatar className={classes.sidebarAvatar}>
                          <Icon>{helpers.getStatusIcon(debt.status)}</Icon>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={debt.uid}
                                    secondary={<>
                                      {debt.notes} @ <Moment format='L'>{debt.date}</Moment>
                                    </>}/>
                      <ListItemSecondaryAction>
                        <Amount variant='h6'>{getAmountByContact(debt, selected)}</Amount>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Box>
          }
        </Grid>

      </Grid>

    </Content>
  );
};

export default Contacts;
