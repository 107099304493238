import React, {Fragment, useContext, useEffect} from 'react';
import {useTranslation} from "react-i18next";

import {makeStyles} from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import MenuIcon from '@material-ui/icons/Menu';
import {Button, Icon, Typography} from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import useRouter from "../common/hooks/useRouter";
import logoImg from "../common/images/debtrail_white.png";

import {AppContext} from "./AppContext";


const lightColor = 'rgba(255, 255, 255, 0.7)';

const useHeaderAppStyles = makeStyles(theme => ({
  appBar: {
    backgroundImage: 'linear-gradient(100deg, #ff6333 0%, #ff3d00 100%)',
    height: '65px',
      display: 'flex',
      alignItems: 'center',
      boxShadow: 'none !important',
      border: 'none !important'
    },
    toolbar: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '1024px'
    },
    separator: {
      flex: 1
    },
    secondaryBar: {
      zIndex: 0,
      paddingBottom: '10px',
    },
    menuButton: {
      marginLeft: -theme.spacing(),
      marginRight: 5
    },
    iconButtonAvatar: {
      padding: 4,
      marginLeft: 5
    },
    link: {
      textDecoration: 'none',
      color: lightColor,
      '&:hover': {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: lightColor,
    },
    avatar: {
      border: '2px solid white',
      background: 'transparent'
    },
    avatarInMenu: {
      width: 40,
      height: 40,
      margin: '10px 10px',
      background: theme.palette.primary.light
    },
    menuAvatar: {
      width: '220px',
      '&:focus': {
        outline: 'none'
      }
    },
    bigAvatar: {
      margin: 10,
      width: 50,
      height: 50,
    },
    menuBottom: {
      background: '#F5F5F5',
      padding: '0.4rem 0.5rem'
    },
    menuList: {
      '& ul': {
        padding: 0
      }
    },
    tabs: {
      minHeight: 64,
      '& .MuiTabs-indicator':
        {
          background: '#F5F5F5 !important',
        }
    },
    tab: {
      marginTop: 8
    },
    appLogo: {
      width: 32,
    }

  }))
;

const AppHeader = (props) => {
  const classes = useHeaderAppStyles();
  const {onDrawerToggle, onDesktopDrawerToggle, showToggle} = props;
  const {state, dispatch, actions} = useContext(AppContext);
  const {user} = state;
  const {history, location} = useRouter();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {t} = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = async () => {
    const signedOut = await dispatch(actions.signOut());
    if (signedOut) {
      window.location = '/user';
    }
  };

  /* eslint-disable */
  useEffect(() => {
    switch (location.pathname) {
      case '/user':
        dispatch(actions.setRouteId(0));
        break;
      case '/user/activity':
        dispatch(actions.setRouteId(1));
        break;
      case '/user/debts':
      case '/user/debts/new':
        dispatch(actions.setRouteId(2));
        break;
      case '/user/schedules':
      case '/user/schedules/new':
        dispatch(actions.setRouteId(3));
        break;
      case '/user/contacts':
        dispatch(actions.setRouteId(4));
        break;
      default:
        dispatch(actions.setRouteId(false));
        break;
    }
  }, []);

  const handleTabChange = (e, value) => {
    dispatch(actions.setRouteId(value));
    switch (value) {
      case 0:
        history.push('/user');
        break;
      case 1:
        history.push('/user/activity');
        break;
      case 2:
        history.push('/user/debts');
        break;
      case 3:
        history.push('/user/schedules');
        break;
      case 4:
        history.push('/user/contacts');
        break;
      default:
        break;
    }
  };

  const handleOnSettings = () => {
    history.push('/user/config');
  };


  return (
    <Fragment>
      <AppBar className={classes.appBar} position='sticky' elevation={0}>
        <Toolbar className={classes.toolbar}>
          <Hidden smUp>
            <IconButton
              color='inherit'
              aria-label='Open drawer'
              onClick={onDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon/>
            </IconButton>
          </Hidden>
          {showToggle && <Hidden smDown>
            <IconButton
              color='inherit'
              aria-label='Open drawer'
              onClick={onDesktopDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon/>
            </IconButton>
          </Hidden>}
          <img alt='' className={classes.appLogo} src={logoImg}/>
          <Tabs className={classes.tabs} value={state.routeId} onChange={handleTabChange}>
            <Tab className={classes.tab} label={t('common.summary')}/>
            <Tab className={classes.tab} label={t('common.activity')}/>
            <Tab className={classes.tab} label={t('common.debts')}/>
            <Tab className={classes.tab} label={t('common.schedules')}/>
            <Tab className={classes.tab} label={t('common.contacts')}/>
          </Tabs>
          <div className={classes.separator}/>
          <div>
            <Tooltip title='Configuration'>
              <IconButton color='inherit' onClick={handleOnSettings}>
                <Icon>settings</Icon>
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <IconButton color='inherit' className={classes.iconButtonAvatar}
                        onClick={handleClick}>
              <Avatar className={classes.avatar} src={user.picture}>
                {!user.picture && <Icon>person_outline</Icon>}
              </Avatar>
            </IconButton>
            <Menu id="avatar-menu" className={classes.menuList}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}>
              <div className={classes.menuAvatar}>
                <Grid container direction='column'>
                  <Grid item container alignItems="center">
                    <Avatar className={classes.avatarInMenu} src={user.picture}>
                      {!user.picture && <Icon>person_outline</Icon>}
                    </Avatar>
                    <Grid item>
                      <Typography>Welcome!</Typography>
                      <Typography color='textSecondary'
                                  variant='caption'>{user.name}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container justify="flex-end"
                        alignItems="flex-end"
                        className={classes.menuBottom}>
                    <Button onClick={signOut}>Sign out</Button>
                  </Grid>
                </Grid>
              </div>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

export default AppHeader;
