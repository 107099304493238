import React, {useContext, useEffect, useState} from 'react';
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  createGenerateClassName,
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/styles';
import {create} from 'jss';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import {en, es} from '../common/languages'
import AppRoutes from './AppRoutes';
import {appTheme} from './AppTheme';
import {AppContext} from './AppContext';
import {AppRouter} from "./AppRouter";
import {SnackbarProvider} from 'notistack';
import AppLoader from "./AppLoader";

const jss = create({
  ...jssPreset()
});

const generateClassName = createGenerateClassName({});

i18n.use(initReactI18next).init({
  resources: {
    en, es
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false
  }
}).then();

const App = () => {
  const {state, dispatch, actions} = useContext(AppContext);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true)
  }, []);

  useEffect(() => {
    i18n.changeLanguage(state.language).then();
  }, [state.language]);

  if (!mounted) {
    dispatch(actions.init());
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>

      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <ThemeProvider theme={appTheme}>
          <div style={{display: 'flex', height: '100vh', overflow: 'hidden'}}>
            <CssBaseline/>
            {state.ready &&
            <SnackbarProvider
              maxSnack={1}
              anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
              <AppRouter>
                <AppRoutes/>
              </AppRouter>
            </SnackbarProvider>}
            {!state.ready && <AppLoader/>}
          </div>
        </ThemeProvider>
      </StylesProvider>

    </MuiPickersUtilsProvider>
  );
};
export {App};
