import React, {useEffect, useRef, useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import StarIcon from '@material-ui/icons/Star';
import SearchIcon from '@material-ui/icons/Search';
import {grey, deepOrange} from '@material-ui/core/colors';
import {Grow} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import SearchInput from './SearchInput';
import {useQuery} from '@apollo/react-hooks';
import {GET_CONTACTS} from '../models/Contact';
import {DebtContact} from '../models/DebtContact';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 42,
    height: 42,
    backgroundColor: grey[300],
    color: grey[700],
  },
  selectedAvatar: {
    width: 42,
    height: 42,
    backgroundColor: deepOrange[400],
    color: '#fff',
  },
  title: {
    color: grey[800],
    '& h2': {
      position: 'relative',
      overflow: 'hidden',
    }

  },
  content: {
    padding: 0,
    height: 350,
    minWidth: 320
  },
  selection: {
    marginLeft: 10
  },
  searchInput: {
    position: 'absolute',
    top: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
  },
  searchInputLeftIcon: {
    marginRight: 10,
    cursor: 'pointer'
  },
  searchInputRightIcon: {
    marginLeft: 10,
    cursor: 'pointer'
  },
  cursorPointer: {
    cursor: 'pointer'
  }
}));

type Props = {
  iSelection?: DebtContact[],
  onClose: ([])=>{},
  onSelect: ([])=>{},
  open: boolean
}
const ContactSelector = (props: Props) => {
  const classes = useStyles();
  const searchRef = useRef();
  const {onClose, iSelection = [], open, onSelect} = props;
  const [selection, setSelection] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState('');
  const {data} = useQuery(GET_CONTACTS);
  const contacts = data ? data.contacts : [];

  useEffect(() => {
    if (open && iSelection.length) {
      const _contacts = [];
      iSelection.forEach(debtContact => {
        _contacts.push(debtContact.contact);
      });
      setSelection(_contacts);
    }
  }, [iSelection, open]);

  function handleClose() {
    onClose();
    setSelection([]);
  }

  function handleDone() {
    setSelection([]);
    onSelect(selection);
  }

  const handleSelect = (contact) => {
    const selId = selection.findIndex(c => c._id === contact._id);
    if (selId < 0) {
      selection.push(contact);
      setSelection([...selection]);
    } else {
      selection.splice(selId, 1);
      setSelection([...selection]);
    }
  };

  const handleDisplaySearch = () => {
    setShowSearch(true);
    setTimeout(() => {
      searchRef.current.focus();
    }, 300);
  };

  const handleClearSearch = () => {
    setShowSearch(false);
    searchRef.current.clear();
  };

  const getContacts = () => {
    const s = search.toLowerCase();
    return contacts.filter(contact => {
      delete contact.__typename;
      return contact.name.toLowerCase().includes(s) ||
        contact.email.toLowerCase().includes(s) ||
        contact.phone.toLowerCase().includes(s);
    });
  };

  return (
    <Dialog onClose={handleClose} open={open}
            TransitionComponent={Transition}>
      <DialogTitle className={classes.title}>
        <Fade in={!showSearch}>
          <Grid container alignContent='center'>
            <Grid item xs>Select Contact</Grid>
            <Grid item>
              <SearchIcon className={classes.cursorPointer} onClick={handleDisplaySearch}/>
            </Grid>
          </Grid>
        </Fade>
        <Slide direction='left' in={showSearch}>
          <div className={classes.searchInput}>
            <SearchIcon className={classes.searchInputLeftIcon}/>
            <SearchInput ref={searchRef} showIcon={false} onSearch={(q) => {
              setSearch(q);
            }}/>
            <CloseIcon className={classes.searchInputRightIcon}
                       onClick={handleClearSearch}/>
          </div>
        </Slide>

      </DialogTitle>
      <DialogContent className={classes.content}>

        {contacts && <List>
          {getContacts().map(contact => {
              const isSelected = selection.find(c => c._id === contact._id);
              return (
                <ListItem key={contact._id} button onClick={() => handleSelect(contact)}>
                  <ListItemAvatar>
                    {contact.picture && !isSelected &&
                    <Avatar className={isSelected ? classes.selectedAvatar : classes.avatar}
                            src={contact.picture}/>}
                    {contact.picture && isSelected &&
                    <Avatar className={isSelected ? classes.selectedAvatar : classes.avatar}>
                      <CheckIcon/>
                    </Avatar>}
                    {!contact.picture &&
                    <Avatar className={isSelected ? classes.selectedAvatar : classes.avatar}>
                      {isSelected ? <CheckIcon/> :
                        contact.myself ? <StarIcon/> :
                          contact.name.charAt(0).toUpperCase()}
                    </Avatar>}
                  </ListItemAvatar>
                  <ListItemText primary={contact.name}
                                secondary={contact.phone ? contact.phone : contact.email}/>
                </ListItem>
              );
            }
          )}
        </List>}

      </DialogContent>
      <Divider light/>
      <DialogActions>
        {!!selection.length &&
        <Typography className={classes.selection}
                    variant='caption'
                    color='textSecondary'>
          {`${selection.length} contacts selected`}
        </Typography>
        }
        <Box flex={1}/>
        <Button onClick={handleDone} color="primary">
          Done
        </Button>
      </DialogActions>

    </Dialog>
  );
};

export default ContactSelector;
