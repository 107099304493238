import {ApolloClient} from 'apollo-client';
import {setContext} from 'apollo-link-context';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {APOLLO_URI} from "./config";
import firebase from './firebase'
import {createUploadLink} from 'apollo-upload-client';

const httpLink = createUploadLink({
  uri: `${APOLLO_URI}?platform=web`,
});

const authLink = setContext(async (_, {headers}) => {
  // get the authentication token from local storage if it exists
  const currentUser = await firebase.auth().currentUser;
  let token;
  if (currentUser) {
    token = await currentUser.getIdToken()
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false
  })
});

export default client;
