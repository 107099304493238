import React, {Fragment, useState} from 'react';
import Hidden from '@material-ui/core/Hidden';
import {drawerWidth, useAppStyles} from "./AppStyles";
import AppHeader from "./AppHeader";
import Navigator from "./AppNavigator";
import classNames from 'classnames';

type Props = {
  children: Object
}
const AppContent = (props: Props) => {
  const classes = useAppStyles();
  const {children} = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [desktopOpen, setDesktopOpen] = useState(true);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDesktopDrawerToggle = () => {
    setDesktopOpen(!desktopOpen);
  };
  return (
    <Fragment>
      <nav className={classNames(classes.drawer, classes.drawerClose)}>
        <Hidden smUp implementation="js">
          <Navigator
            PaperProps={{style: {width: drawerWidth}}}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        {/*<Hidden xsDown implementation="js">*/}
        {/*<Navigator open={desktopOpen}*/}
        {/*onClose={handleDesktopDrawerToggle}*/}
        {/*PaperProps={{style: {width: drawerWidth}}}/>*/}
        {/*</Hidden>*/}
      </nav>
      <div className={classes.appContent}>
        <AppHeader showToggle={!desktopOpen}
                   onDesktopDrawerToggle={handleDesktopDrawerToggle}
                   onDrawerToggle={handleDrawerToggle}/>
        {children}
      </div>
    </Fragment>
  );
};

export default AppContent;
