import React, {Fragment, useState} from 'react';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {Button, Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import {useSnackbar} from 'notistack';

import {Content} from "../../../common/components/Content";
import ContactSection from "../../../common/components/ContactSelection";
import AmountInput from "../../../common/components/AmountInput";
import AmountPerType from "../../../common/components/AmountPerType";
import NotesInput from "../../../common/components/NotesInput";
import RemainderBySelector from "../../../common/components/RemainderBySelector";
import {
  CREATE_SCHEDULE,
  GET_SCHEDULE,
  GET_SCHEDULES,
  Schedule,
  ScheduleInput,
  UPDATE_SCHEDULE
} from "../../../common/models/Schedule";
import ScheduleContact from "../../../common/models/ScheduleContact";
import RemainderSelector from "../../../common/components/RemainderSelector";
import helpers from "../../../common/helpers";
import DateInput from "../../../common/components/DateInput";
import Alert from "../../../common/components/Alert";

import {useDebtEditorStyles} from "./ScheduleEditorStyles";
import {useTranslation} from "react-i18next";
import PrivateSelector from "../../../common/components/PrivateSelector";
import firebase from "../../../firebase";

const ScheduleEditor = (props) => {
  const {match, history} = props;
  const classes = useDebtEditorStyles();
  const [amount, setAmount] = useState(0);
  const [schedule, setSchedule] = useState(new Schedule());
  const {loading, error: errorFetch} = useQuery(GET_SCHEDULE, {
    variables: {id: match.params.id},
    onCompleted: (data) => {
      setSchedule(new Schedule(data.schedule));
      if (data.schedule.editor.total) {
        setAmount(data.schedule.total);
      } else {
        setAmount(data.schedule.total / data.schedule.contacts.length);
      }
    },
    skip: match.params.id === 'new'
  });
  const [createSchedule, {loading: loadingCreate, error: errorCreate}] =
    useMutation(CREATE_SCHEDULE);
  const [updateSchedule, {loading: loadingUpdate, error: errorUpdate}] =
    useMutation(UPDATE_SCHEDULE, {skip: match.params.id === 'new'});
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const handleSave = async (schedule) => {
    if (schedule._id) {
      await handleUpdateSchedule(schedule);
    } else {
      await handleAddSchedule(schedule);
    }
  };

  const handleAddSchedule = async (schedule) => {
    const _schedule = new ScheduleInput(schedule);

    await createSchedule({
      variables: {schedule: _schedule},
      refetchQueries: [{query: GET_SCHEDULES}],
      awaitRefetchQueries: true
    });
    firebase.analytics().logEvent('schedule_create', {});
    enqueueSnackbar('Schedule successfully created!');
    history.push(`/`);
  };

  const handleUpdateSchedule = async (schedule) => {
    const _schedule = new ScheduleInput(schedule);

    await updateSchedule({
      variables: {id: schedule._id, schedule: _schedule},
      refetchQueries: [{query: GET_SCHEDULES}],
      awaitRefetchQueries: true
    });
    firebase.analytics().logEvent('schedule_update', {});
    enqueueSnackbar('Schedule successfully updated!');
  };

  const handleContactSelection = (selection) => {
    const scheduleContacts = [];
    selection.forEach(selectedContact => {
      scheduleContacts.push(new ScheduleContact(selectedContact));
    });
    const contacts = helpers.getUpdatedContacts(schedule.editor.total, amount, scheduleContacts);
    setSchedule({...schedule, contacts});
  };

  const handleAmountChange = (value) => {
    const contacts = helpers.getUpdatedContacts(schedule.editor.total, value, schedule.contacts);
    setAmount(value);
    setSchedule({...schedule, contacts});
  };

  const handleAmountPerContactChange = (e) => {
    const editor = schedule.editor;
    editor.total = e.target.checked;
    const contacts = helpers.getUpdatedContacts(editor.total, amount, schedule.contacts);
    setSchedule({...schedule, editor, contacts})
  };

  const handleNotesChange = (e) => {
    setSchedule({...schedule, notes: e.target.value})
  };

  const handleRemainderChange = (e) => {
    const {reminders} = schedule;
    reminders.interval = e.target.value;
    setSchedule({...schedule, reminders})
  };

  const handleSendByEmailChange = (e) => {
    const {notifications} = schedule;
    notifications.email = e.target.checked;
    setSchedule({...schedule, notifications})
  };

  const handleSendBySMSChange = (e) => {
    const {notifications} = schedule;
    notifications.text = e.target.checked;
    setSchedule({...schedule, notifications})
  };

  const handleDateChange = (e) => {
    const {recurrence} = schedule;
    if (recurrence.mode > 0) {
      recurrence.date = e.toDate();
    } else {
      recurrence.data = e.toDate();
    }
    setSchedule({...schedule, recurrence})
  };

  const handleRecurrenceChange = (e) => {
    const {recurrence} = schedule;
    recurrence.mode = parseInt(e.target.value);
    setSchedule({...schedule, recurrence})
  };

  const handleStartingAtChange = (e) => {
    const {recurrence} = schedule;
    recurrence.data = parseInt(e.target.value);
    setSchedule({...schedule, recurrence})
  };

  const handlePrivateChange = (e) => {
    setSchedule({...schedule, private: e.target.checked});
  };

  const canSubmit = () => {
    return schedule.contacts.length > 0 && parseFloat(amount) > 0 && !isLoading();
  };

  const isLoading = () => {
    return loading || loadingCreate || loadingUpdate;
  };

  const getDateValue = () => {
    return schedule.recurrence.mode > 0 ?
      (schedule.recurrence.date ? schedule.recurrence.date : new Date())
      : new Date()
  };

  if (errorFetch || errorCreate || errorUpdate) {
    setShowErrorDialog(true)
  }

  return (
    <Fragment>
      <Content>
        <Grid container
              direction='column'
              spacing={4}
              style={{maxWidth: 640, margin: 'auto'}}>
          <Grid item style={{width: '100%'}}>
            <ContactSection debtContacts={schedule.contacts}
                            editSelection={!schedule._id}
                            onChange={handleContactSelection}/>
          </Grid>
          <Grid item style={{width: '100%'}}>
            <Paper elevation={0} className={classes.paper}>
              <Box p='20px'>
                <Grid container direction='column' alignItems='center' spacing={2}>
                  <AmountInput value={amount} onChange={handleAmountChange}/>

                  <AmountPerType checked={schedule.editor.total}
                                 onChange={handleAmountPerContactChange}/>

                  <NotesInput value={schedule.notes || ''}
                              onChange={handleNotesChange}/>

                  <Grid item container alignItems='center' justify='center' spacing={3}>
                    <Grid item>
                      <Icon color='disabled'>schedule</Icon>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <InputLabel htmlFor="recurrence">{t('common.recurrence')}</InputLabel>
                        <Select
                          id='recurrence-selector'
                          name='recurrence-selector'
                          style={{width: 280}}
                          inputProps={{
                            name: 'recurrence',
                          }}
                          value={schedule ? schedule.recurrence.mode : 0}
                          onChange={handleRecurrenceChange}
                        >
                          <MenuItem value={0}>{t('schedules.notRepeat')}</MenuItem>
                          <MenuItem value={1}>{t('schedules.biweekly')}</MenuItem>
                          <MenuItem value={2}>{t('schedules.monthly')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {schedule.recurrence.mode > 0 &&
                  <Grid item container
                        alignItems='center'
                        justify='center'
                        spacing={3}>
                    <Grid item>
                      <Icon color='disabled'>today</Icon>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <InputLabel htmlFor="startingAt">
                          {t('schedules.startingAtDay')}
                        </InputLabel>
                        <Select style={{width: 280}}
                                inputProps={{
                                  name: 'startingAt',
                                }}
                                value={schedule.recurrence.data || 1}
                                onChange={handleStartingAtChange}
                        >
                          {
                            schedule && schedule.recurrence.mode === 1 &&
                            [...Array(18)].map((u, i) => (
                              i > 0 && <MenuItem value={i} key={i}>
                                {t('common.day')} {i} {t('common.and')} {i + 14}
                              </MenuItem>
                            ))
                          }

                          {
                            schedule && schedule.recurrence.mode === 2 &&
                            [...Array(32)].map((u, i) => (
                              i > 0 && <MenuItem value={i} key={i}>{t('common.day')} {i}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>}

                  <DateInput
                    icon={schedule.recurrence.mode > 0 ?
                      'cancel_presentation' : 'calendar_today'}
                    format="LL"
                    minDate={new Date()}
                    label={schedule.recurrence.mode > 0 ?
                      t('common.until') : t('schedules.createdOn')}
                    value={getDateValue()}
                    onChange={handleDateChange}/>

                  <RemainderBySelector
                    label={t('common.emailNotifications')}
                    icon='alternate_email'
                    value={schedule.notifications.email}
                    onChange={handleSendByEmailChange}/>

                  <RemainderBySelector
                    label={t('common.smsNotifications')}
                    icon='sms'
                    value={schedule.notifications.text}
                    onChange={handleSendBySMSChange}/>

                  <RemainderSelector
                    value={schedule.reminders.interval}
                    onChange={handleRemainderChange}/>

                  <PrivateSelector label={t('common.private')}
                                   icon={'visibility'}
                                   help={t('debts.editor.private')}
                                   value={schedule.private}
                                   onChange={handlePrivateChange}/>

                  <Grid item>
                    <Box p={3}>
                      <Button disabled={!canSubmit()}
                              variant='contained'
                              color='primary'
                              onClick={() => handleSave(schedule)}>
                        {isLoading() ? `${t('common.saving')}...` : t('common.save')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid item>
          <Box p={2}/>
        </Grid>

      </Content>
      {loading &&
      <Box position='absolute'
           width='100%'
           height='100%'
           bgcolor='rgba(255,255,255, 0.8)'
           top={0} left={0} right={0} bottom={0}
           display='flex'
           alignItems='center'
           justifyContent='center'>
        <CircularProgress/>
      </Box>}
      <Alert show={showErrorDialog}
             okAction={() => setShowErrorDialog(false)}
             title={t('common.error.title')}>
        <Typography>{t('common.error.description')}</Typography>
      </Alert>
    </Fragment>
  );
};

export default ScheduleEditor;
