import {makeStyles} from '@material-ui/styles';
import {grey} from "@material-ui/core/colors";

const useActivityStyles = makeStyles(() => ({
  activity: {
    padding: '5px 10px',
    cursor: 'pointer',
    '&:hover': {
      background: '#ffccbc30'
    }
  },
  selected: {
    background: '#ffccbc60',
    '&:hover': {
      background: '#ffccbc60'
    }
  },
  avatar: {
    margin: 5,
    width: 48,
    height: 48,
    color: '#FF6131',
    background: '#FFF',
    border: '1px solid #cdcdcd'
  },
  sidebarAvatar: {
    width: 40,
    height: 40,
    color: grey[600],
    background: grey[200]
  },
  sidebar: {
    position: 'absolute',
    width: '320px',
    right: 0,
    top: '65px',
    bottom: 0,
    background: '#fff',
    padding: '20px 15px',
    boxShadow: 'rgba(0, 0, 0, 0.26) 0px -2px 2px 1px',
  }
}));

export {useActivityStyles};
