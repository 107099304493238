import React from 'react';
import {makeStyles} from '@material-ui/styles';

const useContentStyles = makeStyles(theme => ({
  mainContent: {
    flex: 1,
    position: 'relative',
    background: '#F5F7FA',
    maxWidth: '100vw',
    zIndex: 0,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '28px 16px 0',
    },
  },
}));

type Props = {
  children: any,
  fullWidth?: boolean,
  noPadding?: boolean
}

const Content = (props: Props) => {
  const classes = useContentStyles();
  const {noPadding} = props;

  return (
    <main className={classes.mainContent}
          style={noPadding ? {padding: 0} : {padding: '48px 36px 0'}}>
      {props.children}
    </main>
  );
};

export {Content};
