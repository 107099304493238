import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Transition from '../common/components/Transition';
import GoogleImg from '../common/images/google-play-badge.png';
import AppleImg from '../common/images/app-store-badge.png';
import {ANDROID_URL, IOS_URL} from "../config";

const AppSmallDeviceWarning = () => {

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle id='receipt-dialog-title'>
        Browsing in a mobile?
      </DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Typography>
              Download our app.
            </Typography>
          </Grid>
          <Grid item/>
          <Grid container justify={'center'} style={{marginBottom: 20}} spacing={1}>
            <Grid item>
              <a href={ANDROID_URL}><img alt='' style={{height: "32px"}} src={GoogleImg}/></a>
            </Grid>
            <Grid item>
              <a href={IOS_URL}> <img alt='' style={{height: "32px",}} src={AppleImg}/></a>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AppSmallDeviceWarning;
