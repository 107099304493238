import React, {createContext} from 'react';
import useEnhancedReducer from 'react-enhanced-reducer-hook';
import thunkMiddleware from 'redux-thunk';
import firebase from "../firebase"
import graphClient from "../apollo"
import apolloClient from "../apollo"
import {GET_USER_META, UPDATE_USER_META, UserInput} from "../common/models/User";

const initialState = {
  user: null,
  error: "",
  loading: false,
  ready: false,
  routeId: 0,
  language: 'en',
  currency: '$'
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'READY':
      return {...state, ...{ready: action.payload}};
    case 'AUTH_IN_PROGRESS':
      return {...state, ...{loading: action.payload, error: null}};
    case 'AUTH_ERROR':
      return {...state, ...{error: action.payload}};
    case 'AUTH_CLEAR_ERRORS':
      return {...state, ...{error: null}};
    case 'AUTH_SUCCESS':
      return {...state, ...{user: action.payload}};
    case 'AUTH_RESET':
      return {...state, ...initialState};
    case 'SET_ROUTE_ID':
      return {...state, ...{routeId: action.payload}};
    case 'SET_LANGUAGE':
      return {...state, ...{language: action.payload}};
    case 'SET_CURRENCY':
      return {...state, ...{currency: action.payload}};
    default:
      return state;
  }
};

const actions = {};

actions.init = () => async dispatch => {
  const language = window.navigator.language;

  const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      const {data} = await apolloClient.query({
        query: GET_USER_META
      });
      if (data.user) {
        dispatch({type: 'AUTH_SUCCESS', payload: data.user});
      }
      if (data.user.language) {
        dispatch({type: 'SET_LANGUAGE', payload: data.user.language});
      } else {
        dispatch({type: 'SET_LANGUAGE', payload: language.split('-')[0]});
      }
      if (data.user.currency) {
        dispatch({type: 'SET_CURRENCY', payload: data.user.currency});
      }
    }
    unsubscribe();
    dispatch({type: 'READY', payload: true});
  });

};

actions.signIn = (email, password) => async dispatch => {
  dispatch({type: 'AUTH_IN_PROGRESS', action: true});

  try {
    const resp = await firebase.auth().signInWithEmailAndPassword(email, password);
    const {user} = resp;
    dispatch({type: 'AUTH_IN_PROGRESS', action: false});
    dispatch({type: 'AUTH_SUCCESS', payload: user});
    firebase.analytics().logEvent('login', {});
    return user;
  } catch (e) {
    dispatch({type: 'AUTH_IN_PROGRESS', action: false});
    dispatch({type: 'AUTH_ERROR', payload: e.message});
    return null;
  }
};

actions.signInWithGoogle = () => async dispatch => {
  dispatch({type: 'AUTH_IN_PROGRESS', action: true});

  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    const resp = await firebase.auth().signInWithPopup(provider);
    const {user} = resp;
    dispatch({type: 'AUTH_IN_PROGRESS', action: false});
    dispatch({type: 'AUTH_SUCCESS', payload: user});
    firebase.analytics().logEvent('login', {});
    return user;
  } catch (e) {
    dispatch({type: 'AUTH_IN_PROGRESS', action: false});
    dispatch({type: 'AUTH_ERROR', payload: e.message});
    return null;
  }
};

actions.signInWithFacebook = () => async dispatch => {
  dispatch({type: 'AUTH_IN_PROGRESS', action: true});

  try {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.setCustomParameters({
      'display': 'popup'
    });
    const resp = await firebase.auth().signInWithPopup(provider);
    const {user} = resp;
    dispatch({type: 'AUTH_IN_PROGRESS', action: false});
    dispatch({type: 'AUTH_SUCCESS', payload: user});
    firebase.analytics().logEvent('login', {});
    return user;
  } catch (e) {
    dispatch({type: 'AUTH_IN_PROGRESS', action: false});
    dispatch({type: 'AUTH_ERROR', payload: e.message});
    return null;
  }
};

actions.signUp = (name, email, password) => async dispatch => {
  dispatch({type: 'AUTH_IN_PROGRESS', payload: true});

  try {
    const res = await firebase.auth().createUserWithEmailAndPassword(email, password);
    const {user} = res;
    await user.updateProfile({displayName: name});
    dispatch({type: 'AUTH_IN_PROGRESS', payload: false});
    dispatch({type: 'AUTH_SUCCESS', payload: user});
    firebase.analytics().logEvent('sign_up', {});
    return user;
  } catch (e) {
    dispatch({type: 'AUTH_IN_PROGRESS', payload: false});
    dispatch({type: 'AUTH_ERROR', payload: e.message});
  }

  return null;
};

actions.signOut = () => async dispatch => {
  await graphClient.resetStore();
  await firebase.auth().signOut();
  dispatch({type: 'AUTH_CLEAR_ERRORS', payload: null});
  return true
};

actions.forgotPassEmail = (email) => async dispatch => {
  dispatch({type: 'AUTH_IN_PROGRESS', payload: true});
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    dispatch({type: 'AUTH_IN_PROGRESS', payload: false});
    return true;
  } catch (e) {
    dispatch({type: 'AUTH_IN_PROGRESS', payload: false});
    dispatch({type: 'AUTH_ERROR', payload: e.message});
    return null;
  }
};

actions.updateUserProfile = (userIn) => async (dispatch) => {
  const user = new UserInput(userIn);

  try {
    const {data} = await apolloClient.mutate({
      mutation: UPDATE_USER_META,
      variables: {user}
    });

    const userMeta = data && data.updateUser;
    console.log(userMeta);

    if (userMeta && userMeta.language) {
      dispatch({type: 'SET_LANGUAGE', payload: userMeta.language});
    }
    if (userMeta && userMeta.currency) {
      dispatch({type: 'SET_CURRENCY', payload: userMeta.currency});
    }
    if (userMeta) {
      console.log(userMeta);
      dispatch({type: 'AUTH_SUCCESS', payload: userMeta});
    }

    return true;
  } catch (e) {
    console.log(e);
    return null;
  }
};

actions.setLanguage = (language) => dispatch => {
  dispatch({type: 'SET_LANGUAGER', payload: language});
};

actions.setCurrency = (currency) => dispatch => {
  dispatch({type: 'SET_CURRENCY', payload: currency});
};

actions.setUser = (user) => dispatch => {
  dispatch({type: 'AUTH_SUCCESS', payload: user});
};


actions.setRouteId = (routeId) => dispatch => {
  dispatch({type: 'SET_ROUTE_ID', payload: routeId});
};


actions.clear = () => dispatch => {
  dispatch({type: 'AUTH_CLEAR_ERRORS', payload: null});
};

const AppContext = createContext();
const AppProvider = ({children}) => {
  const [state, dispatch] = useEnhancedReducer(reducer, initialState, [thunkMiddleware]);
  const store = {state, dispatch, actions};
  return <AppContext.Provider value={store}>{children}</AppContext.Provider>
};

export {AppContext, AppProvider}
