import React from 'react';
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

type Props = {
  label: string,
  icon: string,
  help: string,
  value: string,
  onChange: ()=>{}
}
const PrivateSelector = (props: Props) => {
  const {value, onChange, label, icon, help} = props;

  return (
    <Grid item container alignItems='center' justify='center' spacing={3}>
      <Grid item>
        <Icon color='disabled'>{icon}</Icon>
      </Grid>
      <Grid item style={{width: 300}}>
        <FormControlLabel
          style={{width: '100%'}}
          control={
            <Checkbox checked={value}
                      onChange={onChange}
                      value="private"
                      color='primary'/>
          }
          label={label}
        />
        <Typography variant={"caption"} color={'textSecondary'}>
          {help}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PrivateSelector;
