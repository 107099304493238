import React from "react";
import {BrowserRouter, Route} from "react-router-dom";

const AppRouterContext = React.createContext({});

const AppRouter = ({children}) => (
  <BrowserRouter>
    <Route>
      {(routeProps) => (
        <AppRouterContext.Provider value={routeProps}>
          {children}
        </AppRouterContext.Provider>
      )}
    </Route>
  </BrowserRouter>
);

export {AppRouter, AppRouterContext};
