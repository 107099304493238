import {gql} from 'apollo-boost';

export const GET_CONTACTS = gql`{
  contacts {
    _id
    name
    phone
    email
    myself
    picture
    updatedAt
    createdAt
  }
}`;

export const CREATE_CONTACT = gql`
  mutation CreateContact($contact: ContactInput!) {
    createContact(contact: $contact) {
      _id
      name
      phone
      picture
      email
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($id: MongoID!, $contact: ContactInput!) {
    updateContact(id: $id, contact: $contact) {
      _id
      name
      phone
      picture
      email
    }
  }
`;

export const DELETE_CONTACT = gql`
  mutation RemoveContact($id: MongoID!) {
  removeContact (id: $id) {
    _id
  }
}
`;

export const UPLOAD_CONTACT_PICTURE = gql`
mutation UploadPictureContact($image: Upload!) {
  uploadPictureContact(image: $image)
}`;

export class ContactInput {
  name;
  phone;
  email;
  language;
  picture;

  constructor(contact) {
    this.name = contact && contact.name;
    this.phone = contact && contact.phone;
    this.email = contact && contact.email;
    this.language = contact && contact.language;
    this.picture = contact && contact.picture;
  }
}

export default class Contact {
  _id;
  name;
  phone;
  email;
  myself;
  debts;
  picture;
  payments;
  amount;
  updatedAt;
  createdAt;
}


