import React from "react";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {ANDROID_URL, IOS_URL} from "../../../config";
import GoogleImg from '../../../common/images/google-play-badge.png';
import AppleImg from '../../../common/images/app-store-badge.png';

function DebtFooter({onUnsubscribe, onResubscribe}) {
  const {t} = useTranslation();
  return (
    <>
      <Typography component="h4" variant="body1" align={"center"} style={{marginBottom: 32}}>
        {t('contact_stores')}
      </Typography>

      <Grid container justify={"center"}>
        <a href={ANDROID_URL}><img alt='' style={{height: "42px"}} src={GoogleImg}/></a>
        <a href={IOS_URL}> <img alt='' style={{height: "42px", marginLeft: 20}} src={AppleImg}/></a>
      </Grid>

      <Grid container justify={"center"}>
        <Typography component="p" variant="caption" align={"center"}
                    style={{marginTop: 25, maxWidth: 420}}>
          {t('contact.unsubscribe_message')}
          <span style={{cursor: 'pointer', color: 'blue'}}
                onClick={onUnsubscribe}>{t('contact.unsubscribe')}</span>,
          {t('contact.resubscribe_message')}
          <span style={{cursor: 'pointer', color: 'blue'}}
                onClick={onResubscribe}>{t('contact.resubscribe')}</span>.
        </Typography>
      </Grid>

      <Grid container justify={"center"} spacing={1} style={{marginTop: 20}}>
        <Grid item>
          <Typography component="p" variant="caption" align={"center"}>
            <a target="_blank" rel="noopener noreferrer"
               href={process.env.REACT_APP_SITE_URL + '/terms'}>{t('common.terms')}</a>
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="p" variant="caption" align={"center"}>|</Typography>
        </Grid>
        <Grid item>
          <Typography component="p" variant="caption" align={"center"}>
            <a target="_blank" rel="noopener noreferrer"
               href={process.env.REACT_APP_SITE_URL + '/privacy'}>{t('common.privacy')}</a>
          </Typography>
        </Grid>
      </Grid>

      <div style={{marginBottom: 40}}/>

    </>
  );
}

export default DebtFooter;
