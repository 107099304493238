import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Transition from "../../../../common/components/Transition";

const ConfirmationDialog = (props) => {
  const {title, show, onClose} = props;

  return (
    <Dialog
      TransitionComponent={Transition}
      open={show}
      fullWidth={true}
      maxWidth='sm'
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}> Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
