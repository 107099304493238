export const en = {
  translation: {
    common: {
      and: 'and',
      activity: 'Activity',
      addContacts: 'Add contacts',
      addNew: 'Add new',
      all: 'All',
      amount: 'Amount',
      biweekly: 'Bi-Weekly',
      cancel: 'Cancel',
      completed: 'Completed',
      config: 'Configuration',
      contact: 'Contact',
      contacts: 'Contacts',
      currency: 'Currency',
      day: 'Day',
      debt: 'Debt',
      debts: 'Debts',
      delete: 'Delete',
      disabled: 'Disabled',
      edit: 'Editar',
      editSelection: 'Edit selection',
      email: 'Email',
      emailNotifications: 'Notify contacts by email',
      for: 'for',
      language: 'Language',
      loadMore: 'Load more',
      monthly: 'Monthly',
      name: 'Name',
      next: 'Next',
      notes: 'Notes',
      pay: 'Pay',
      payment: 'Payment',
      pending: 'Pending',
      phone: 'Phone',
      private: 'Private',
      profile: 'Profile',
      receipt: 'Receipt',
      recurrence: 'Recurrence',
      reminders: 'Reminders',
      remindersEnd: 'Reminders ends at',
      remindersStart: 'Reminders starts at',
      requests: 'Requests',
      requestsBy: 'Requests by',
      save: 'Save',
      saving: 'Saving',
      schedule: 'Schedule',
      schedules: 'Schedules',
      smsNotifications: 'Notify contacts by sms',
      summary: 'Summary',
      total: 'Total',
      until: 'Until',
      weekly: 'Weekly',
      welcome: 'Hello',
      yes: 'Yes',
      error: {
        title: 'An error has occurred!',
        description: 'An error has occurred. Please try again later!'
      },
      confirmation: "Confirmation",
      terms: 'Terms of Service',
      privacy: 'Privacy & Policy',
      userAvatar: {
        error: 'Error uploading picture',
        title: 'What do you want to do?',
        upload: 'Upload New Picture',
        delete: 'Delete Current Picture'
      }
    },
    languages: {
      en: 'English',
      es: 'Spanish'
    },
    home: {
      addNew: 'Add New',
      scheduledDebts: 'Scheduled debts',
      pendingPayments: 'Pending payments',
      pendingDebts: 'Pending debts',
      latestActivity: 'Latest activity',
      closeDebt: 'Close debt',
      confirmPayment: 'Confirm payment',
      rejectPayment: 'Reject payment',
      balanceDescription: ' This is how much money is out',
      actions: 'Actions needed',
      notifiedPayments: 'Notified payments to review',
      declinedDebts: 'Declined debts to review',
      info: {
        actions: 'Actions you need to complete',
        requests: 'Debts created by other users and assigned to me',
        pendingPayments: 'Payments notified by the contacts that you need to confirm',
        pendingDebts: 'Pending debts to be paid by contacts'
      },
      error: {
        title: 'An error has occurred!',
        description: 'An error has occurred. Please try again later!'
      },
      empty: {
        title: 'No active debts',
        message: 'Start adding some debts to send reminders,track borrowers, and view your unpaid balance.',
      },
      verification: {
        section: {
          title: 'Verify',
          email: 'Verify your email',
          phone: 'Verify your phone'
        },
        dialog: {
          email: {
            title: 'Email Verification',
            description: 'An email has been sent check your inbox for more instructions',
            error: {
              'INVALID_DATA': 'Invalid email!',
              'INTERNAL_SERVER_ERROR': 'An error occurred trying to validate your email.'
            }
          },
          phone: {
            title: 'Phone Verification',
            description: 'A text message has been sent to your phone. Check your messages for more instructions',
            error: {
              'INVALID_DATA': 'Invalid phone!',
              'INTERNAL_SERVER_ERROR': 'An error occurred trying to validate your phone.'
            }
          }
        },
        feedback: {
          success: 'User code successfully verified!',
          error: {
            'INVALID_ID': 'The provided code is invalid!'
          }
        }
      }
    },
    debts: {
      debtDetails: 'Debt details',
      debtCreation: 'Debt will be created on',
      close: 'Close debt',
      restore: 'Restore debt',
      confirmation: {
        paymentConfirm: {
          title: 'Confirm payment',
          message: 'Are you sure you want to confirm this payment?',
        },
        paymentReject: {
          title: 'Reject payment',
          message: 'Are you sure you want to reject this payment?',
        },
        payInFull: {
          title: 'Pay in full',
          message: 'Are you sure you want to pay in full this debt?',
        },
        decline: {
          title: 'Decline debt',
          message: 'Are you sure you want to decline this debt?',
        },
        close: {
          title: 'Close debt',
          message: 'Are you sure you want to close this debt?',
        },
        restore: {
          title: 'Restore debt',
          message: 'Are you sure you want to restore this debt?',
        }
      },
      feedback: {
        paymentConfirm: 'Payment confirmed!',
        paymentReject: 'Payment rejected!',
        close: 'Debt was closed successfully',
        restore: 'Debt was restored successfully',
        declineDebt: 'Debt was declined',
      },
      editor: {
        toggle: 'Togggle to split the total between contacts or assign the total to each contact',
        private: 'Prevent the debt to appear in the contact\'s user account'
      },
      deleteWarning: {
        title: 'Delete debt',
        message: 'Are you sure you want to delete te Debt?',
      },
      empty: {
        title: 'No active debt was found',
        message: 'Its seems that you don\'t have active debts, let\'s create some debts.',
      }
    },
    schedules: {
      details: 'Schedule details',
      startingAtDay: 'Starting at day',
      createdOn: 'Debt will be created on',
      notRepeat: 'Does not repeat',
      biweekly: 'Every 2 weeks',
      monthly: 'Every month',
      recurrence: {
        future: 'Debt will be created @ {{date}}',
        monthly: 'Monthly debts @ day {{day}}',
        biweekly: 'Monthly debts at @ days  {{day1}},  {{day2}}',
      },
      deleteWarning: {
        title: 'Delete schedule',
        message: 'Are you sure you want to delete the Calendar?',
      },
      empty: {
        title: 'No schedules Found',
        message: 'To avoid the creation of periodic debts, you can create schedules for future dates or several recurrences.'
      }
    },
    activity: {
      created: "Debt created",
      updated: "Debt updated",
      email_reminder: "Email reminder #{{reminder}} to {{contact}}",
      text_reminder: "Text reminder #{{reminder}} to {{contact}}",
      contact_payment_created: "Notification of {{amount}} by {{contact}}",
      contact_payment_rejected: "Rejected {{amount}} to {{contact}}",
      contact_payment_confirmed: "Confirmation of {{amount}} to {{contact}}",
      contact_completed: "Close of {{amount}} for {{contact}}",
      closed: "Debt closed",
      removed: "Debt removed",
      archived: "Debt archived",
      details: "Activity Details",
      empty: {
        title: 'Empty activity',
        message: 'You need to start adding debts to see the activity generated by your actions.',
      },
      status: {
        created: 'Created',
        updated: 'Updated',
        email_reminder: 'Email reminder #{{reminder}} to {{contact}}',
        text_reminder: 'Text reminder #{{reminder}} to {{contact}}',
        payment: 'Notification of {{amount}} by {{contact}}',
        rejected: 'Rejected {{amount}} to {{contact}}',
        confirmation: 'Confirmation of {{amount}} to {{contact}}',
        closed_contact: 'Close of {{amount}} for {{contact}}',
        closed: 'Close',
        removed: 'Removed',
        archived: 'Archived',
      }
    },
    requests: {
      empty: {
        title: 'Empty requests',
        message: 'Debts created by other users and assigned to me.',
      },
    },
    contacts: {
      find: 'Find contacts by name.',
      details: 'Contact details',
      editor: {
        add: 'Add contact',
        edit: 'Edit contact',
      },
      deleteWarning: {
        title: 'Delete contact',
        message: 'Are you sure you want to delete contact?',
      },
    },
    contact: {
      hello: 'Hello {{contact}}',
      nameSubtitle: 'You have the following debt.',
      disclaimer: 'If you think that something is wrong you can contact directly to {{user}} using the following information.',
      completed: 'We are pleased to inform your debt is completed.',
      notFound: 'We are sorry to inform you that the following debt doesn\'t exist.',
      stores: 'Don\'t have the app yet?',
      pay: {
        full: 'Pay in Full',
        partial: 'Pay a different amount',
        decline: 'Decline debt',
        title: 'How much do you want to pay?',
        errorTitle: 'An Error has Occurred',
        successTitle: 'All ready!',
        invalid: 'Invalid payment!',
        success: 'We will notify your debtor that you made a payment!',
        confirmation: {
          title: 'Confirmation',
          description: 'Are you sure you want to pay this debt in full?'
        }
      },
      payments: {
        buttonTitle: 'View previous payments',
        dialogTitle: 'Previous Payments',
      },
      unsubscribe: 'unsubscribe',
      unsubscribe_message: 'If you don\'t want to receive these emails from Debtrail in the future, please ',
      unsubscribe_dialog: 'Are you sure you want to stop receiving emails from Debtrail?',
      unsubscribe_feedback: 'You are successfully unsubscribe from email.',
      resubscribe: 'resubscribe',
      resubscribe_message: ' to resume your email notifications you can ',
      resubscribe_feedback: 'You are successfully subscribed to email notifications.',
    },
    contact_hello: 'Hello {{contact}}',
    contact_name_subtitle: 'You have the following debt.',
    contact_pay_full: 'Pay in Full',
    contact_pay_partial: 'Pay a different amount',
    contact_pay_error_title: 'An Error has Occurred',
    contact_pay_success_title: 'All ready!',
    contact_pay_title: 'How much do you want to pay?',
    contact_pay_invalid: 'Invalid payment!',
    contact_pay_success: 'We will notify your debtor that you made a payment!',
    contact_payments: ' View previous payments',
    contact_payments_title: 'Previous Payments',
    contact_disclaimer: 'If you think that something is wrong you can contact directly to {{user}} using the following information.',
    contact_not_found: 'We are sorry to inform you that the following debt doesn\'t exist.',
    contact_completed: 'We are pleased to inform your debt is completed.',
    contact_stores: 'Don\'t have the app yet?'
  }
};
