import React from "react";
import Moment from "react-moment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from '@material-ui/icons/Person';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import DebtIcon from '@material-ui/icons/Check';
import {useDebtStyles} from "../DebtStyles";
import {useTranslation} from "react-i18next";

const DebtUser = ({debt, debtUser, debtContactId}) => {
  const classes = useDebtStyles();
  const {t, i18n} = useTranslation();
  return (
    <List>
      <ListItem>
        <ListItemAvatar>
          {debtUser.picture ?
            <Avatar className={classes.avatarIcon} src={debtUser.picture}/>
            :
            <Avatar className={classes.avatarIcon}>
              <PersonIcon style={{fontSize: '1.1rem'}}/>
            </Avatar>
          }

        </ListItemAvatar>
        <ListItemText primary={debtUser.name} secondary={debtUser.email}/>
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar className={classes.avatarIcon}>
            <CalendarIcon style={{fontSize: '1.1rem'}}/>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<Moment format='LL' locale={i18n.language}>{debt.date}</Moment>}/>
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar className={classes.avatarIcon}>
            <DebtIcon style={{fontSize: '1.1rem'}}/>
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={t('common.debt')} secondary={debtContactId}/>
      </ListItem>
    </List>
  );
};

export default DebtUser;
