import React, {Fragment, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {useMutation} from '@apollo/react-hooks';
import Moment from 'react-moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Box, Divider} from '@material-ui/core';
import {
  COMPLETE_DEBT_CONTACT,
  GET_DEBTS,
  RESTORE_DEBT_CONTACT
} from '../../../../common/models/Debt';
import Item from '../../../../common/components/Item';
import ItemSkeleton from '../../../../common/components/ItemSkeleton';
import ConfirmationDialog from './ConfirmationDialog';
import Alert from '../../../../common/components/Alert';
import helpers from '../../../../common/helpers';

const getContactsDeclinedDebts = (debts) => {
  const contacts = [];
  if (debts && debts.length) {
    debts.forEach(debt => {
      debt.contacts.forEach((debtContact) => {
        if (debtContact.declined) {
          const currentContact = helpers.getCurrentContact(contacts, debtContact);
          if (currentContact) {
            currentContact.amount += debtContact.amount;
            currentContact.debts.push(debt);
          } else {
            contacts.push({
              _id: debtContact.contact._id,
              name: debtContact.contact.name,
              amount: debtContact.amount,
              debts: [debt]
            });
          }
        }
      });
    });
  }
  return contacts;
};

const getContactDeclinedDebts = (contact, contacts) => {
  if (!contacts.length || !contact)
    return [];

  const currentContact = contacts.filter(pc => pc.id === contact.id);
  return currentContact[0].debts;
};

const getDebtContactUid = (debt, contact) => {
  const _contact = debt.contacts.find(debtContact => debtContact.contact._id === contact._id);
  return _contact.uid;
};

const DeclinedDebts = (props) => {
  const {loading, onBalanceChange, debts} = props;
  const {t} = useTranslation();

  const [closeDebt] = useMutation(COMPLETE_DEBT_CONTACT);
  const [restoreDebt] = useMutation(RESTORE_DEBT_CONTACT);
  const contacts = getContactsDeclinedDebts(debts);
  const [currentContact, setCurrentContact] = React.useState();
  const declinedDebts = getContactDeclinedDebts(currentContact, contacts);
  const [confirmation, setConfirmation] = React.useState(null);
  const {enqueueSnackbar} = useSnackbar();

  const notifyPendingDebtsBalance = useCallback(() => {
    onBalanceChange(declinedDebts.length);
  }, [onBalanceChange, declinedDebts]);

  useEffect(() => {
    notifyPendingDebtsBalance();
  }, [notifyPendingDebtsBalance]);

  const handleCloseDebt = (id) => {
    setConfirmation({
      title: t('debts.confirmation.close.title'),
      message: t('debts.confirmation.close.message'),
      type: 0,
      data: id
    });
  };

  const handleRestoreDebt = (id) => {
    setConfirmation({
      title: t('debts.confirmation.restore.title'),
      message: t('debts.confirmation.restore.message'),
      type: 1,
      data: id
    });
  };

  const handleOk = async (type, data) => {
    setConfirmation(null);
    switch (type) {
      case 0:
        setCurrentContact(null);
        await closeDebt({
          variables: {debtContact: data},
          refetchQueries: [{query: GET_DEBTS, variables: {active: true}}],
        });
        enqueueSnackbar(t('debts.feedback.close'));
        break;
      case 1:
        setCurrentContact(null);
        await restoreDebt({
          variables: {debtContact: data},
          refetchQueries: [{query: GET_DEBTS, variables: {active: true}}],
        });
        enqueueSnackbar(t('debts.feedback.restore'));
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    setConfirmation(null);
  };

  if (!contacts.length) {
    return null;
  }

  return (
    <>
      <Typography variant='subtitle2'>{t('home.declinedDebts')}</Typography>
      <Box mt={1}/>
      {!!contacts.length && <Divider light/>}

      <Grid item container direction='column'>
        {loading && <><ItemSkeleton/> <ItemSkeleton/> <ItemSkeleton/></>}
        {contacts.map(contact =>
          <Item key={contact._id}
                title={contact.name}
                description={contact.debts.length > 1 ?
                  contact.debts.length + ` ${t('common.debt')}s` :
                  contact.debts.length + ` ${t('common.debt')}`
                }
                textIcon={contact.name.charAt(0)}
                value={contact.amount}
                onClick={() => setCurrentContact(contact)}
          />
        )}
      </Grid>

      <ConfirmationDialog
        show={!!currentContact}
        title={`${t('home.declinedDebts')} ${t('common.for')} ${currentContact ? currentContact.name : ''}`}
        onClose={() => setCurrentContact(null)}>
        {declinedDebts.map((debt, i) =>
          <Fragment key={debt.uid}>
            <Item title={<>Debt #{debt.uid}</>}
                  description={<>{debt.notes} @ <Moment format='LL'>{debt.date}</Moment></>}
                  icon="hourglass_empty"
                  value={`$${currentContact.amount}`}
                  actions={
                    [{
                      title: t('debts.close'),
                      icon: 'done_all',
                      onClick: () => handleCloseDebt(getDebtContactUid(debt, currentContact))
                    }, {
                      title: t('debts.restore'),
                      icon: 'restore',
                      onClick: () => handleRestoreDebt(getDebtContactUid(debt, currentContact))
                    }]
                  }
            />
            {!(i === (declinedDebts.length - 1)) && <Divider light/>}
          </Fragment>
        )}
      </ConfirmationDialog>
      <Alert show={!!confirmation}
             title={confirmation ? confirmation.title : ''}
             okAction={() => handleOk(confirmation.type, confirmation.data)}
             cancelAction={handleCancel}>
        {confirmation ? confirmation.message : null}
      </Alert>
    </>
  );
};

export default DeclinedDebts;
