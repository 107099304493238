import React from 'react';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import {DatePicker} from "@material-ui/pickers";

type Props = {
  label: string,
  icon: string,
  format: string,
  value: string,
  maxDate?: Date,
  minDate?: Date,
  onChange: ()=>void
}
const DateInput = (props: Props) => {
  const {label, icon, value, maxDate, minDate, format, onChange} = props;

  return (
    <Grid item container alignItems='center' justify='center' spacing={3}>
      <Grid item>
        <Icon color='disabled'>{icon}</Icon>
      </Grid>
      <Grid item style={{width: 300}}>
        <DatePicker
          style={{width: 280}}
          autoOk={true}
          maxDate={maxDate}
          minDate={minDate}
          variant="inline"
          format={format}
          label={label}
          value={value}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default DateInput;
