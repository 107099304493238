import React, {useContext} from 'react';
import Typography from '@material-ui/core/Typography';
import numeral from 'numeral';
import {AppContext} from "../../app";

const Amount = (props) => {
  const {state} = useContext(AppContext);
  const symbol = props.symbol || state.currency;
  let value = 0;

  if (props.children === null || props.children === undefined) {
    return null;
  }

  if (typeof props.children === 'string' || typeof props.children === 'number') {
    value = symbol + numeral(props.children).format('0,0.00');
  } else {
    throw new Error('Invalid input supplied. Input must be number or string');
  }

  return (
    <Typography component='span' {...props}>
      {value}
    </Typography>
  );
};

export default Amount;
