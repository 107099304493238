import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Debt} from '../../views/contact/Debt';
import {ROOT_PATH} from "../../config";


const ContactRedirect = () => {
  return <Redirect to={{pathname: ROOT_PATH}}/>
};

const ContactRoutes = ({match}) => {
  return (
    <Switch>
      <Route path={`${match.path}/debts/:id`} component={Debt}/>
      <Route path='*' component={ContactRedirect}/>
    </Switch>
  );
};

export default ContactRoutes;
