import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import Transition from "../../../common/components/Transition";

const PaymentDialog = (props) => {
  const {show, okAction, cancelAction} = props;
  const [amount, setAmount] = useState('');
  const [notes, setNotes] = useState('');
  const {t} = useTranslation();

  return (
    <Dialog
      TransitionComponent={Transition}
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{t('contact_pay_title')}</DialogTitle>
      <DialogContent>
        <TextField
          required
          autoFocus
          style={{width: 200}}
          type="number"
          label={t('common.amount')}
          fullWidth
          value={amount}
          onChange={(e) => setAmount(e.target.value)}/>
        <TextField
          label={t('common.notes')}
          multiline
          rows="2"
          margin="normal"
          fullWidth
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelAction}>
          {t('common.cancel')}
        </Button>
        <Button color="primary"
                disabled={!amount}
                onClick={() => okAction(amount, notes)}>
          {t('common.pay')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentDialog;
