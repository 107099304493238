import React, {useContext, useState} from 'react';
import {Redirect} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useLoginStyles} from "./LoginStyles";
import {AppContext} from '../../app/AppContext';
import logoImg from '../../common/images/debtrail.png';
import googleLogoImg from '../../common/images/google_logo.png';
import facebookLogoImg from '../../common/images/facebook.png';
import Welcome from '../../common/components/Welcome'
import useRouter from "../../common/hooks/useRouter";

function Login(props) {
  const classes = useLoginStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {state, dispatch, actions} = useContext(AppContext);
  const {history} = useRouter();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const user = await dispatch(actions.signIn(email, password));
    if (user) {
      dispatch(actions.clear());
      history.push('/user');
    }
  };

  const handleSignInWithGoogle = async () => {
    const user = await dispatch(actions.signInWithGoogle());
    if (user) {
      dispatch(actions.clear());
      history.push('/user');
    }
  };

  const handleSignInWithFacebook = async () => {
    const user = await dispatch(actions.signInWithFacebook());
    if (user) {
      dispatch(actions.clear());
      history.push('/user');
    }
  };

  const handleSignUp = () => {
    dispatch(actions.clear());
    history.push('/user/signup');
  };

  const handleForgotPass = () => {
    dispatch(actions.clear());
    history.push('/user/forgot-password');
  };

  let {from} = props.location.state || {from: {pathname: "/"}};
  if (state.user) return <Redirect to={from}/>;

  return (
    <Welcome>
      <img alt='' className={classes.avatar} src={logoImg}/>
      <Typography component="h1" variant="h5" color={"textSecondary"}>Sign In</Typography>
      {state.error !== '' &&
      <Typography component="h5" className={classes.errorMessage}>{state.error}</Typography>}
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="username">Email</InputLabel>
          <Input disabled={state.loading}
                 id="email"
                 name="email"
                 autoComplete="current-username"
                 autoFocus value={email}
                 onChange={(event) => setEmail(event.target.value)}/>
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input disabled={state.loading}
                 name="password"
                 type="password"
                 id="password"
                 autoComplete="current-password" value={password}
                 onChange={(event) => setPassword(event.target.value)}/>
        </FormControl>
        <Grid container direction={"column"} className={classes.wrapper} justify={"center"}>
          <Button disabled={state.loading} className={classes.submit}
                  type="submit"
                  variant="contained"
                  color="primary"
          >
            Sign in
          </Button>

          {state.loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
          <Box display='flex' justifyContent='center' flexDirection='column'>
            <Button variant="outlined" className={classes.submit} onClick={handleSignInWithGoogle}>
              <img alt='' src={googleLogoImg}
                   style={{height:30, marginRight:10}}/> Sign in with google
            </Button>
            <Button variant="outlined" className={classes.submit} onClick={handleSignInWithFacebook}>
              <img alt='' src={facebookLogoImg}
                   style={{height:36, marginRight:10}}/> Continue with facebook
            </Button>
          </Box>
          <Button color="primary"
                  className={classes.button}
                  style={{marginTop: 10}}
                  onClick={handleForgotPass}>
            FORGOT PASSWORD
          </Button>
        </Grid>
        <Grid container justify={"space-between"} style={{marginTop: 15}} alignItems={"center"}>
          <Typography variant="body1" color={"textSecondary"}>Don't have an account?</Typography>
          <Button color="primary" className={classes.button} onClick={handleSignUp}>Sign Up</Button>
        </Grid>
      </form>
    </Welcome>
  );
}

export {Login};
