import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Grid} from "@material-ui/core";
import {grey} from '@material-ui/core/colors';
import {Content} from "../../../common/components/Content";
import Profile from "./views/Profile";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    marginTop: 48
  },
  center: {
    maxWidth: 1024,
    width: '100%',
    margin: 'auto'
  },
  avatar: {
    marginTop: 20,
    width: 96,
    height: 96
  },
  title: {
    marginLeft: 20,
    marginRight: 15,
    fontSize: 16,
    color: grey[700]
  }
}));

function TabPanel(props) {
  const classes = useStyles();
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3} className={classes.center}>{children}</Box>
    </Typography>
  );
}

const Config = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const {t} = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Content noPadding>
      <div className={classes.root}>
        <AppBar color='default' position="fixed" elevation={0} style={{top: 64, background: '#fff'}}>
          <Grid container wrap='nowrap' alignItems='center' style={{maxWidth: 1024, margin: 'auto'}}>
            <Typography variant='h6' className={classes.title}>{t('common.config')}</Typography>
            <Tabs className={classes.center} value={value} onChange={handleChange}>
              <Tab label={t('common.profile')}/>
            </Tabs>
          </Grid>
        </AppBar>

        <TabPanel value={value} index={0}>
          <Profile/>
        </TabPanel>

      </div>
    </Content>
  );
};

export default Config;
