import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Box} from '@material-ui/core';
import WidgetHeader from "../../../../common/components/WidgetHeader";
import PendingPayments from "./PendingPayments";
import DeclinedDebts from "./DeclinedDebts";

const ActionsNeeded = (props) => {
  const {loading, debts} = props;
  const {t} = useTranslation();
  const [declinedDebts, setDeclinedDebts] = useState();
  const [pendingPayments, setPendingPayments] = useState();

  if (loading || !declinedDebts || !pendingPayments) {
    return null;
  }

  return (
    <Grid item>
      <Paper elevation={0} style={{padding: 20}}>
        <WidgetHeader title={t('home.actions')} description={t('home.info.actions')}/>
        <Box mt={2}/>
        <PendingPayments loading={loading} debts={debts} onBalanceChange={setPendingPayments}/>
        <Box mt={2}/>
        <DeclinedDebts loading={loading} debts={debts} onBalanceChange={setDeclinedDebts}/>
      </Paper>

    </Grid>
  );
};

export default ActionsNeeded;
