import 'moment/locale/es';
import React from 'react';
import ReactDOM from 'react-dom';
import App, {AppProvider} from './app';
import * as serviceWorker from './serviceWorker';
import apolloClient from "./apollo";
import {ApolloProvider} from "@apollo/react-hooks";

ReactDOM.render(<ApolloProvider client={apolloClient}>
  <AppProvider><App/></AppProvider>
</ApolloProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
