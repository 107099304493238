import React from 'react';
import {FormControl, Grid, Icon, TextField} from "@material-ui/core";

const StringInput = (props) => {
  const {icon, label, value, disabled = false, onChange} = props;

  return (
    <Grid item container alignItems='flex-end' justify='center' spacing={2}>
      <Grid item>
        <Icon color='disabled'>{icon}</Icon>
      </Grid>
      <Grid item>
        <FormControl>
          <TextField
            style={{width: 280}}
            label={label}
            margin="normal"
            fullWidth
            disabled={disabled}
            value={value}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default StringInput;
