import {gql} from 'apollo-boost';
import DebtReminders from './DebtReminders';
import ScheduleRecurrence from './ScheduleRecurrence';

export const GET_SCHEDULES = gql`{
  schedules {
    _id
    uid
    contacts  {
      contact {
        _id
        name
        phone
        email
      }
      amount
    }
    notes
    total
    reminders {
      interval
    }
    notifications {
      email
      text
    }
    recurrence {
      mode
      data
      date
    }
    editor {
      total
    }
    schedule
    deleted
    updatedAt
    createdAt
  }
}`;

export const GET_SCHEDULE = gql`
query GetSchedule($id: MongoID!) {
  schedule (id: $id) {
    _id
    contacts  {
      contact {
        _id
        name
        phone
        email
      }
      amount
    }
    notes
    total
    reminders {
      interval
    }
    notifications {
      email
      text
    }
    recurrence {
      mode
      data
      date
    }
    editor {
      total
    }
    schedule
    deleted
    updatedAt
    createdAt
  }
}`;

export const CREATE_SCHEDULE = gql`mutation CreateSchedule($schedule: ScheduleInput!) {
  createSchedule (schedule: $schedule) {
    _id
  }
}`;

export const UPDATE_SCHEDULE = gql`mutation UpdateSchedule($id: MongoID!, $schedule: ScheduleInput!) {
  updateSchedule (id: $id, schedule: $schedule) {
    _id
  }
}`;

export const DELETE_SCHEDULE = gql`mutation DeleteSchedule($id: MongoID!) {
  removeSchedule (id: $id) {
    _id
  }
}`;

class ScheduleNotifications {
  email = false;
  text = false;
}

class ScheduleEditor {
  total = true;
}

export class Schedule {
  _id;
  contacts = [];
  notes = '';
  reminders = new DebtReminders();
  notifications = new ScheduleNotifications();
  attachment = '';
  editor = new ScheduleEditor();
  recurrence = new ScheduleRecurrence();
  private = false;

  constructor(schedule) {
    if (schedule) {
      this._id = schedule._id;
      this.contacts = schedule.contacts;
      this.notes = schedule.notes;
      this.reminders = schedule.reminders;
      this.notifications = schedule.notifications;
      this.attachment = schedule.attachment;
      this.editor = schedule.editor;
      this.recurrence = schedule.recurrence;
      this.private = schedule.private;
    }
  }
}

class ScheduleContactInput {
  contact;
  amount;

  constructor(_contact) {
    this.contact = _contact && _contact.contact && _contact.contact._id;
    this.amount = _contact && _contact.amount;
  }
}

export class ScheduleInput {
  contacts = [];
  notes = '';
  reminders = new DebtReminders();
  notifications = new ScheduleNotifications();
  attachment = '';
  editor = new ScheduleEditor();
  recurrence = new ScheduleRecurrence();
  private = false;

  constructor(schedule) {
    if (schedule) {
      this.contacts = schedule.contacts && schedule.contacts.map(c => new ScheduleContactInput(c));
      this.notes = schedule.notes;
      this.reminders = schedule.reminders;
      this.notifications = schedule.notifications;
      this.attachment = schedule.attachment || null;
      this.editor = schedule.editor;
      this.recurrence = schedule.recurrence;
      this.private = schedule.private;
    }
  }
}
