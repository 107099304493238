import React from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import {useTranslation} from "react-i18next";

type Props = {
  value: string,
  onChange: ()=>{}
}
const NotesInput = (props: Props) => {
  const {value, onChange} = props;
  const {t} = useTranslation();

  return (
    <Grid item container alignItems='center' justify='center' spacing={3}>
      <Grid item>
        <Icon color='disabled'>notes</Icon>
      </Grid>
      <Grid item>
        <TextField
          style={{width: 280}}
          label={t('common.notes')}
          multiline
          rows="2"
          margin="normal"
          fullWidth
          value={value}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default NotesInput;
