import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Transition from '../../../../common/components/Transition';
import GoogleImg from '../../../../common/images/google-play-badge.png';
import AppleImg from '../../../../common/images/app-store-badge.png';
import {ANDROID_URL, IOS_URL} from "../../../../config";

const ReceiptDialog = (props) => {
  const {show, onClose} = props;

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle id='receipt-dialog-title'>
        Adding a receipt?
      </DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Typography>
              This feature is only available from the app. If you dont have the app yet you can
              download it from:
            </Typography>
          </Grid>
          <Grid item/>
          <Grid container justify={'center'}>
            <a href={ANDROID_URL}><img alt='' style={{height: "42px"}} src={GoogleImg}/></a>
            <a href={IOS_URL}> <img alt='' style={{height: "42px", marginLeft: 20}} src={AppleImg}/></a>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReceiptDialog;
