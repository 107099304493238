import React from 'react';
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";

type Props = {
  label: string,
  icon: string,
  value: string,
  onChange: ()=>{}
}
const RemainderBySelector = (props: Props) => {
  const {value, onChange, label, icon} = props;

  return (
    <Grid item container alignItems='center' justify='center' spacing={3}>
      <Grid item>
        <Icon color='disabled'>{icon}</Icon>
      </Grid>
      <Grid item style={{width: 300}}>
        <FormControlLabel
          control={
            <Checkbox checked={value}
                      onChange={onChange}
                      value="email"
                      color='primary'/>
          }
          label={label}
        />
      </Grid>
    </Grid>
  );
};

export default RemainderBySelector;
