import Contact from "./Contact";

export default class ScheduleContact {
  contact = new Contact();
  amount = 0;

  constructor(contact) {
    this.contact = contact;
  }
}
