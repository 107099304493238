import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/styles';
import {green, red} from '@material-ui/core/colors';
import GoogleImg from '../../common/images/google-play-badge.png';
import AppleImg from '../../common/images/app-store-badge.png';
import {ANDROID_URL, IOS_URL, SITE_URL} from "../../config";
import firebase from "../../firebase";
import {Link} from "@material-ui/core";

const useWelcomeStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    display: 'block', // Fix IE 11 issue.
  },
  gridContainer: {
    height: '100%'
  },
  gridFirstItem: {
    backgroundColor: '#e64a19',
    color: "white",
    padding: "0  40px",
    backgroundPosition: 'right center',
    backgroundSize: 'cover',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    maxWidth: '400px',
    [theme.breakpoints.up(500)]: {
      marginLeft: '-80px'
    },
    [theme.breakpoints.down(500)]: {
      margin: '10px'
    },
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    color: '#404040',
    fontWeight: 600
  },
  avatar: {
    height: 40,
    width: 40,
    margin: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
    minHeight: 50
  },
  wrapper: {
    margin: theme.spacing(3),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '70%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  errorMessage: {
    margin: theme.spacing(1),
    fontWeight: "bold",
    color: red[400],
    left: '50%',
  },
  link: {
    textDecoration: 'none',
    color: '#8a8a8a',
    fontWeight: '600',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));

export default function Welcome(props) {
  const classes = useWelcomeStyles();
  const {children} = props;

  const handleIosOpen = () => {
    firebase.analytics().logEvent('ios_open', {});
    window.open(IOS_URL, '_blank');
  };

  const handleAndroidOpen = () => {
    firebase.analytics().logEvent('android_open', {});
    window.open(ANDROID_URL, '_blank');
  };

  return (
    <main className={classes.main}>
      <CssBaseline/>
      <Grid container className={classes.gridContainer}>
        <Hidden smDown>
          <Grid item container sm={8} xs={4} className={classes.gridFirstItem}
                alignItems={"center"} justify={"flex-start"}>
            <Grid item container direction={"column"}>
              <Typography component="h1" variant="h2">Debtrail</Typography>
              <Typography component="h3" variant="h5" style={{marginTop: 15, paddingRight: 20}}>
                Follow the trail of your debtors to recover your money.
              </Typography>
              <Grid item container style={{marginTop: 30}}>
                <Link href='#' onClick={handleAndroidOpen}>
                  <img alt='Google Play' style={{height: 50}} src={GoogleImg}/>
                </Link>
                <Link href='#' onClick={handleIosOpen}>
                  <img alt='App Store' style={{height: 50, marginLeft: 10}}
                       src={AppleImg}/>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Grid item sm={4} xs={12} className={classes.gridSecondItem}>
          <Grid container direction='column'
                alignItems='flex-start'
                justify='center'
                className={classes.gridContainer}>
            <Paper className={classes.paper} elevation={4}>
              {children}
            </Paper>
            <Grid item style={{margin: '20px 60px 0'}}>
              <a href={`${SITE_URL}/privacy`} style={{marginRight: 15}}
                 className={classes.link}>Privacy</a>
              <a href={`${SITE_URL}/terms`} className={classes.link}>Terms</a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
}
