import {makeStyles} from '@material-ui/styles';
import {green, red} from '@material-ui/core/colors';

const useLoginStyles = makeStyles(theme => ({
  avatar: {
    height: 40,
    width: 40,
    margin: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
    minHeight: 50
  },
  wrapper: {
    margin: theme.spacing(3),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '70%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  errorMessage: {
    margin: theme.spacing(1),
    fontWeight: "bold",
    color: red[600],
    textAlign: 'center'
  },
  link: {
    color: "inherit",
    textDecoration: "none"
  }
}));

export {useLoginStyles};
