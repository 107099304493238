import React, {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/react-hooks';
import Moment from 'react-moment';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {Box, Divider} from '@material-ui/core';

import {CREATE_DEBT_CONTACT_PAYMENT} from '../../../../common/models/DebtContact';
import {DECLINE_DEBT_CONTACT, GET_DEBTS, GET_DEBTS_REQUESTS} from '../../../../common/models/Debt';
import Item from '../../../../common/components/Item';
import Amount from '../../../../common/components/Amount';
import ItemSkeleton from '../../../../common/components/ItemSkeleton';
import Alert from '../../../../common/components/Alert';
import helpers from '../../../../common/helpers';
import WidgetHeader from "../../../../common/components/WidgetHeader";

import PaymentDialog from "../../../../views/contact/components/PaymentDialog";
import ConfirmationDialog from './ConfirmationDialog';
import Empty from "../../../../common/components/Empty";
import RequestImg from "../../../../common/images/debts_requests_empty.png";
import {useSnackbar} from "notistack";

const getContacts = (debts) => {
  const contacts = [];
  if (debts) {
    debts.forEach(debt => {
      const currentContact = contacts.find(contact => contact.id === debt.user.user);
      const paid = helpers.getPaymentsAmount(debt.contacts[0].payments);
      const amount = debt.contacts[0].amount;
      const declined = debt.contacts[0].declined;
      if (amount - paid > 0 && !declined) {
        if (currentContact) {
          currentContact.amount += amount;
          currentContact.paid += paid;
          currentContact.debts.push({
            amount: debt.contacts[0].amount,
            payments: debt.contacts[0].payments,
            status: debt.contacts[0].status,
            uid: debt.contacts[0].uid,
          });
        } else {
          contacts.push({
            id: debt.user.user,
            name: debt.user.name,
            picture: debt.user.picture,
            amount: amount,
            paid: paid,
            debts: [{
              date: debt.date,
              notes: debt.notes,
              amount: debt.contacts[0].amount,
              payments: debt.contacts[0].payments,
              status: debt.contacts[0].status,
              uid: debt.contacts[0].uid,
            }]
          })
        }
      }
    })
  }
  return contacts;
};

const PendingRequests = (props) => {
  const {loading, debts} = props;
  const {t} = useTranslation();
  const [createPayment] = useMutation(CREATE_DEBT_CONTACT_PAYMENT);
  const [rejectDebt] = useMutation(DECLINE_DEBT_CONTACT);
  const pendingContacts = getContacts(debts);
  const getTotalAmount = pendingContacts.reduce((amount, contact) => amount + contact.amount, 0);
  const getTotalPaid = pendingContacts.reduce((paid, contact) => paid + contact.paid, 0);
  const [pendingContact, setPendingContact] = useState();
  const [feedbackError, setFeedbackError] = useState();
  const [feedbackText, setFeedbackText] = useState();
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [contactPartialPayment, setContactPartialPayment] = useState();
  const [confirmation, setConfirmation] = React.useState(null);
  const {enqueueSnackbar} = useSnackbar();

  const handlePayment = async (contact, amount, notes = undefined) => {
    const {errors} = await createPayment({
      variables: {
        debtContact: contact,
        amount: parseFloat(amount),
        notes,
      },
      errorPolicy: 'all',
      refetchQueries: [{query: GET_DEBTS_REQUESTS}],
    });
    if (errors) {
      const [error] = errors;
      if (error.extensions.code === 'DEBT_INVALID_PAYMENT') {
        setFeedbackError(t('contact_pay_invalid'));
      }
    } else {
      setFeedbackText(t('contact_pay_success'));
      setPendingContact(null);
    }
    setShowFeedbackDialog(true);
  };

  const handlePartialPayment = async (amount, notes) => {
    const contact = contactPartialPayment;
    setContactPartialPayment(null);
    await handlePayment(contact, amount, notes);
  };

  const handleFeedbackClose = () => {
    setShowFeedbackDialog(false);
    setFeedbackError(null);
  };


  const handleRejectDebt = (id) => {
    setConfirmation({
      title: t('debts.confirmation.decline.title'),
      message: t('debts.confirmation.decline.message'),
      type: 0,
      data: id
    });
  };

  const handlePayInfull = (uid, amount) => {
    setConfirmation({
      title: t('debts.confirmation.payInFull.title'),
      message: t('debts.confirmation.payInFull.message'),
      type: 1,
      data: {uid, amount}
    });
  };


  const handleOk = async (type, data) => {
    setConfirmation(null);
    switch (type) {
      case 0:
        await rejectDebt({
          variables: {debtContact: data},
          refetchQueries: [{query: GET_DEBTS, variables: {active: true}}],
        });
        enqueueSnackbar('Debt was rejected!');
        break;
      case 1:
        await handlePayment(data.uid, data.amount);
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    setConfirmation(null);
  };

  return (
    <Grid item>
      <Paper elevation={0} style={{padding: 20}}>
        <WidgetHeader title={t('common.requests')} description={t('home.info.requests')}/>

        <Box mt={1}/>
        {getTotalAmount - getTotalPaid > 0 && <>
          <Amount variant='h4' style={{fontWeight: 300}}>{getTotalAmount - getTotalPaid}</Amount>
          <Box mt={2}/>
          <Divider light/>
        </>}

        <Grid item container direction='column'>
          {loading && <><ItemSkeleton/><ItemSkeleton/><ItemSkeleton/></>}
          {pendingContacts.filter(contact => contact.paid < contact.amount)
            .map(contact =>
              <Item key={contact.id}
                    title={contact.name}
                    description={contact.debts.length > 1 ?
                      contact.debts.length + ` ${t('common.debt')}s` :
                      contact.debts.length + ` ${t('common.debt')}`
                    }
                    icon={contact.picture}
                    textIcon={contact.name.charAt(0)}
                    value={contact.amount - contact.paid}
                    onClick={() => setPendingContact(contact)}
              />
            )}
        </Grid>

        {getTotalAmount - getTotalPaid === 0 &&
        <Empty image={RequestImg} section='requests' showTitle={false}/>
        }

      </Paper>

      <ConfirmationDialog
        show={!!pendingContact}
        title={`${t('common.requestsBy')} ${pendingContact ? pendingContact.name : ''}`}
        onClose={() => setPendingContact(null)}>
        {pendingContact && pendingContact.debts.map((debt, i) => {
            const amount = debt.amount - helpers.getPaymentsAmount(debt.payments);

            if (!amount) {
              return null;
            }

            return <Fragment key={debt.uid}>
              <Item title={<>Debt #{debt.uid}</>}
                    description={<>{debt.notes} @ <Moment format='LL'>{debt.date}</Moment></>}
                    icon="hourglass_empty"
                    value={`$${amount}`}
                    actions={
                      [{
                        title: t('contact.pay.full'),
                        icon: 'done_all',
                        onClick: () => handlePayInfull(debt.uid, amount)
                      }, {
                        title: t('contact.pay.partial'),
                        icon: 'check',
                        onClick: () => setContactPartialPayment(debt.uid)
                      },
                        {
                          title: t('contact.pay.decline'),
                          icon: 'close',
                          onClick: () => handleRejectDebt(debt.uid)
                        }]
                    }
              />
              {!(i === (pendingContact.debts.length - 1)) && <Divider light/>}
            </Fragment>
          }
        )}
      </ConfirmationDialog>

      <PaymentDialog
        show={!!contactPartialPayment}
        cancelAction={() => setContactPartialPayment(null)}
        okAction={handlePartialPayment}/>

      <Alert show={showFeedbackDialog}
             title={feedbackError ? t('contact_pay_error_title') : t('contact_pay_success_title')}
             okActionText="Ok"
             okAction={handleFeedbackClose}>
        {feedbackError && <Typography>{feedbackError}</Typography>}
        {feedbackText && <Typography>{feedbackText}</Typography>}
      </Alert>

      <Alert show={!!confirmation}
             title={confirmation ? confirmation.title : ''}
             okAction={() => handleOk(confirmation.type, confirmation.data)}
             cancelAction={handleCancel}>
        {confirmation ? confirmation.message : null}
      </Alert>
    </Grid>
  );
};

export default PendingRequests;
