import React, {Fragment, useEffect, useState} from 'react';
import moment from 'moment'

import {useMutation, useQuery} from '@apollo/react-hooks';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Paper from '@material-ui/core/Paper';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import {Content} from '../../../common/components/Content';
import Item from '../../../common/components/Item';
import {DELETE_SCHEDULE, GET_SCHEDULES} from '../../../common/models/Schedule';
import EmptySchedulesImg from '../../../common/images/schedules_empty.png';
import Empty from '../../../common/components/Empty';
import Amount from '../../../common/components/Amount';

import {useSchedulesStyles} from './SchedulesStyles';
import {useTranslation} from "react-i18next";
import Alert from "../../../common/components/Alert";
import ItemSkeleton from "../../../common/components/ItemSkeleton";

const Schedules = (props) => {
  const {history} = props;
  const classes = useSchedulesStyles();
  const {loading, error, data, refetch} = useQuery(GET_SCHEDULES);
  const [deleteSchedule] = useMutation(DELETE_SCHEDULE);
  const schedules = data && data.schedules ? data.schedules : [];
  const [selected, setSelected] = useState();
  const [deletingSchedule, setDeletingSchedule] = useState();
  const [mounted, isMounted] = useState(false);
  const {t} = useTranslation();

  if (!mounted) {
    refetch();
  }

  useEffect(() => {
    isMounted(true);
  }, []);

  const getRecurrence = (mode, data) => {
    switch (mode) {
      case 0:
        return t('schedules.recurrence.future', {date: moment(data).format('LL')});
      case 1:
        return t('schedules.recurrence.monthly', {day: data});
      case 2:
        return t('schedules.recurrence.biweekly', {day1: data, day2: parseInt(data) + 15});
      default:
        break
    }
  };

  const handleEdit = (id) => {
    history.push(`/user/schedules/${id}`)
  };

  const handleDelete = async () => {
    await deleteSchedule({
      variables: {id: deletingSchedule},
      refetchQueries: [{query: GET_SCHEDULES}],
      awaitRefetchQueries: true
    });
    setDeletingSchedule(null);
    setSelected(null);
  };

  return (
    <Content>
      <Grid container style={{maxWidth: 1024, margin: 'auto', marginBottom: 20}}>
        <Grid item xs style={{paddingRight: 20}}>
          <Grid item container direction='row' justify='space-between'>
            <Grid item/>
            <Grid item>
              <Link to='schedules/new' className={classes.link}>
                <Button variant='contained' color='primary'>{t('common.addNew')}</Button>
              </Link>
            </Grid>
          </Grid>
          <Box p={2}/>
          <Paper elevation={0}>
            <Box p={2}>
              {loading && <><ItemSkeleton/> <ItemSkeleton/> <ItemSkeleton/></>}
              {error && <p>Error :</p>}

              {!loading && schedules.length > 0 &&
              <Grid item container direction='column'>
                {schedules.map((schedule, i) =>
                  <Fragment key={schedule._id}>
                    <Item title={`${t('common.schedule')} #${schedule.uid}`}
                          description={
                            getRecurrence(schedule.recurrence.mode, schedule.recurrence.data)
                          }
                          icon='schedule'
                          value={`$${schedule.total.toFixed(2)}`}
                          selected={selected && schedule._id === selected._id}
                          onClick={() => setSelected(schedule)}
                    />
                    {!(i === (schedules.length - 1)) && <Divider light/>}
                  </Fragment>
                )}
              </Grid>}

              {!loading && schedules.length === 0 &&
              <Empty image={EmptySchedulesImg} section='schedules'/>
              }
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={5} style={{paddingLeft: 20}}>
          {selected && <Box position='fixed' minWidth='300px' height='calc(100% - 110px)'>
            <Grid container wrap='nowrap' direction='column' spacing={2} style={{height: '100%'}}>
              <Grid item container alignItems='center' justify='space-between'>
                <Grid item xs>
                  <Typography variant='h6'>{t('schedules.details')}</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => handleEdit(selected._id)}>
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton onClick={() => setDeletingSchedule(selected._id)}>
                    <Icon>delete</Icon>
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item>
                <Typography>
                  {t('common.schedule')} #{selected.uid}
                </Typography>
              </Grid>
              <Grid item>
                <Box m={2}/>
                <Typography variant='h4'>
                  ${selected.total.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item>
                <Box m={2}/>
                <Divider light/>
              </Grid>
              <Grid item style={{height: '100%', overflow: 'auto'}}>
                <Box m={1}/>
                <Typography variant='subtitle1'>
                  {t('common.contacts')}
                </Typography>
                <Box m={1}/>
                <List>
                  {selected.contacts.map(debtContact => (
                    <ListItem key={debtContact.contact._id}>
                      <ListItemAvatar>
                        <Avatar className={classes.sidebarAvatar}>
                          {debtContact.contact.name.charAt(0)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={debtContact.contact.name}
                                    secondary={debtContact.contact.phone ?
                                      debtContact.contact.phone :
                                      debtContact.contact.email}/>
                      <ListItemSecondaryAction>
                        <Amount variant='h6'>{debtContact.amount}</Amount>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Box>
          }
        </Grid>

      </Grid>
      <Alert title={t('schedules.deleteWarning.title')}
             show={!!deletingSchedule}
             okAction={handleDelete}
             okActionText={t('common.yes')}
             cancelActionText='No'
             cancelAction={() => {
               setDeletingSchedule(null);
             }}>
        {t('schedules.deleteWarning.message')}
      </Alert>
    </Content>
  );
};

export default Schedules;
