import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {
  Button,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Paper,
  Select
} from "@material-ui/core";
import helpers from "../../../../common/helpers";
import {AppContext} from "../../../../app";
import StringInput from "../components/StringInput";
import {useTranslation} from "react-i18next";
import UploadAvatar from "../../../../common/components/UploadAvatar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    marginTop: 48
  },
  center: {
    maxWidth: 1024,
    width: '100%',
    margin: 'auto'
  },
  avatar: {
    marginTop: 20,
    width: 96,
    height: 96,
    background: theme.palette.primary.light
  }
}));

const Profile = () => {
  const classes = useStyles();
  const {state, dispatch, actions} = useContext(AppContext);
  const [user, setUser] = React.useState(state.user);
  const [loading, setLoading] = React.useState(false);
  const {t} = useTranslation();

  const handleInputChange = name => e => {
    setUser({...user, [name]: e.target.value});
  };

  const handlePictureChange = picture => {
    console.log(picture)
    setUser({...user, picture});
  };


  const handleSave = async () => {
    setLoading(true);
    const res = await dispatch(actions.updateUserProfile(user));
    if (!res) {

    }
    setLoading(false);
  };

  const getErrors = () => {
    const validateEmail = RegExp(/[^@]+@[^]+\..+/g);

    return {
      email: !user.email && !validateEmail.test(user.email),
      name: !user.name,
      loading
    };
  };

  return (
    <Grid container direction='column' style={{maxWidth: 580, margin: 'auto'}}>
      <Grid item>
        <Paper elevation={0} className={classes.paper}>
          <Box p='20px'>
            <Grid container direction='column' alignItems='center' spacing={1}>

              <Grid item container alignItems='center' justify='center'>
                <UploadAvatar picture={user.picture} onChange={handlePictureChange}/>
              </Grid>

              <StringInput
                label={t('common.name')}
                icon='person'
                value={user.name}
                onChange={handleInputChange('name')}/>

              <StringInput
                label={t('common.phone')}
                icon='phone'
                value={user.phone || ''}
                onChange={handleInputChange('phone')}/>

              <StringInput
                label={t('common.email')}
                icon='email'
                value={user.email}
                disabled={true}
                onChange={handleInputChange('email')}/>

              <Grid item container alignItems='flex-end'
                    justify='center' spacing={2} style={{marginTop: 8}}>
                <Grid item>
                  <Icon color='disabled'>language</Icon>
                </Grid>
                <Grid item>
                  <FormControl>
                    <InputLabel htmlFor="language">{t('common.language')}</InputLabel>
                    <Select
                      id='language-selector'
                      name='language-selector'
                      style={{width: 280, marginBottom: 8}}
                      inputProps={{
                        name: 'language',
                      }}
                      value={user.language || 'en'}
                      onChange={handleInputChange('language')}
                    >
                      <MenuItem value={'en'}>{t('languages.en')}</MenuItem>
                      <MenuItem value={'es'}>{t('languages.es')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container
                    alignItems='flex-end'
                    justify='center'
                    spacing={2}
                    style={{marginTop: 8}}>
                <Grid item>
                  <Icon color='disabled'>euro_symbol</Icon>
                </Grid>
                <Grid item>
                  <FormControl>
                    <InputLabel htmlFor="language">{t('common.currency')}</InputLabel>
                    <Select
                      id='currency-selector'
                      name='currency-selector'
                      style={{width: 280, marginBottom: 8}}
                      inputProps={{
                        name: 'currency',
                      }}
                      value={user.currency || '$'}
                      onChange={handleInputChange('currency')}
                    >
                      <MenuItem value={'$'}>Dollar ($)</MenuItem>
                      <MenuItem value={'€'}>Euro (€)</MenuItem>
                      <MenuItem value={'£'}>Libra (£)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item>
                <Box p={3}>
                  <Button
                    disabled={!helpers.canSubmit(getErrors())}
                    variant='contained'
                    color='primary'
                    onClick={handleSave}>
                    {loading ? `${t('common.saving')}...` : t('common.save')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/*<Box p='20px'/>*/}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Profile;
