import React, {useEffect, useRef, useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    width: 'auto'
  },
  mainInput: {
    border: 'none',
    color: theme.palette.primary.main,
    fontSize: '132px',
    '&:focus': {
      outline: 0
    },
    '&::placeholder': {
      color: theme.palette.primary.light,
      fontSize: '132px',
    }
  },
  fractionInput: {
    border: 'none',
    color: theme.palette.primary.main,
    fontSize: '60px',
    '&:focus': {
      outline: 0
    },
    '&::placeholder': {
      color: theme.palette.primary.light,
      fontSize: '60px',
    }
  },
}));

type Props = {
  value: string,
  onChange: (value: string)=>void;
}

const AmountInput = (props: Props) => {
  const {value, onChange} = props;
  const arrValue = value ? value.toString().split('.') : [];
  const classes = useStyles();
  const [mainValue, setMainValue] = useState('');
  const [showFractionValue, setShowFractionValue] = useState(false);
  const [fractionValue, setFractionValue] = useState('');

  const iFractionRef = useRef();
  const iMainRef = useRef();

  useEffect(() => {
    const _mainVal = arrValue[0] ? arrValue[0] : '';
    const _fractionVal = arrValue[1] ? arrValue[1] : '';
    setMainValue(_mainVal);
    setFractionValue(_fractionVal);
    if (_fractionVal) {
      setShowFractionValue(true)
    }
  }, [arrValue]);

  const handleMainInputChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value.includes('.') || e.target.value.includes(',')) {
      setShowFractionValue(true);
      setTimeout(() => {
        iFractionRef.current.focus();
      }, 10);
    }
    if (e.target.value === '' || re.test(e.target.value)) {
      setMainValue(e.target.value);
      handleChange(e.target.value, fractionValue);
    }

  };

  const handleFractionInputChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '') {
      setShowFractionValue(false);
      setTimeout(() => {
        iMainRef.current.focus();
      }, 10);
    }
    if (e.target.value === '' || (re.test(e.target.value) && e.target.value.length < 3)) {
      setFractionValue(e.target.value);
      handleChange(mainValue, e.target.value);
    }
  };

  const handleChange = (_mainValue, _fractionValue) => {
    const value = _mainValue + (_fractionValue ? '.' + _fractionValue : '');
    onChange(value ? value : '');
  };

  return (
    <Grid item container spacing={1} alignItems='flex-start' className={classes.root}>
      <Grid item>
        <Typography color='primary' variant='h5'
                    style={{marginTop: 15, fontSize: '3rem'}}>$</Typography>
      </Grid>
      <Grid item>
        <input autoFocus ref={iMainRef} className={classes.mainInput} value={mainValue}
               placeholder='0'
               onChange={handleMainInputChange}
               style={{width: `${mainValue ? mainValue.length * 80 : 80}px`}}/>
      </Grid>
      {showFractionValue && <Grid item>
        <input ref={iFractionRef} className={classes.fractionInput} value={fractionValue}
               style={{width: 80, marginTop: 15}}
               placeholder='00'
               onChange={handleFractionInputChange}/>
      </Grid>}
    </Grid>
  );
};

export default AmountInput;
