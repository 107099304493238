import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {ROOT_PATH} from '../config';
import ContactRoutes from "../views/contact/ContactRoutes";
import UserRoutes from "../views/user/UserRoutes";

const AppRoutes = () => {
  return (
    <Switch>
      <Route path={`${ROOT_PATH}contact`} component={ContactRoutes}/>
      <Route path='/user' component={UserRoutes}/>

      <Route path='*' component={() => <Redirect to={{pathname: ROOT_PATH + 'user'}}/>}/>
    </Switch>
  );
};

export default AppRoutes;
