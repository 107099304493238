import React, {useEffect, useState} from 'react';
import {useMutation, useQuery} from "@apollo/react-hooks";

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import {
  CREATE_DEBT_CONTACT_PAYMENT,
  DECLINE_DEBT_CONTACT,
  GET_DEBT_CONTACT,
  RESUBSCRIBE_DEBT_CONTACT,
  UNSUBSCRIBE_DEBT_CONTACT,
  VIEW_DEBT_CONTACT
} from "../../common/models/DebtContact";

import Amount from "../../common/components/Amount";
import NotFoundImg from "../../common/images/undraw_empty.svg";
import CompletedImg from "../../common/images/undraw_completed.svg";
import PaymentsDialog from "./components/PaymentsDialog";
import PaymentDialog from "./components/PaymentDialog";
import {useDebtStyles} from "./DebtStyles";
import Alert from "../../common/components/Alert";
import {useTranslation} from "react-i18next";
import AppLoader from "../../app/AppLoader";
import DebtUser from "./components/DebtUser";
import DebtHeader from "./components/DebtHeader";
import DebtFooter from "./components/DebtFooter";

function PrimaryButton(props) {
  const {onClick, children} = props;
  return (
    <Button variant='contained' color="primary" size="large" style={{width: '220px'}}
            onClick={onClick}>
      {children}
    </Button>
  )
}

function SecondaryButton(props) {
  const {onClick, children} = props;
  return (
    <Button variant='outlined' color="primary" size="large" style={{width: '220px'}}
            onClick={onClick}>
      {children}
    </Button>
  )
}

function DeclineButton(props) {
  const {onClick, children} = props;
  return (
    <Button variant='outlined' size="large" style={{width: '220px'}}
            onClick={onClick}>
      {children}
    </Button>
  )
}

function DetailsButton(props) {
  const {onClick, children} = props;
  return (
    <Button variant='text' color="primary" size="large" style={{width: '220px'}}
            onClick={onClick}>
      {children}
    </Button>
  )
}

function NotFound() {
  const {t} = useTranslation();
  return (
    <Grid container direction="column">
      <img style={{height: 200, margin: '15px auto'}} src={NotFoundImg} alt="Not Found"/>
      <Typography component="h1"
                  variant="h6"
                  align={"center"}
                  style={{color: "#646464", marginTop: 15}}>
        {t('contact_not_found')}
      </Typography>
    </Grid>
  );
}

function Completed() {
  const {t} = useTranslation();
  return (
    <Grid container direction="column">
      <img style={{height: 200, margin: '15px auto'}} src={CompletedImg} alt="Completed"/>
      <Typography component="h1"
                  variant="h6"
                  align={"center"}
                  style={{color: "#646464", marginTop: 15}}>
        {t('contact_completed')}
      </Typography>
    </Grid>
  );
}

function LoadingContent() {
  return (
    <div style={{display: 'flex', height: '100vh', overflow: 'hidden', width: '100%'}}>
      <AppLoader/>
    </div>
  )
}

function Debt(props) {
  const classes = useDebtStyles();
  const {match} = props;
  const debtContactId = match.params ? match.params.id : null;
  const {t, i18n} = useTranslation();
  const {loading, data} = useQuery(GET_DEBT_CONTACT, {
    variables: {debtContact: debtContactId},
    onCompleted: async ({debtContact}) => {
      if (debtContact) {
        await i18n.changeLanguage(debtContact.contacts[0].contact.language);
      }
    }
  });
  const [createPayment] = useMutation(CREATE_DEBT_CONTACT_PAYMENT);
  const [unsubscribeDebtContact] = useMutation(UNSUBSCRIBE_DEBT_CONTACT);
  const [resubscribeDebtContact] = useMutation(RESUBSCRIBE_DEBT_CONTACT);
  const [declineDebtContact] = useMutation(DECLINE_DEBT_CONTACT);
  const [viewDebtContact] = useMutation(VIEW_DEBT_CONTACT);
  const debt = data ? data.debtContact : null;
  const debtUser = debt ? debt.user : null;
  const debtContact = debt ? debt.contacts[0] : null;
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [showPaymentsDialog, setShowPaymentsDialog] = useState(false);
  const [payingInFull, setPayingInFull] = useState();
  const [declining, setDeclining] = useState();
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showUnsubscribeDialog, setShowUnsubscribeDialog] = useState(false);
  const [feedbackError, setFeedbackError] = useState();
  const [feedbackText, setFeedbackText] = useState();

  /* eslint-disable */
  useEffect(() => {
    if (debtContact && !debtContact.viewed) {
      viewDebtContact({
        variables: {
          debtContact: debtContactId,
        }
      });
    }
  }, [debtContact]);

  const handlePayment = async (amount, notes = undefined) => {
    setPayingInFull(null);
    const {errors} = await createPayment({
      variables: {
        debtContact: debtContactId,
        amount: parseFloat(amount),
        notes,
      },
      errorPolicy: 'all',
    });
    if (errors) {
      const [error] = errors;
      if (error.extensions.code === 'DEBT_INVALID_PAYMENT') {
        setFeedbackError(t('contact_pay_invalid'));
      }
    } else {
      setFeedbackText(t('contact_pay_success'));
    }
    setShowFeedbackDialog(true);
  };

  const handlePartialPayment = async (amount, notes) => {
    setShowPaymentDialog(false);
    await handlePayment(amount, notes);
  };

  const handleDeclineDebt = async () => {
    setDeclining(false);
    const {errors} = await declineDebtContact({
      variables: {
        debtContact: debtContactId,
      },
      errorPolicy: 'all',
    });
    if (errors) {
      setFeedbackError(t('common.error.description'));
    } else {
      setFeedbackText(t('debts.feedback.declineDebt'));
    }
    setShowFeedbackDialog(true);
  };

  const handleUnsubscribe = async () => {
    setShowUnsubscribeDialog(false);
    await unsubscribeDebtContact({
      variables: {
        debtContact: debtContactId,
      },
      errorPolicy: 'all',
    });
    setFeedbackText(t('contact.unsubscribe_feedback'));
    setShowFeedbackDialog(true);
  };

  const handleResubscribe = async () => {
    await resubscribeDebtContact({
      variables: {
        debtContact: debtContactId,
      },
      errorPolicy: 'all',
    });
    setFeedbackText(t('contact.resubscribe_feedback'));
    setShowFeedbackDialog(true);
  };

  const handleFeedbackClose = () => {
    if (feedbackText) {
      window.close();
    }
    setShowFeedbackDialog(false);
    setFeedbackError(null);
  };

  const isDebtContactValid = () => debt && !debt.deleted && debtContact && !debtContact.declined;

  if (loading) {
    return (<LoadingContent/>)
  }

  return (
    <main className={classes.main}>
      <CssBaseline/>
      {isDebtContactValid() && debtContact.status !== "completed" && !loading &&
      <Grid container direction="column" className={classes.gridContainer} alignItems='center'
            wrap={"nowrap"}>
        <DebtHeader/>
        <Grid item container alignItems='center' justify='center'>
          <Paper className={classes.paper} elevation={0}>
            <Typography component="h1"
                        variant="h5"
                        align={"center"}>
              {t('contact_hello', {contact: debtContact.contact.name})}
            </Typography>
            <Typography component="h2"
                        variant="body1"
                        align={"center"}
                        style={{marginTop: 10}}>
              {t('contact_name_subtitle')}
            </Typography>

            <Amount component="h3"
                    variant="h3"
                    color='primary'
                    align={"center"}
                    style={{marginTop: 15}}
                    symbol={debtUser.currency}>
              {debtContact.pending}
            </Amount>

            {debt.notes &&
            <Typography component="h2"
                        variant="body1"
                        align={"center"}
                        style={{marginTop: 10}}>
              {debt.notes}
            </Typography>
            }

            <Grid item container direction='column' justify='center' alignItems='center'
                  style={{marginTop: 20}}
                  spacing={1}>
              <Grid item>
                <PrimaryButton onClick={() => setPayingInFull(debtContact.pending)}>
                  {t('contact_pay_full')}
                </PrimaryButton>
              </Grid>
              <Grid item>
                <SecondaryButton onClick={() => setShowPaymentDialog(true)}>
                  {t('contact_pay_partial')}
                </SecondaryButton>
              </Grid>
              {debtContact.payments.length !== 0 &&
              <Grid item>
                <DetailsButton onClick={() => setShowPaymentsDialog(true)}>
                  {t('contact_payments')}
                </DetailsButton>
              </Grid>
              }
              {debtContact.payments.length === 0 &&
              <Grid item>
                <DeclineButton onClick={() => setDeclining(true)}>
                  {t('debts.confirmation.decline.title')}
                </DeclineButton>
              </Grid>
              }
            </Grid>

            <Divider light style={{marginTop: 30}}/>

            <Typography component="p" variant="caption" align={"center"} style={{marginTop: 25}}>
              {t('contact_disclaimer', {user: debtUser.name})}
            </Typography>

            <DebtUser
              debt={debt}
              debtUser={debtUser}
              debtContactId={debtContactId}
            />

          </Paper>
        </Grid>

        <DebtFooter
          onUnsubscribe={() => setShowUnsubscribeDialog(true)}
          onResubscribe={handleResubscribe}
        />

        <PaymentDialog
          maxAmount={debtContact.pending}
          show={showPaymentDialog}
          cancelAction={() => setShowPaymentDialog(false)}
          okAction={handlePartialPayment}/>

        <PaymentsDialog
          user={debtUser}
          payments={debtContact.payments}
          show={showPaymentsDialog}
          okAction={() => setShowPaymentsDialog(false)}/>

        <Alert show={!!payingInFull}
               title={t('contact.pay.confirmation.title')}
               okActionText={t('common.yes')}
               okAction={() => handlePayment(payingInFull)}
               cancelActionText="No"
               cancelAction={() => setPayingInFull(null)}>
          <Typography>{t('contact.pay.confirmation.description')}</Typography>
        </Alert>

        <Alert show={!!declining}
               title={t('common.confirmation')}
               okActionText={t('common.yes')}
               okAction={() => handleDeclineDebt()}
               cancelActionText="No"
               cancelAction={() => setDeclining(null)}>
          <Typography>{t('debts.confirmation.decline.message')}</Typography>
        </Alert>

        <Alert show={showFeedbackDialog}
               title={feedbackError ? t('contact_pay_error_title') : t('contact_pay_success_title')}
               okActionText="Ok"
               okAction={handleFeedbackClose}>
          {feedbackError && <Typography>{feedbackError}</Typography>}
          {feedbackText && <Typography>{feedbackText}</Typography>}
        </Alert>

        <Alert show={showUnsubscribeDialog}
               title={t('common.confirmation')}
               okActionText={t('common.yes')}
               okAction={handleUnsubscribe}
               cancelActionText="No"
               cancelAction={() => setShowUnsubscribeDialog(false)}
        >
          {t('contact.unsubscribe_dialog')}
        </Alert>

      </Grid>
      }
      {(!isDebtContactValid() || debtContact.status === "completed") && !loading &&
      <Grid container
            direction="column"
            className={classes.gridContainer}
            alignItems='center'
            wrap={"nowrap"}>
        <DebtHeader/>
        <Grid item container alignItems='center' justify='center'>
          <Paper className={classes.paper} elevation={0}>
            {!isDebtContactValid() && <NotFound/>}
            {isDebtContactValid() && debtContact.status === "completed" && <Completed/>}
          </Paper>
        </Grid>
        <DebtFooter
          onUnsubscribe={() => setShowUnsubscribeDialog(true)}
          onResubscribe={handleResubscribe}
        />
      </Grid>
      }
    </main>
  );
}

export {Debt};
