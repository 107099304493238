import React, {Fragment, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {useMutation} from '@apollo/react-hooks';
import Moment from 'react-moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Box, Divider} from '@material-ui/core';
import {
  CONFIRM_DEBT_CONTACT_PAYMENT,
  REJECT_DEBT_CONTACT_PAYMENT
} from '../../../../common/models/DebtContact';
import {GET_DEBTS} from '../../../../common/models/Debt';
import Item from '../../../../common/components/Item';
import ItemSkeleton from '../../../../common/components/ItemSkeleton';
import ConfirmationDialog from './ConfirmationDialog';
import Alert from '../../../../common/components/Alert';
import helpers from '../../../../common/helpers';

const getContactsPendingPayments = (debts) => {
  const contacts = [];
  if (debts && debts.length) {
    debts.forEach(debt => {
      debt.contacts.forEach((debtContact) => {
        if (debtContact.status === 'pending') {
          const currentContact = helpers.getCurrentContact(contacts, debtContact);
          if (currentContact) {
            if (debtContact.payments.length > 0) {
              currentContact.amount += helpers.getPaymentsAmount(debtContact.payments);
              currentContact.payments = [
                ...currentContact.payments,
                ...helpers.getDebtPendingPayments(debtContact.payments, debt.uid)
              ];
            }
          } else {
            const payments = helpers.getDebtPendingPayments(debtContact.payments, debt.uid);
            if (payments.length > 0) {
              contacts.push({
                _id: debtContact.contact._id,
                name: debtContact.contact.name,
                amount: helpers.getPaymentsAmount(debtContact.payments),
                payments: payments
              });
            }
          }
        }
      });
    });
  }
  return contacts;
};

/* eslint-disable */
function getContactsDeclinedDebts(debts) {
  const contacts = [];
  if (debts && debts.length) {
    debts.forEach(debt => {
      debt.contacts.forEach((debtContact) => {
        if (debtContact.declined) {
          const currentContact = helpers.getCurrentContact(contacts, debtContact);
          if (currentContact) {
            currentContact.amount += debtContact.contact.amount;
            currentContact.debts.push(debt);
          }
        } else {
          contacts.push({
            _id: debtContact.contact._id,
            name: debtContact.contact.name,
            amount: debtContact.contact.amount,
            debts: debt
          });
        }
      });
    });
  }
  return contacts;
}

const getContactPendingPayments = (contact, pendingContacts) => {
  if (!pendingContacts.length || !contact)
    return [];

  const pendingContact = pendingContacts.filter(pc => pc.id === contact.id);
  return pendingContact[0].payments;
};

const PendingPayments = (props) => {
  const {loading, debts, onBalanceChange = () => null} = props;
  const {t} = useTranslation();

  const [confirmPayment] = useMutation(CONFIRM_DEBT_CONTACT_PAYMENT);
  const [rejectPayment] = useMutation(REJECT_DEBT_CONTACT_PAYMENT);
  const pendingContacts = getContactsPendingPayments(debts);
  const pendingBalance = helpers.getPendingPayments(pendingContacts);
  const [pendingContact, setPendingPaymentsContact] = React.useState();
  const pendingPayments = getContactPendingPayments(pendingContact, pendingContacts);
  const [confirmation, setConfirmation] = React.useState(null);
  const {enqueueSnackbar} = useSnackbar();

  const notifyPendingDebtsBalance = useCallback(() => {
    onBalanceChange(pendingBalance);
  }, [onBalanceChange, pendingBalance]);

  useEffect(() => {
    notifyPendingDebtsBalance();
  }, [notifyPendingDebtsBalance]);

  const handleConfirmPayment = (id) => {
    setConfirmation({
      title: t('debts.confirmation.paymentConfirm.title'),
      message: t('debts.confirmation.paymentConfirm.message'),
      type: 0,
      data: id
    });
  };

  const handleRejectPayment = (id) => {
    setConfirmation({
      title: t('debts.confirmation.paymentReject.title'),
      message: t('debts.confirmation.paymentReject.message'),
      type: 1,
      data: id
    });
  };

  const handleOk = async (type, data) => {
    setConfirmation(null);
    switch (type) {
      case 0:
        setPendingPaymentsContact(null);
        await confirmPayment({
          variables: {debtContactPayment: data},
          refetchQueries: [{query: GET_DEBTS, variables: {active: true}}],
        });
        enqueueSnackbar(t('debts.feedback.paymentConfirm'));
        break;
      case 1:
        setPendingPaymentsContact(null);
        await rejectPayment({
          variables: {debtContactPayment: data},
          refetchQueries: [{query: GET_DEBTS, variables: {active: true}}],
        });
        enqueueSnackbar(t('debts.feedback.paymentReject'));
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    setConfirmation(null);
  };

  if (!pendingContacts.length) {
    return null;
  }

  return (
    <>
      <Typography variant='subtitle2'>{t('home.notifiedPayments')}</Typography>
      <Box mt={1}/>
      {!!pendingContacts.length && <Divider light/>}

      <Grid item container direction='column'>
        {loading && <><ItemSkeleton/> <ItemSkeleton/> <ItemSkeleton/></>}
        {pendingContacts.map(contact =>
          <Item key={contact._id}
                title={contact.name}
                description={contact.payments.length > 1 ?
                  contact.payments.length + ` ${t('common.payment')}s` :
                  contact.payments.length + ` ${t('common.payment')}`
                }
                textIcon={contact.name.charAt(0)}
                value={contact.amount}
                onClick={() => setPendingPaymentsContact(contact)}
          />
        )}
      </Grid>

      <ConfirmationDialog
        show={!!pendingContact}
        title={`${t('home.notifiedPayments')} ${t('common.for')} ${pendingContact ? pendingContact.name : ''}`}
        onClose={() => setPendingPaymentsContact(null)}>
        {pendingPayments.map((payment, i) =>
          <Fragment key={payment.uid}>
            <Item title={<>Debt #{payment.debt}</>}
                  description={<>{payment.notes} @ <Moment format='LL'>{payment.date}</Moment></>}
                  icon="hourglass_empty"
                  value={`$${payment.amount}`}
                  actions={
                    [{
                      title: t('home.confirmPayment'),
                      icon: 'check',
                      onClick: () => handleConfirmPayment(payment.uid)
                    }, {
                      title: t('home.rejectPayment'),
                      icon: 'close',
                      onClick: () => handleRejectPayment(payment.uid)
                    }]
                  }
            />
            {!(i === (pendingPayments.length - 1)) && <Divider light/>}
          </Fragment>
        )}
      </ConfirmationDialog>
      <Alert show={!!confirmation}
             title={confirmation ? confirmation.title : ''}
             okAction={() => handleOk(confirmation.type, confirmation.data)}
             cancelAction={handleCancel}>
        {confirmation ? confirmation.message : null}
      </Alert>
    </>
  );
};

export default PendingPayments;
