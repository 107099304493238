import {makeStyles} from '@material-ui/styles';

const drawerWidth = 256;
const useAppStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',

  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      transition: 'width 0.3s ease',
    },
  },
  drawerClose: {
    width: 0
  },
  appContent: {
    flex: "1 1 100%",
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
}));

export {useAppStyles, drawerWidth};
