import React from 'react';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";

type Props = {
  children: [],
  icon?: string,
  align?: "flex-start" | "center" | "flex-end",
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  error?: string
}

const FormField = (props: Props) => {
  const {children, size = 8, icon, align = "center", error} = props;

  return (
    <Grid xs={size} item container spacing={1} alignItems={align}>
      {icon && <Grid item>
        <Icon style={{color: grey[600], marginTop: 10, marginRight: 10}}>{icon}</Icon>
      </Grid>}
      <Grid item xs>
        {children}
      </Grid>
      {error && <Grid item>
        <Tooltip title={error} aria-label={error}>
          <Icon style={{color: red[600], marginTop: 10, marginLeft: 10}}>warning</Icon>
        </Tooltip>
      </Grid>}
      {!error && <Grid item>
        <Tooltip title='Field is good'>
          <Icon style={{color: green[600], marginTop: 10, marginLeft: 10}}>check</Icon>
        </Tooltip>
      </Grid>}
    </Grid>
  );
};

export default FormField;
