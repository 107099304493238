import React, {useContext, useState} from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useLoginStyles} from "./SignUpStyles";
import {AppContext} from '../../app/AppContext';
import logoImg from '../../common/images/debtrail.png';
import Welcome from '../../common/components/Welcome'
import helpers from '../../common/helpers'
import Grid from "@material-ui/core/Grid";
import useRouter from "../../common/hooks/useRouter";

function SignUp(props) {
  const classes = useLoginStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const {state, dispatch, actions} = useContext(AppContext);
  const {history} = useRouter();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const user = await dispatch(actions.signUp(name, email, password));
    if (user) {
      history.push('/');
    }
  };

  const getErrors = () => {
    const validateEmail = RegExp(/[^@]+@[^]+\..+/g);
    const validPassword = password.length > 5;
    const noError = !state.error;

    return {
      email: !email && !validateEmail.test(email),
      password: !validPassword,
      confirmation: confirmation !== password,
      error: !noError,
    };
  };

  let {from} = props.location.state || {from: {pathname: "/"}};
  if (state.user) return <Redirect to={from}/>;

  return (
    <Welcome>
      <img alt='' className={classes.avatar} src={logoImg}/>
      <Typography component="h1" variant="h5" color={"textSecondary"}>Sign Up</Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="username">Name</InputLabel>
          <Input disabled={state.loading}
                 id="name"
                 name="name"
                 autoComplete="current-username"
                 autoFocus value={name}
                 onChange={(event) => setName(event.target.value)}/>
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="username">Email</InputLabel>
          <Input disabled={state.loading}
                 id="email"
                 name="email"
                 autoComplete="current-username"
                 autoFocus value={email}
                 onChange={(event) => setEmail(event.target.value)}/>
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input disabled={state.loading}
                 name="password"
                 type="password"
                 id="password"
                 autoComplete="current-password" value={password}
                 onChange={(event) => setPassword(event.target.value)}/>
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input disabled={state.loading}
                 name="confirmation"
                 type="password"
                 id="confirmation"
                 autoComplete="current-password" value={confirmation}
                 onChange={(event) => setConfirmation(event.target.value)}/>
        </FormControl>
        <div className={classes.wrapper}>
          {state.error !== '' &&
          <Typography component="h5" className={classes.errorMessage}>{state.error}</Typography>}

          <Button type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={!helpers.canSubmit(getErrors())}
          >
            Sign up
          </Button>

          {state.loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </div>
        <Grid container justify={"space-between"} style={{marginTop: 40}} alignItems={"center"}>
          <Typography variant="body1" color={"textSecondary"}>Do you have an account?</Typography>
          <Button color="primary" className={classes.button}>
            <NavLink className={classes.link} to="/login">Sign In</NavLink>
          </Button>
        </Grid>
      </form>
    </Welcome>
  );
}

export {SignUp};
