import React from 'react';
import {useTranslation} from 'react-i18next';
import Moment from 'react-moment';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {Box} from '@material-ui/core';

import Item from '../../../../common/components/Item';
import ItemSkeleton from '../../../../common/components/ItemSkeleton';
import EventsImg from "../../../../common/images/events_empty.png";
import Empty from "../../../../common/components/Empty";

const LatestActivity = (props) => {
  const {loading, events} = props;
  const {t} = useTranslation();


  return (
    <Grid item>
      <Paper elevation={0} style={{padding: 20}}>
        <Typography variant='h6' color='textSecondary'>{t('home.latestActivity')}</Typography>
        <Box m={1}/>
        <Grid item container direction='column'>
          {loading && <><ItemSkeleton/> <ItemSkeleton/> <ItemSkeleton/></>}
          {events && events.map(event =>
            <Item key={event._id}
                  title={`${t('common.debt')} #${event.uid}`}
                  description={
                    <>
                      {t(`activity.${event.action}`)} @ <Moment format='LL'>{event.date}</Moment>
                    </>
                  }
                  icon='update'
                  value={event.total}
            />
          )}
        </Grid>
        {!loading && !(events && events.length) &&
        <Empty image={EventsImg} section='activity' showTitle={false}/>
        }
      </Paper>
    </Grid>
  );
};

export default LatestActivity;
