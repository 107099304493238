import React, {Fragment, useState} from 'react';
import firebase from "../../../firebase"
import {useMutation, useQuery} from '@apollo/react-hooks';
import {Button, Paper} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSnackbar} from 'notistack';

import helpers from '../../../common/helpers';
import {Content} from '../../../common/components/Content';
import {
  CREATE_DEBT,
  Debt,
  DebtInput,
  GET_DEBT,
  GET_DEBTS,
  UPDATE_DEBT
} from '../../../common/models/Debt';
import {DebtContact} from '../../../common/models/DebtContact';
import ContactSection from '../../../common/components/ContactSelection';
import AmountInput from '../../../common/components/AmountInput';
import AmountPerType from '../../../common/components/AmountPerType';
import NotesInput from '../../../common/components/NotesInput';
import RemainderSelector from '../../../common/components/RemainderSelector';
import RemainderBySelector from '../../../common/components/RemainderBySelector';
import DateInput from '../../../common/components/DateInput';
import Alert from '../../../common/components/Alert';
import {useDebtEditorStyles} from './DebtEditorStyles';
import {useTranslation} from "react-i18next";
import PrivateSelector from "../../../common/components/PrivateSelector";


const DebtEditor = (props) => {
  const {match, history} = props;
  const classes = useDebtEditorStyles();
  const [amount, setAmount] = useState(0);
  const [debt, setDebt] = useState(new Debt());
  const {t} = useTranslation();

  const {loading, error: errorFetch} = useQuery(GET_DEBT, {
    variables: {id: match.params.id},
    onCompleted: (data) => {
      setDebt(new Debt(data.debt));
      if (data.debt.editor.total) {
        setAmount(data.debt.total);
      } else {
        setAmount(data.debt.total / data.debt.contacts.length);
      }
    },
    skip: match.params.id === 'new'
  });

  const [createDebt, {loading: loadingCreate, error: errorCreate}]
    = useMutation(CREATE_DEBT);
  const [updateDebt, {loading: loadingUpdate, error: errorUpdate}]
    = useMutation(UPDATE_DEBT, {skip: match.params.id === 'new'});
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleSave = async (debt) => {
    if (debt._id) {
      await handleUpdateDebt(debt);
    } else {
      await handleAddDebt(debt);
    }
  };

  const handleAddDebt = async (debt) => {
    const _debt = new DebtInput(debt);
    await createDebt({
      variables: {debt: _debt},
      refetchQueries: [{query: GET_DEBTS, variables: {active: true}}],
      awaitRefetchQueries: true
    });
    firebase.analytics().logEvent('debt_create', {});
    enqueueSnackbar('Debt successfully created!');
    history.push('/');
  };

  const handleUpdateDebt = async (debt) => {
    const _debt = new DebtInput(debt);
    await updateDebt({
      variables: {id: debt._id, debt: _debt},
      refetchQueries: [{query: GET_DEBTS, variables: {active: true}}],
      awaitRefetchQueries: true
    });
    firebase.analytics().logEvent('debt_update', {});
    enqueueSnackbar('Debt successfully updated!');
  };

  const handleContactSelection = (selection) => {
    const debtContacts = [];
    selection.forEach(selectedContact => {
      debtContacts.push(new DebtContact(selectedContact));
    });
    const contacts = helpers.getUpdatedContacts(debt.editor.total, amount, debtContacts);
    setDebt({...debt, contacts});
  };

  const handleAmountChange = (value) => {
    const contacts = helpers.getUpdatedContacts(debt.editor.total, value, debt.contacts);
    setAmount(value);
    setDebt({...debt, contacts});
  };

  const handleAmountPerContactChange = (e) => {
    const editor = debt.editor;
    editor.total = e.target.checked;
    const contacts = helpers.getUpdatedContacts(editor.total, amount, debt.contacts);
    setDebt({...debt, editor, contacts});
  };

  const handleNotesChange = (e) => {
    setDebt({...debt, notes: e.target.value});
  };

  const handleRemainderChange = (e) => {
    const {reminders} = debt;
    reminders.interval = e.target.value;
    setDebt({...debt, reminders});
  };

  const handleReminderStartChange = (e) => {
    const {reminders} = debt;
    reminders.start = e.toDate();
    setDebt({...debt, reminders});
  };

  const handleReminderEndChange = (e) => {
    const {reminders} = debt;
    reminders.end = e.toDate();
    setDebt({...debt, reminders});
  };

  const handleSendByEmailChange = (e) => {
    const {notifications} = debt;
    notifications.email = e.target.checked;
    setDebt({...debt, notifications});
  };

  const handleSendBySMSChange = (e) => {
    const {notifications} = debt;
    notifications.text = e.target.checked;
    setDebt({...debt, notifications});
  };

  const handleDateChange = (e) => {
    setDebt({...debt, date: e.toDate()});
  };

  const handlePrivateChange = (e) => {
    setDebt({...debt, private: e.target.checked});
  };

  const canSubmit = () => {
    return debt.contacts.length > 0 && parseFloat(amount) > 0 && !isLoading();
  };

  const isLoading = () => {
    return loading || loadingCreate || loadingUpdate;
  };

  if (errorFetch || errorCreate || errorUpdate) {
    setShowErrorDialog(true);
  }

  return (
    <Fragment>
      <Content>
        <Grid container
              direction='column'
              spacing={4}
              style={{maxWidth: 640, margin: 'auto'}}>
          <Grid item style={{width: '100%'}}>
            <ContactSection debtContacts={debt.contacts}
                            editSelection={!debt._id}
                            onChange={handleContactSelection}/>
          </Grid>
          <Grid item style={{width: '100%'}}>
            <Paper elevation={0}
                   className={classes.paper}>
              <Box p='20px'>
                <Grid container
                      direction='column'
                      alignItems='center'
                      spacing={2}>

                  <AmountInput value={amount} onChange={handleAmountChange}/>

                  <AmountPerType checked={debt.editor.total}
                                 onChange={handleAmountPerContactChange}/>

                  <NotesInput value={debt.notes || ''}
                              onChange={handleNotesChange}/>


                  <RemainderBySelector label={t('common.emailNotifications')}
                                       icon='alternate_email'
                                       value={debt.notifications.email}
                                       onChange={handleSendByEmailChange}/>

                  <RemainderBySelector label={t('common.smsNotifications')}
                                       icon='sms'
                                       value={debt.notifications.text}
                                       onChange={handleSendBySMSChange}/>

                  <RemainderSelector value={debt.reminders.interval}
                                     onChange={handleRemainderChange}/>

                  {debt.reminders.interval !== 'disabled' &&
                  <DateInput label={t('common.remindersStart')}
                             icon="play_circle_outline"
                             format="LL"
                             minDate={new Date()}
                             value={debt.reminders.start || new Date()}
                             onChange={handleReminderStartChange}/>}

                  {debt.reminders.interval !== 'disabled' &&
                  <DateInput label={t('common.remindersEnd')}
                             icon="remove_circle_outline"
                             format="LL"
                             minDate={new Date()}
                             value={debt.reminders.end || helpers.getNextYearDate()}
                             onChange={handleReminderEndChange}/>}

                  <DateInput label="Debt date"
                             icon="calendar_today"
                             format="LL"
                             maxDate={new Date()}
                             value={debt.date}
                             onChange={handleDateChange}/>

                  <PrivateSelector label={t('common.private')}
                                   icon={'visibility'}
                                   help={t('debts.editor.private')}
                                   value={debt.private}
                                   onChange={handlePrivateChange}/>


                  <Grid item>
                    <Box p={3}>
                      <Button disabled={!canSubmit()}
                              variant='contained'
                              color='primary'
                              onClick={() => handleSave(debt)}>
                        {isLoading() ? `${t('common.saving')}...` : t('common.save')}
                      </Button>
                    </Box>
                  </Grid>

                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid item>
          <Box p={2}/>
        </Grid>

      </Content>

      {loading &&
      <Box position='absolute'
           width='100%'
           height='100%'
           bgcolor='rgba(255,255,255, 0.8)'
           top={0} left={0} right={0}
           bottom={0}
           display='flex'
           alignItems='center'
           justifyContent='center'>
        <CircularProgress/>
      </Box>
      }
      <Alert show={showErrorDialog}
             okAction={() => setShowErrorDialog(false)}
             title={t('common.error.title')}>
        <Typography>{t('common.error.description')}</Typography>
      </Alert>
    </Fragment>
  );
};

export default DebtEditor;
