import {makeStyles} from '@material-ui/styles';
import {grey} from "@material-ui/core/colors";

const useDebtEditorStyles = makeStyles(theme => ({
  root: {
    maxWidth: '936px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  bottomBox: {
    borderTop: `1px solid ${grey[300]}`
  }
}));

export {useDebtEditorStyles};
