export const ROOT_PATH = '/';
export const LOGIN_PATH = '/user/login';
export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
export const APOLLO_URI = process.env.REACT_APP_APOLLO_URI;
export const ANDROID_URL = process.env.REACT_APP_ANDROID_URL;
export const IOS_URL = process.env.REACT_APP_IOS_URL;
export const SITE_URL = process.env.REACT_APP_SITE_URL;

