import {makeStyles} from '@material-ui/styles';
import {grey} from "@material-ui/core/colors";

const useContactsStyles = makeStyles(theme => ({
  root: {
    maxWidth: '936px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  link: {
    textDecoration: "none"
  },
  sidebarAvatar: {
    width: 46,
    height: 46,
    color: grey[700],
    background: grey[300],
    marginRight: 10,
    fontSize: '14px',
    fontWeight: 500
  },
}));

export {useContactsStyles};
