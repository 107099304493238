import React from 'react';
import Moment from "react-moment";
import {useTranslation} from "react-i18next";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from "@material-ui/core/List";

import Amount from "../../../common/components/Amount";
import helpers from "../../../common/helpers";
import Item from "../../../common/components/Item";
import Transition from "../../../common/components/Transition";

const PaymentsDialog = (props) => {
  const {show, user, payments = [], okAction} = props;
  const {t, i18n} = useTranslation();

  return (
    <Dialog
      TransitionComponent={Transition}
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{t('contact_payments_title')}</DialogTitle>
      <DialogContent>
        <List>
          {payments.map((payment, i) => (
            <Item key={i}
                  title={<Amount variant="h6" symbol={user.currency}>{payment.amount}</Amount>}
                  description={
                    <>
                      {payment.notes} @ <Moment format='LL'
                                                locale={i18n.language}>{payment.date}</Moment>
                    </>
                  }
                  icon={helpers.getPaymentStatusIcon(payment.status)}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={okAction}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentsDialog;
