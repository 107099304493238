const canSubmit = (formErrors) => {
  const out = Object.keys(formErrors).some(err => formErrors[err]);
  return !out;
};

const getActivityIndicator = (action) => {
  switch (action) {
    case 'created':
      return 'add';
    case 'updated':
      return 'edit';
    case 'contact_informed':
      return 'send';
    case 'contact_paid':
      return 'done';
    case 'contact_rejected':
      return 'error';
    case 'contact_confirmed':
      return 'done_all';
    case 'closed':
      return 'close';
    case 'removed':
      return 'delete_forever';
    case 'archived':
      return 'archive';
    default:
      return 'fiber_manual_record';
  }

};

const getStatusIcon = (status) => {
  switch (status) {
    case 'pending':
      return 'hourglass_empty';
    case 'completed':
      return 'done_all';
    default:
      return 'hourglass_empty';
  }
};

const getPaymentStatusIcon = (status) => {
  switch (status) {
    case 'pending':
      return 'hourglass_empty';
    case 'rejected':
      return 'close';
    case 'completed':
      return 'done_all';
    default:
      return 'hourglass_empty';
  }
};

const getDebtId = (id) => {
  return id.substring(id.length - 11, id.length);
};

const getNextYearDate = () => {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
};

const getUpdatedContacts = (isTotal, amount, contacts) => {
  let amountPerContact = 0;

  if (isTotal) {
    amountPerContact = parseFloat(amount) / contacts.length;
  } else {
    amountPerContact = parseFloat(amount);
  }

  contacts.forEach(contact => {
    contact.amount = amountPerContact;
  });

  return contacts;

};

const getPendingPayments = (pendingContacts) => {
  let pendingPayments = 0;
  pendingContacts.forEach(pc => {
    pendingPayments += pc.amount;
  });
  return pendingPayments;
};

const getDebtPendingPayments = (payments, debt) => {
  return payments.filter(payment => payment.status === 'pending')
    .map(p => {
      p.debt = debt;
      return p;
    });
};

const getCurrentContact = (contacts, debtContact) => {
  return contacts.find((contact) => contact._id === debtContact.contact._id);
};

const getPaymentsAmount = (payments) => {
  return payments.reduce((amount, payment) =>
    amount + (payment.status === 'pending' ? payment.amount : 0), 0);
};

export default {
  getDebtId,
  getActivityIndicator,
  canSubmit,
  getStatusIcon,
  getPaymentStatusIcon,
  getUpdatedContacts,
  getNextYearDate,
  getPendingPayments,
  getDebtPendingPayments,
  getCurrentContact,
  getPaymentsAmount
};


