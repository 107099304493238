import React from "react";
import Grid from "@material-ui/core/Grid";
import Debtrail from '../../../common/images/debtrail.png';
import Typography from "@material-ui/core/Typography";
import {useDebtStyles} from "../DebtStyles";

function DebtHeader() {
  const classes = useDebtStyles();
  return (
    <a target="_blank" rel="noopener noreferrer"
       href={process.env.REACT_APP_SITE_URL} style={{textDecoration: 'none'}}>
      <Grid className={classes.logoContainer} container justify={"center"}>
        <img alt='' width={36} height={36} src={Debtrail}/>
        <Typography
          component="h1"
          variant="h4"
          style={{color: 'grey', fontWeight: 500}}>Debtrail</Typography>
      </Grid>
    </a>
  );
}

export default DebtHeader;
