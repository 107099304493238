import React, {useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {LOGIN_PATH} from '../../config';
import {Routes} from '../../views/user/routes';
import Login from '../../views/login';
import SignUp from '../../views/signUp';
import {ForgotPassword} from '../../views/forgotPassword/ForgotPassword';
import AppContent from '../../app/AppContent';
import {AppContext} from '../../app/AppContext';
import AppSmallDeviceWarning from "../../app/AppSmallDeviceWarning";
import {useMediaQuery} from "@material-ui/core";

export const SecuredRoute = (props) => {
  const {component: Component, secured, unsecuredPath, ...rest} = props;
  return (<Route {...rest} render={
    _props => (
      secured ?
        (<AppContent><Component {..._props}/></AppContent>) :
        (<Redirect to={{pathname: unsecuredPath, state: {from: _props.location}}}/>)
    )
  }/>);
};

const UserRoutes = ({match}) => {
  const matches = useMediaQuery('(max-width:960px)');
  const {state} = useContext(AppContext);
  const {user} = state;
  return (
    <>
      {matches &&
      <AppSmallDeviceWarning/>
      }
      {!matches && <Switch>
        <Route path={LOGIN_PATH} component={Login}/>
        <Route path={`${match.path}/signup`} component={SignUp}/>
        <Route path={`${match.path}/forgot-password`} component={ForgotPassword}/>

        {Routes.map((route, rIndex) => (
          <SecuredRoute secured={user}
                        unsecuredPath={LOGIN_PATH}
                        exact={route.exact}
                        path={match.path + route.path}
                        component={route.component}
                        key={rIndex}/>
        ))}
        {/*<Route path='*' component={AppRedirect}/>*/}
      </Switch>}
    </>
  );
};

export default UserRoutes;
