import React from 'react';
import {useTranslation} from "react-i18next";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

type Props = {
  showTitle: boolean,
  section: string,
  image: any
}

const Empty = (props: Props) => {
  const {section, image, showTitle = true} = props;
  const {t} = useTranslation();

  return (
    <Grid item container direction='column' spacing={2} alignItems='center'>
      <Grid item container justify='center'>
        <img alt='' src={image} style={{width: '80%', height: '100%'}}/>
      </Grid>
      {showTitle && <Grid item>
        <Typography variant='h5'>{t(`${section}.empty.title`)}</Typography>
      </Grid>}
      <Grid item>
        <Typography variant='subtitle2' color='textSecondary' align='center'
                    style={{width: '70%', margin: '0 auto 30px auto'}}>
          {t(`${section}.empty.message`)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Empty;
