import Dashboard from '../../views/user/dashboard/Dashboard';
import Debts from '../../views/user/debts/Debts';
import DebtEditor from "../../views/user/debtEditor/DebtEditor";
import Schedules from '../../views/user/schedules/Schedules';
import ScheduleEditor from "../../views/user/scheduleEditor/ScheduleEditor";
import Activity from '../../views/user/activity/Activity';
import Contacts from '../../views/user/contacts/Contacts';
import Config from "../../views/user/config/Config";

const Groups = [
  {name: 'Services'},
  {name: 'Analisis'},
  {name: 'Settings'}
];

const Routes = [
  {
    path: ``,
    name: 'Dashboard',
    icon: 'home',
    exact: true,
    component: Dashboard,
    group: 'Home',
    showOnSidebar: true
  },
  {
    path: `/debts`,
    name: 'Debts',
    icon: 'check',
    exact: true,
    component: Debts,
    group: 'Services',
    showOnSidebar: true
  },
  {
    path: `/debts/:id`,
    name: 'Debt Editor',
    exact: true,
    component: DebtEditor,
    showOnSidebar: false
  },
  {
    path: `/schedules`,
    name: 'Schedules',
    icon: 'refresh',
    exact: true,
    component: Schedules,
    group: 'Services',
    showOnSidebar: true
  },
  {
    path: `/schedules/:id`,
    name: 'Schedule Editor',
    exact: true,
    component: ScheduleEditor,
    showOnSidebar: false
  },
  {
    path: `/activity`,
    name: 'Activity',
    icon: 'timer',
    exact: true,
    component: Activity,
    group: 'Analisis',
    showOnSidebar: true
  },
  {
    path: `/contacts`,
    name: 'Contacts',
    icon: 'people',
    exact: true,
    component: Contacts,
    group: 'Settings',
    showOnSidebar: true
  },
  {
    path: `/config`,
    exact: true,
    component: Config,
    group: 'Settings',
    showOnSidebar: false
  },
];

export {Groups, Routes};
