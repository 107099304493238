import React from 'react';
import {useTranslation} from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {Box} from '@material-ui/core';

import Amount from '../../../../common/components/Amount';


const PendingBalance = (props) => {
  const {balance} = props;
  const {t} = useTranslation();

  return (
    <Grid item>
      <Paper elevation={0} style={{padding: '20px'}}>
        <Typography variant='h6' color='primary'>Balance</Typography>
        <Box mt={1}/>
        <Amount variant='h3' style={{fontWeight: 300}}>{balance}</Amount>
        <Typography variant='subtitle2' color='textSecondary' style={{marginTop: 5}}>
          {t('home.balanceDescription')}
        </Typography>
      </Paper>
    </Grid>
  );
};

export default PendingBalance;
