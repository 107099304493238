import React, {Fragment, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {useMutation} from '@apollo/react-hooks';
import Moment from 'react-moment';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Box, Divider} from '@material-ui/core';
import {COMPLETE_DEBT_CONTACT, GET_DEBTS} from '../../../../common/models/Debt';
import Item from '../../../../common/components/Item';
import Amount from '../../../../common/components/Amount';
import ItemSkeleton from '../../../../common/components/ItemSkeleton';
import ConfirmationDialog from './ConfirmationDialog';
import Alert from '../../../../common/components/Alert';
import helpers from '../../../../common/helpers';
import WidgetHeader from "../../../../common/components/WidgetHeader";
import Empty from "../../../../common/components/Empty";
import DebtsImg from "../../../../common/images/debts_empty.png";

const getContactsPendingDebts = (debts) => {
  const contacts = [];
  if (debts && debts.length) {
    debts.forEach(debt => {
      debt.contacts.forEach((debtContact) => {
        if (debtContact.status === 'pending') {
          const currentContact = helpers.getCurrentContact(contacts, debtContact);
          if (currentContact) {
            currentContact.amount += debtContact.amount;
            currentContact.debts++;
          } else {
            contacts.push({
              _id: debtContact.contact._id || '',
              name: debtContact.contact.name || '',
              picture: debtContact.contact.picture,
              amount: debtContact.amount,
              debts: 1,
              payments: []
            });
          }
        }
      });
    });
  }
  return contacts;
};

const getContactAmount = (debt, contact) => {
  const debtContact = debt.contacts.filter(dc => dc.contact._id === contact._id);
  return debtContact[0].amount;
};

const getContactDebts = (contact, debts) => {
  if (!debts || !debts.length || !contact)
    return [];

  return debts.filter(debt => {
    for (let debtContact of debt.contacts) {
      if (debtContact.contact._id === contact._id && debtContact.status === 'pending') {
        return true;
      }
    }

    return false;

  });
};

const isDebtDeclined = (debt, contact) => {
  const _contact = debt.contacts.find(debtContact => debtContact.contact._id === contact._id);
  if (!_contact) {
    return false;
  }
  return _contact.declined;
};

const PendingDebts = (props) => {
  const {loading, debts, onBalanceChange} = props;
  const {t} = useTranslation();

  const [confirmContactDebt] = useMutation(COMPLETE_DEBT_CONTACT);
  const pendingDebtsContacts = getContactsPendingDebts(debts);
  const pendingDebtsBalance = helpers.getPendingPayments(pendingDebtsContacts);
  const [pendingDebtsContact, setPendingDebtsContact] = React.useState();
  const pendingDebts = getContactDebts(pendingDebtsContact, debts);
  const [confirmation, setConfirmation] = React.useState(null);
  const {enqueueSnackbar} = useSnackbar();
  const notifyPendingDebtsBalance = useCallback(() => {
    onBalanceChange(pendingDebtsBalance);
  }, [onBalanceChange, pendingDebtsBalance]);

  useEffect(() => {
    notifyPendingDebtsBalance();
  }, [notifyPendingDebtsBalance]);

  const handleCloseDebt = (debt) => {
    const _contact = debt.contacts.find(dc => dc.contact._id === pendingDebtsContact._id);
    setConfirmation(_contact.uid);
  };

  const handleOk = async (contact) => {
    setConfirmation(null);
    setPendingDebtsContact(null);
    await confirmContactDebt({
      variables: {debtContact: contact},
      refetchQueries: [{query: GET_DEBTS, variables: {active: true}}],
    });
    enqueueSnackbar('Debt closed!');
  };

  const handleCancel = () => {
    setConfirmation(null);
  };

  // if (!loading && !pendingDebtsBalance) {
  //   return null;
  // }

  return (
    <Grid item>
      <Paper elevation={0} style={{padding: 20}}>
        <WidgetHeader title={t('home.pendingDebts')} description={t('home.info.pendingDebts')}/>
        <Box mt={1}/>
        {!!pendingDebtsBalance && <>
          <Amount variant='h4' style={{fontWeight: 300}}>{pendingDebtsBalance}</Amount>
          <Box mt={2}/>
        </>}

        {pendingDebtsContacts.length > 0 && <Divider light/>}

        <Grid item container direction='column'>
          {loading && <><ItemSkeleton/><ItemSkeleton/><ItemSkeleton/></>}
          {pendingDebtsContacts.map(contact =>
            <Item key={contact._id}
                  title={contact.name}
                  description={contact.debts > 1 ?
                    contact.debts + ` ${t('common.debt')}s` :
                    contact.debts + ` ${t('common.debt')}`}
                  icon={contact.picture}
                  textIcon={contact.name.charAt(0)}
                  value={contact.amount}
                  onClick={() => setPendingDebtsContact(contact)}
            />
          )}
        </Grid>

        {!loading && !pendingDebtsBalance &&
        <Empty image={DebtsImg} section='debts' showTitle={false}/>
        }

      </Paper>

      <ConfirmationDialog
        show={!!pendingDebtsContact}
        title={`${t('home.pendingDebts')} ${pendingDebtsContact ? pendingDebtsContact.name : ''}`}
        onClose={() => setPendingDebtsContact(null)}>
        {pendingDebts.map((debt, i) => {
            const isDeclined = isDebtDeclined(debt, pendingDebtsContact);
            return (
              <Fragment key={debt._id}>
                <Item title={`${t('common.debt')} #${debt.uid}`}
                      description={<>{debt.notes} @ <Moment format='LL'>{debt.date}</Moment></>}
                      icon={isDeclined ? 'close' : helpers.getStatusIcon(debt.status, debt)}
                      value={`$${getContactAmount(debt, pendingDebtsContact)}`}
                      actions={
                        [{
                          title: t('home.closeDebt'),
                          icon: 'check',
                          onClick: () => handleCloseDebt(debt)
                        }, isDeclined && {
                          title: t('debts.restore'),
                          icon: 'restore',
                          onClick: () => handleCloseDebt(debt)
                        }]
                      }
                />
                {!(i === (pendingDebts.length - 1)) && <Divider light/>}
              </Fragment>
            )
          }
        )}
      </ConfirmationDialog>

      <Alert show={!!confirmation}
             title='Close Debt'
             okAction={() => handleOk(confirmation)}
             cancelAction={handleCancel}>
        Are you sure you want to close this debt?
      </Alert>
    </Grid>
  );
};

export default PendingDebts;
