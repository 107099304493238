import React from 'react';
import {useActivityStyles} from '../ActivityStyles';
import Moment from 'react-moment';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {GET_EVENTS} from "../../../../common/models/Event";
import {useQuery} from "@apollo/react-hooks";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Amount from "../../../../common/components/Amount";
import {useTranslation} from "react-i18next";
import numeral from "numeral";


const ActivityDetails = (props) => {
  const classes = useActivityStyles();
  const {t} = useTranslation();
  const {debtId: debt, debtUid} = props;
  const {loading, data} = useQuery(GET_EVENTS, {variables: {filter: {debt}, full: true}});
  const events = data && data.events && data.events.items;

  const getActivity = (event) => {
    const contact = event && event.contact && event.contact.contact.name;
    let amount;
    if (event.action === 'contact_completed') {
      amount = event && event.total;
    } else {
      amount = event && event.payment && event.payment.amount;
    }
    amount = numeral(amount).format('0,0.00');
    const variables = {amount, contact};
    return t(`activity.${event.action}`, variables);
  };

  return (
    <Box position='fixed' minWidth='300px' height='calc(100% - 110px)'>
      <Grid container
            wrap='nowrap'
            direction='column'
            spacing={2}
            style={{height: '100%'}}>
        <Grid item container alignItems='center'>
          <Grid item xs>
            <Typography variant='h6'>{t('activity.details')}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography>
            {t('common.debt')} #{debtUid}
          </Typography>
        </Grid>
        {loading && <CircularProgress/>}
        {!loading && events && <>
          <Grid item>
            <Box m={2}/>
            <Amount variant='h4'>{events[0].total}</Amount>
          </Grid>
          <Grid item>
            <Box m={2}/>
            <Divider light/>
          </Grid>
          <Grid item style={{height: '100%', overflow: 'auto'}}>
            <Box m={1}/>
            <Typography variant='subtitle1'>
              Events
            </Typography>
            <Box m={1}/>
            {!loading && <List>
              {events.map(event => (
                <ListItem key={event._id}>
                  <ListItemAvatar>
                    <Avatar className={classes.sidebarAvatar}>
                      <Icon>update</Icon>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={getActivity(event)}
                                secondary={<Moment format='LL'>{event.date}</Moment>}/>
                </ListItem>
              ))}
            </List>}
          </Grid>
        </>}
      </Grid>
    </Box>
  );
};

export default ActivityDetails;
