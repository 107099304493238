import {gql} from 'apollo-boost';
import DebtReminders from './DebtReminders';

//region Queries
export const GET_DEBTS = gql`
 query GetDebts($active: Boolean, $filter: DebtsFilter, $limit: Int, $offset:Int, $sort: String ){
  debts(active: $active, filter: $filter, limit: $limit, offset:$offset sort: $sort) {
    count
    items{
      _id
      uid
      contacts  {
        contact {
          _id
          name
          phone
          email
          picture
        }
        uid
        amount
        status
        declined
        payments {
          uid
          amount
          status
          notes
          date
        }
      }
      notes
      date
      total
      pending
      reminders {
        interval
        start
        end
      }
      notifications {
        email
        text
      }
      attachment
      receipt {
        items {
          label
          amount
          contacts {
            contact {
              _id
              name
              phone
              email
              picture
            }
            portion
          }
        }
        total
      }
      editor {
        total
      }
      status
      locked
      deleted
      updatedAt
      createdAt
    }
  }
}
`;

export const GET_DEBTS_REQUESTS = gql`
{
  debtsRequests{
    count
    items{
      contacts  {
        contact {
          _id
          name
          phone
          email
          picture
        }
        uid
        amount
        status
        payments {
          uid
          amount
          status
          notes
          date
        }
      }
      notes
      date
      user{
        user
        name
        picture
      }
    }
  }
}
`;


export const GET_DEBT = gql`
query GetDebt($id: MongoID!) {
  debt (id: $id) {
    _id
    contacts  {
      contact {
        _id
        name
        phone
        email
        picture
      }
      amount
      status
    }
    notes
    date
    total
    pending
    reminders {
      interval
      start
      end
    }
    notifications {
      email
      text
    }
    attachment
    receipt {
      items {
        label
        amount
        contacts {
          contact {
            _id
            name
            phone
            email
            picture
          }
          portion
        }
      }
      total
    }
    editor {
      total
    }
    private
    status
    locked
    deleted
    updatedAt
    createdAt
  }
}`;

export const CREATE_DEBT = gql`mutation CreateDebt($debt: DebtInput!) {
  createDebt (debt: $debt) {
    _id
  }
}`;

export const UPDATE_DEBT = gql`mutation CreateDebt($id: MongoID!, $debt: DebtInput!) {
  updateDebt (id: $id, debt: $debt) {
    _id
  }
}`;

export const REMOVE_DEBT = gql`mutation RemoveDebt($id: MongoID!) {
  removeDebt (id: $id) {
    _id
  }
}`;

export const COMPLETE_DEBT_CONTACT = gql`mutation completeDebtContact($debtContact: String!) {
  completeDebtContact (debtContact: $debtContact) {
    uid
  }
}`;

export const DECLINE_DEBT_CONTACT = gql`mutation declineDebtContact($debtContact: String!) {
  declineDebtContact (debtContact: $debtContact) {
    uid
  }
}`;

export const RESTORE_DEBT_CONTACT = gql`mutation restoreDebtContact($debtContact: String!) {
  restoreDebtContact (debtContact: $debtContact) {
    uid
  }
}`;

//endregion

class DebtNotifications {
  email;
  text;
}

class DebtEditor {
  total;
}

export class Debt {
  _id;
  contacts = [];
  notes = '';
  date = new Date();
  reminders = new DebtReminders();
  notifications = new DebtNotifications();
  attachment = '';
  receipt;
  editor = new DebtEditor();
  private = false;

  constructor(debt) {
    if (debt) {
      this._id = debt._id;
      this.contacts = debt.contacts;
      this.notes = debt.notes;
      this.date = debt.date;
      this.reminders = debt.reminders;
      this.notifications = debt.notifications;
      this.attachment = debt.attachment;
      this.receipt = debt.receipt;
      this.editor = debt.editor;
      this.private = debt.private;
    }
  }
}

class DebtContactInput {
  contact;
  amount;

  constructor(_contact) {
    this.contact = _contact && _contact.contact && _contact.contact._id;
    this.amount = _contact && _contact.amount;
  }
}

export class DebtInput {
  contacts = [];
  notes = '';
  date = new Date();
  reminders = new DebtReminders();
  notifications = new DebtNotifications();
  attachment = '';
  receipt;
  editor = new DebtEditor();
  private = false;

  constructor(debt) {
    if (debt) {
      this.contacts = debt.contacts && debt.contacts.map(c => new DebtContactInput(c));
      this.notes = debt.notes;
      this.date = debt.date;
      this.reminders = debt.reminders;
      this.notifications = debt.notifications;
      this.attachment = debt.attachment || null;
      this.receipt = debt.receipt;
      this.editor = debt.editor;
      this.private = debt.private;
    }
  }
}
