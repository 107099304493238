import {createMuiTheme} from '@material-ui/core/styles';

let appTheme = createMuiTheme({
  typography: {
    fontFamily: [
      'Avenir',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    useNextVariants: true,
    h1: {
      // color: defaultTitleColor
    },
    h2: {
      // color: defaultTitleColor
    },
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: '#ff6333',
      main: '#ff3d00',
      dark: '#b22a00',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

appTheme = {
  ...appTheme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#fff',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'initial',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: appTheme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        // backgroundColor: appTheme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        [appTheme.breakpoints.up('md')]: {
          minWidth: 0,
        },
      }
    },
    MuiIconButton: {
      root: {
        padding: appTheme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#ddd',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: appTheme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiTablePagination: {
      selectRoot: {
        marginRight: '18px',
      },
      select: {
        paddingRight: '24px',
      }
    },
    MuiTableRow: {
      head: {
        height: '32px',
        backgroundColor: '#f5f5f5'
      }
    },
    MuiTableCell: {
      root: {
        padding: '4px 16px 4px 16px'
      }
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MUIDataTableToolbar: {
      root: {
        display: "none",
        [appTheme.breakpoints.down('xs')]: {
          display: "none !important",
        },
      }
    },
    MUIDataTablePagination: {
      root: {
        backgroundColor: "#f5f5f5"
      }
    },
    MUIDataTableHeadCell: {
      root: {
        backgroundColor: '#f5f5f5',
        fontWeight: 600
      },
      fixedHeader: {
        backgroundColor: '#f5f5f5'
      }
    },
    MuiPaper: {
      root: {
        // backgroundColor:'#333',
        '&.MuiPaper-elevation0': {
          // border: `1px solid ${grey[300]}`
          boxShadow: '0 2px 1px 0 rgba(0,0,0,.1)'
        },
      }
    },
  },

  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...appTheme.mixins,
    toolbar: {
      minHeight: 48,
    }
  },
};

export {appTheme};
