import {makeStyles} from '@material-ui/styles';
import {grey} from '@material-ui/core/colors';

const useDebtsStyles = makeStyles(() => ({
  debt: {
    padding: 20,
    marginBottom: 30,
    border: `1px solid ${grey[300]}`
  },
  indicator: {
    margin: '0 10px 0 10px',
    color: grey[600],
    fontSize: 18
  },
  chip: {
    margin: '0 10px 0 0',
  },
  link: {
    textDecoration: "none"
  },
  activity: {
    padding: '5px 10px',
    cursor: 'pointer',
    '&:hover': {
      background: '#ffccbc30'
    }
  },
  selected: {
    background: '#ffccbc60',
    '&:hover': {
      background: '#ffccbc60'
    }
  },
  avatar: {
    margin: 5,
    width: 48,
    height: 48,
    color: '#FF6131',
    background: '#FFF',
    border: '1px solid #cdcdcd'
  },
  sidebarAvatar: {
    width: 46,
    height: 46,
    color: grey[700],
    background: grey[300],
    marginRight: 10,
    fontSize: '14px',
    fontWeight: 500
  },
  sidebar: {
    position: 'absolute',
    width: '320px',
    right: 0,
    top: '65px',
    bottom: 0,
    background: '#fff',
    padding: '20px 15px',
    boxShadow: 'rgba(0, 0, 0, 0.26) 0px -2px 2px 1px',
  }
}));

export {useDebtsStyles};
