import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from "@material-ui/core/Grow";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

type Props = {
  show: boolean,
  title?: string,
  okActionText?: string,
  okAction?: ()=>any,
  cancelActionText?: string,
  cancelAction?: ()=>any
}

const Alert = (props: Props) => {
  const {show, title, okActionText = 'Yes', okAction, cancelActionText = 'No', cancelAction} = props;

  return (
    <Dialog
      TransitionComponent={Transition}
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {props.children}
      </DialogContent>
      <DialogActions>
        {cancelAction && <Button onClick={cancelAction}>
          {cancelActionText}
        </Button>}
        {okAction && <Button onClick={okAction} color="primary" autoFocus>
          {okActionText}
        </Button>}
      </DialogActions>
    </Dialog>
  );
};

export default Alert;
