import React, {Fragment, useState} from 'react';
import Moment from 'react-moment';
import numeral from "numeral";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/react-hooks";

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";

import {GET_EVENTS} from "../../../common/models/Event";
import {Content} from "../../../common/components/Content";
import Item from "../../../common/components/Item";
import Empty from "../../../common/components/Empty";
import EventsImg from "../../../common/images/events_empty.png";
import ItemSkeleton from "../../../common/components/ItemSkeleton";

import ActivityDetails from "./components/ActivityDetails";

const Activity = () => {
  const [selected, setSelected] = useState();

  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [events, setEvents] = useState([]);
  const {loading, error, data} = useQuery(GET_EVENTS,
    {
      variables: {limit, offset},
      onCompleted: (data) => {
        const _events = data && data.events && data.events.items;
        setEvents([...events, ..._events]);
      },
    });
  const totalEvents = data && data.events && data.events.count ? data.events.count : 0;
  const {t} = useTranslation();

  const handleAddMore = () => {
    setOffset(events.length);
  };

  const getActivity = (event) => {
    const contact = event && event.contact && event.contact.contact.name;
    let amount;
    if (event.action === 'contact_completed') {
      amount = event && event.total;
    } else {
      amount = event && event.payment && event.payment.amount;
    }
    amount = numeral(amount).format('0,0.00');
    const variables = {amount, contact};
    return t(`activity.${event.action}`, variables);
  };

  return (
    <Content>
      <Grid container style={{maxWidth: 1024, margin: 'auto', marginBottom: 20}}>
        <Grid item xs style={{paddingRight: 20}}>
          <Paper elevation={0}>
            <Box p={2}>
              {events.length === 0 && loading && <><ItemSkeleton/> <ItemSkeleton/> <ItemSkeleton/></>}
              {error && <p>Error :</p>}

              {events.length > 0 &&
              <Grid item container direction='column'>
                {events.map((event, i) =>
                  <Fragment key={event._id}>
                    <Item title={`${t('common.debt')} #${event.uid}`}
                          description={<>
                            {getActivity(event)} @ <Moment
                            format='LL'>{event.date}</Moment>
                          </>}
                          icon='update'
                          value={event.total}
                          selected={selected && event.uid === selected.uid}
                          onClick={() => setSelected(event)}
                    />
                    {!(i === (events.length - 1)) && <Divider light/>}
                  </Fragment>
                )}
              </Grid>}

              {events.length > 0 && loading && <><ItemSkeleton/> <ItemSkeleton/> <ItemSkeleton/></>}

              {!loading && events.length === 0 &&
              <Empty image={EventsImg} section='activity'/>
              }
            </Box>
          </Paper>
          {events && events.length > 0 && totalEvents > events.length &&
          <Box mt={4} textAlign='center'>
            <Button variant='outlined'
                    color='primary'
                    disabled={loading}
                    onClick={handleAddMore}>{t('common.loadMore')}...</Button>
          </Box>}
        </Grid>

        <Grid item xs={5} style={{paddingLeft: 20}}>
          {selected &&
          <ActivityDetails debtId={selected.debt} debtUid={selected.uid}/>
          }
        </Grid>
      </Grid>
    </Content>
  );
};

export default Activity;
