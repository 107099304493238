import React, {useRef, useState} from 'react';
import Icon from "@material-ui/core/Icon";
import {Avatar, Box, Button, Fab, makeStyles} from "@material-ui/core";
import {useMutation} from "@apollo/react-hooks";
import {UPLOAD_USER_PICTURE} from "../../common/models/User";
import {useSnackbar} from "notistack";
import Skeleton from '@material-ui/lab/Skeleton';
import {grey} from "@material-ui/core/colors";
import Alert from "../../common/components/Alert";
import {useTranslation} from "react-i18next";
import {UPLOAD_CONTACT_PICTURE} from "../models/Contact";

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginTop: 20,
    width: 96,
    height: 96,
    background: grey[400],
    cursor: 'pointer'
  },
  photoInput: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    outline: 'none',
    width: '100%',
    height: '100%',
    border: 'none',
    cursor: 'pointer',
    opacity: 0

  },
  fab: {
    position: 'absolute',
    bottom: '-14px',
    right: '-12px'
  },
}));

const UploadAvatar = ({type = 'user', picture, onChange}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [uploadUserPicture] = useMutation(UPLOAD_USER_PICTURE);
  const [uploadContactPicture] = useMutation(UPLOAD_CONTACT_PICTURE);
  const uploadPicture = type === 'user' ? uploadUserPicture : uploadContactPicture;
  const {enqueueSnackbar} = useSnackbar();
  const iref = useRef();
  const {t} = useTranslation();

  const handleChange = async e => {
    const [file] = e.target.files;
    const data = new FormData();
    data.append('image', file);
    setLoading(true);
    const {data: response, errors} = await uploadPicture({
      variables: {
        image: file,
      },
      errorPolicy: 'all',
    });
    setLoading(false);
    if (errors) {
      enqueueSnackbar(t('common.userAvatar.error'));
    } else {
      onChange(type === 'user' ? response.uploadPictureUser : response.uploadPictureContact);
    }
  };

  const handleClick = () => {
    if (!picture) {
      iref.current.click();
    } else {
      setShowAlert(true);
    }
  };

  const handleDeletePicture = () => {
    if (picture) {
      onChange('');
      setShowAlert(false);
    }
  };

  const handleUploadNew = () => {
    iref.current.click();
    setShowAlert(false);
  };

  return (
    <Box position='relative'>
      {!loading && <>
        <Avatar className={classes.avatar} src={picture}>
          {!picture && <Icon style={{fontSize: 48}}>person_outline</Icon>}
        </Avatar>
      </>}
      <Fab size="small"
           color="primary"
           aria-label="add"
           className={classes.fab}
           disabled={loading}
           onClick={handleClick}>
        <Icon>camera_alt</Icon>
      </Fab>
      <input
        ref={iref}
        style={{display: 'none'}}
        type='file'
        accept="image/x-png,image/gif,image/jpeg"
        onChange={handleChange}
      />
      {loading && <Skeleton variant="circle" className={classes.avatar} width={96} height={96}/>}
      <Alert show={showAlert}
             title={t('common.userAvatar.title')}
             cancelAction={() => setShowAlert(false)}
             cancelActionText={t('common.cancel')}>
        <Button onClick={handleUploadNew}>{t('common.userAvatar.upload')}</Button>
        <Button onClick={handleDeletePicture}>{t('common.userAvatar.delete')}</Button>
      </Alert>
    </Box>
  );
};

export default UploadAvatar;
