import {gql} from "apollo-boost";

//region Queries
export const GET_DEBT_CONTACT = gql`
 query GetDebtContact($debtContact: String!){
  debtContact(debtContact: $debtContact) {
    notes
    date
    deleted
    status
    user {
      name
      email
      phone
      currency
      language
      picture
    }
    contacts {
      uid
      contact {
        name
        email
        phone
        language
        language
        picture
      }
      amount
      pending
      status
      declined
      viewed
      payments {
        amount
        status
        notes
        date
      }
    }
  }
}
`;

export const UNSUBSCRIBE_DEBT_CONTACT = gql`mutation unsubscribeDebtContact($debtContact: String!) {
  unsubscribeDebtContact(debtContact: $debtContact)
}`;

export const RESUBSCRIBE_DEBT_CONTACT = gql`mutation resubscribeDebtContact($debtContact: String!) {
  resubscribeDebtContact(debtContact: $debtContact)
}`;

export const CREATE_DEBT_CONTACT_PAYMENT = gql`mutation createDebtContactPayment($debtContact: String!, $amount: Float!, $notes: String) {
  createDebtContactPayment(debtContact: $debtContact, amount: $amount, notes: $notes) {
    amount
    uid
    status
  }
}`;

export const REJECT_DEBT_CONTACT_PAYMENT = gql`mutation rejectDebtContactPayment($debtContactPayment: String!) {
  rejectDebtContactPayment(debtContactPayment: $debtContactPayment) {
    uid
  }
}`;

export const CONFIRM_DEBT_CONTACT_PAYMENT = gql`mutation confirmDebtContactPayment($debtContactPayment: String!) {
  confirmDebtContactPayment (debtContactPayment: $debtContactPayment) {
    uid
  }
}`;

export const DECLINE_DEBT_CONTACT = gql`mutation declineDebtContact($debtContact: String!) {
  declineDebtContact (debtContact: $debtContact) {
    uid
  }
}`;

export const VIEW_DEBT_CONTACT = gql`mutation viewDebtContact($debtContact: String!) {
    viewDebtContact (debtContact: $debtContact) {
        uid
    }
}`;

//endregion


export class DebtContactPayment {
  uid;
  amount;
  status;
  notes;
}

export class DebtContact {
  _id;
  uid;
  contact;
  amount;
  status;
  declined;
  payments;

  constructor(contact) {
    this.contact = contact;
    this.amount = contact.amount || 0;
  }
}

export class DebtContactInput {
  contact;
  amount = 0;

  constructor(debtContact) {
    this.contact = debtContact.contact._id;
    this.amount = debtContact.amount;
  }
}
