import {gql} from "apollo-boost";

export const GET_USER_META = gql`{
  user {
    user,
    name,
    phone,
    email,
    phoneVerified,
    emailVerified,
    language,
    currency,
    picture
  }
}`;

export const UPDATE_USER_META = gql`
mutation updateUser($user: UserInput!) {
  updateUser(user: $user) {
    user,
    name,
    phone,
    email,
    phoneVerified,
    emailVerified,
    language,
    currency,
    picture
  }
}`;

export const CREATE_USER_VERIFICATION = gql`
mutation createUserVerification($type: EnumUserVerificationType!) {
  createUserVerification(type: $type) {
    user,
    name,
    phone,
    email,
    language,
    currency,
    phoneVerified,
    emailVerified,
  }
}`;

export const VERIFY_USER_CODE = gql`
mutation verifyUserCode($code: String!) {
  verifyUserCode(code: $code) {
    user,
    name,
    phone,
    email,
    language,
    currency,
    phoneVerified,
    emailVerified,
  }
}`;

export const UPLOAD_USER_PICTURE = gql`
mutation UploadPictureUser($image: Upload!) {
  uploadPictureUser(image: $image)
}`;

export class UserInput {
  name;
  phone;
  language;
  currency;
  picture;

  constructor(userInput) {
    this.name = userInput && userInput.name;
    this.phone = userInput && userInput.phone;
    this.language = userInput && userInput.language;
    this.currency = userInput && userInput.currency;
    this.picture = userInput && userInput.picture;
  }
}

export default class User {
  _id;
  name = '';
  phone = '';
  email = '';
  language;
  currency;
  updatedAt;
  createdAt;
}
