import React, {useState} from 'react';
import {Paper, Fab} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import {grey} from '@material-ui/core/colors';
import ContactSelector from './ContactSelector';
import Amount from "./Amount";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    overscrollBehavior: 'none',
    width: '100%',
    maxWidth: 640
  },
  avatar: {
    width: 56,
    height: 56,
    backgroundColor: theme.palette.primary.light
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 20px',
  },
  fab: {
    boxShadow: 'none',
    backgroundColor: grey[100],
    color: grey[600],
    '&:hover': {
      backgroundColor: grey[200],
    }
  }
}));

type Props = {
  debtContacts: [],
  editSelection: boolean,
  onChange: ([])=>{}
}

const ContactSection = (props: Props) => {
  const {t} = useTranslation();
  const {onChange, debtContacts, editSelection = true} = props;
  const classes = useStyles();
  const [isContactSelectorOpen, setIsContactSelectorOpen] = useState(false);
  let scrollTimeStamp = 0;

  const handleSelection = (selection) => {
    onChange(selection);
    setIsContactSelectorOpen(false);
  };

  const handleHorizontalScroll = (event) => {
    const delta = Math.max(-1,
      Math.min(1, (event.nativeEvent.wheelDelta || -event.nativeEvent.detail))
    );
    let mult = 1;
    if (event.timeStamp - scrollTimeStamp < 1000) {
      mult = 1000 / (event.timeStamp - scrollTimeStamp);
    }
    scrollTimeStamp = event.timeStamp;
    event.currentTarget.scrollLeft -= (delta) * mult;
    event.preventDefault();
  };

  return (
    <Paper elevation={0}
           className={classes.root}
           onWheel={handleHorizontalScroll}>
      {
        debtContacts.map(debtContact => (
          <div key={debtContact.contact._id} style={{display: 'inline-block'}}>
            <Box className={classes.box}>
              {debtContact.contact.picture &&
              <Avatar className={classes.avatar} src={debtContact.contact.picture}/>}
              {!debtContact.contact.picture &&
              <Avatar className={classes.avatar}>{debtContact.contact.name.charAt(0)}</Avatar>}
              <Box m={0.5}/>
              <Typography color='textSecondary'
                          variant='subtitle2'>{debtContact.contact.name}</Typography>
              <Amount variant='caption'>{debtContact.amount}</Amount>
            </Box>
          </div>
        ))
      }
      {editSelection &&
      <div style={{display: 'inline-block'}}>
        <Box className={classes.box}>
          <Fab aria-label="add contact" className={classes.fab}
               onClick={() => setIsContactSelectorOpen(true)}>
            {!!!debtContacts.length && <AddIcon/>}
            {!!debtContacts.length && <EditIcon/>}
          </Fab>
          <Box m={0.5}/>
          <Typography color='textSecondary' variant='subtitle2'>
            {debtContacts.length ? t('common.editSelection') : t('common.addContacts')}
          </Typography>
        </Box>
      </div>}
      <ContactSelector open={isContactSelectorOpen}
                       iSelection={debtContacts}
                       onClose={() => setIsContactSelectorOpen(false)}
                       onSelect={handleSelection}/>
    </Paper>
  );

};

export default ContactSection;
