import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import Switch from "@material-ui/core/Switch";
import {useTranslation} from "react-i18next";

type Props = {
  checked: boolean,
  onChange: ()=>void
}
const AmountPerType = (props: Props) => {
  const {checked, onChange} = props;
  const {t} = useTranslation();

  return (
    <Grid item container alignItems='center' justify='center'>
      <Grid item>
        <Typography>{t('common.contact')}</Typography>
      </Grid>
      <Grid item>
        <Switch
          color='primary'
          checked={checked}
          onChange={onChange}
          value="AmountPerType"
          inputProps={{'aria-label': 'secondary checkbox'}}
        />
      </Grid>
      <Grid item>
        <Typography color='primary'>Total</Typography>
      </Grid>
      <Grid item>
        <Typography color='textSecondary' style={{marginTop: 6}}>
          <Tooltip title={t('debts.editor.toggle')}>
            <Icon style={{transform: 'rotate(180deg)', marginLeft: 15}}>error_outline</Icon>
          </Tooltip>
        </Typography>

      </Grid>
    </Grid>
  );
};

export default AmountPerType;
