import React, {useContext, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Badge, Box, Fab, Icon} from '@material-ui/core';
import {DatePicker} from '@material-ui/pickers';

import {AppContext} from '../../../app/AppContext';
import {GET_DEBTS, GET_DEBTS_REQUESTS} from '../../../common/models/Debt';
import {GET_EVENTS} from '../../../common/models/Event';
import {GET_SCHEDULES} from '../../../common/models/Schedule';
import {Content} from '../../../common/components/Content';
import Alert from '../../../common/components/Alert';

import ReceiptDialog from './components/ReceiptDialog';
import LatestActivity from './components/LatestActivity';
import PendingDebts from './components/PendingDebts';
import {useDashboardStyles} from './DashboardStyles';
import PendingBalance from './components/PendingBalance';
import PendingRequests from "./components/PendingRequests";
import ActionsNeeded from "./components/ActionsNeeded";
import Verify from "./components/Verify";

const Dashboard = (props) => {
  const {history} = props;
  const classes = useDashboardStyles();
  const {
    data: schedulesData
  } = useQuery(GET_SCHEDULES);
  const {
    loading: loadingDebts,
    data: debtsData
  } = useQuery(GET_DEBTS, {variables: {active: true}});
  const {
    loading: loadingRequests,
    data: reqData
  } = useQuery(GET_DEBTS_REQUESTS);
  const {
    loading: loadingActivities,
    data: activityData
  } = useQuery(GET_EVENTS, {variables: {full: true, limit: 3}});

  const schedules = schedulesData && schedulesData.schedules;
  const debts = debtsData && debtsData.debts && debtsData.debts.items;
  const debtsRequests = reqData && reqData.debtsRequests && reqData.debtsRequests.items;
  const events = activityData && activityData.events && activityData.events.items;

  const {state} = useContext(AppContext);
  const [pendingDebtsBalance, setPendingDebtsBalance] = useState(0);
  const balance = pendingDebtsBalance;

  const [date, setDate] = useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const {t} = useTranslation();

  const getScheduledDebts = (day) => {
    let scheduledDebts = 0;
    const currentDay = parseInt(day.format('D'));
    const currentMonth = parseInt(day.format('M'));

    if (schedules) {
      schedules.forEach(schedule => {

        switch (schedule.recurrence.mode) {
          case 0:
            const rDate = moment(schedule.recurrence.data);
            const rDay = parseInt(rDate.format('D'));
            const rMonth = parseInt(rDate.format('M'));
            if (rDay === currentDay && rMonth === currentMonth) {
              scheduledDebts++;
            }
            break;
          case 1:
            if (parseInt(schedule.recurrence.data) === currentDay) {
              scheduledDebts++;
            }
            break;
          case 2:
            if (parseInt(schedule.recurrence.data) === currentDay ||
              parseInt(schedule.recurrence.data) + 15 === currentDay) {
              scheduledDebts++;
            }
            break;
          default:
            break;
        }

      });
    }

    return scheduledDebts;
  };

  const addNew = (route) => {
    history.push(`/user/${route}/new`);
  };

  return (
    <Content>
      <Grid container
            style={{maxWidth: 1024, margin: 'auto'}}>

        <Grid item xs
              container
              direction='column'
              spacing={4}
              style={{padding: '0 20px 0 10px'}}>

          <Grid item>
            <Typography variant='h5'>
              {t('common.welcome')}, {state.user.name}
            </Typography>
          </Grid>

          <PendingBalance loading={loadingDebts} balance={balance}/>

          <ActionsNeeded loading={loadingDebts}
                         debts={debts}/>

          <PendingDebts loading={loadingDebts}
                        debts={debts}
                        onBalanceChange={setPendingDebtsBalance}/>

          <PendingRequests loading={loadingRequests}
                           debts={debtsRequests}/>


          <LatestActivity loading={loadingActivities} events={events}/>

        </Grid>

        <Grid item container xs={5}
              direction='column'
              spacing={2} style={{padding: '0 10px 0 20px'}}>

          <Grid item>
            <Typography variant='h6'>{t('home.addNew')}</Typography>
          </Grid>
          <Grid item container>
            <Grid item xs style={{textAlign: 'center'}}>
              <Fab color='primary'
                   onClick={() => addNew('debts')}>
                <Icon>checked</Icon>
              </Fab>
              <Box mt={1}/>
              <Typography>{t('common.debt')}</Typography>
            </Grid>
            <Grid item xs style={{textAlign: 'center'}}>
              <Fab aria-label='add contact' color='primary'
                   onClick={() => addNew('schedules')}>
                <Icon>calendar_today</Icon>
              </Fab>
              <Box mt={1}/>
              <Typography>{t('common.schedule')}</Typography>
            </Grid>
            <Grid item xs style={{textAlign: 'center'}}>
              <Fab aria-label='add contact' color='primary'
                   onClick={() => setOpen(true)}>
                <Icon>receipt</Icon>
              </Fab>
              <Box mt={1}/>
              <Typography>{t('common.receipt')}</Typography>
            </Grid>
          </Grid>

          <Verify/>

          <Grid item>
            <Box mt={3}/>
            <Typography variant='h6'>{t('home.scheduledDebts')}</Typography>
            <Box mt={1}/>
          </Grid>
          <Grid item container alignItems='center' justify='center' className={classes.calendar}>
            <DatePicker
              variant='static'
              openTo='date'
              value={date}
              onChange={setDate}
              minDate={new Date()}
              renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {

                const scheduledDebts = getScheduledDebts(day);

                return <Badge
                  className={!!scheduledDebts && isInCurrentMonth ? classes.badge : undefined}
                  badgeContent={!!scheduledDebts && isInCurrentMonth ? scheduledDebts : undefined}>
                  {dayComponent}
                </Badge>;
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <ReceiptDialog show={open} onClose={() => setOpen(false)}/>

      <Alert show={showErrorDialog}
             okAction={() => setShowErrorDialog(false)}
             title={t('home.error.title')}>
        <Typography>{t('home.error.description')}</Typography>
      </Alert>
    </Content>
  );
};

export default Dashboard;
