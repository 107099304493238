import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import {makeStyles} from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Button from "@material-ui/core/Button";
import grey from '@material-ui/core/colors/grey';

import logoImg from "../common/images/debtrail.png";
import {Groups, Routes} from '../views/user/routes';
import useRouter from "../common/hooks/useRouter";

const useNavigatorAppStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-elevation0': {
      borderTop: 'none',
      borderLeft: 'none'
    }
  },
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  categoryHeaderPrimary: {
    color: '',
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.black,
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: '#5F6368',
  },
  itemCategory: {
    backgroundColor: '#fafafa',
    boxShadow: '0 -1px 0 #ddd inset',
    paddingTop: 16,
    paddingBottom: 16,
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.18)',
    },
  },
  itemActiveItem: {
    color: theme.palette.primary.main,
  },
  itemPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize,
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  link: {
    textDecoration: 'none'
  },
  drawerContainer: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  appName: {
    height: '65px',
    backgroundColor: '#fff'
  },
  appLogo: {
    height: 32,
    width: 32,
    margin: theme.spacing(2),
  }
}));

function Navigator(props) {
  const classes = useNavigatorAppStyles();
  const {location} = useRouter();

  const isRouteActive = (routePath, exact = false) => {
    if (exact) {
      return location.pathname === routePath;
    } else {
      return location.pathname.indexOf(routePath) > -1;
    }
  };

  return (
    <Drawer variant="persistent" {...props} className={classes.root}>
      <div className={classes.drawerContainer}>
        <List disablePadding>
          <ListItem
            className={classNames(classes.firebase, classes.item, classes.itemCategory, classes.appName)}>
            <img alt='' className={classes.appLogo} src={logoImg}/>
            Debtrail
          </ListItem>
          {Routes.map(((route, rIndex) => {
            if (route.group === 'Home') {
              return <Link to={route.path} className={classes.link}
                           key={rIndex}>
                <ListItem
                  className={classNames(classes.item, classes.itemCategory,
                    classes.itemActionable,
                    isRouteActive(route.path, route.exact) && classes.itemActiveItem)}>
                  <ListItemIcon><Icon>{route.icon}</Icon></ListItemIcon>
                  <ListItemText classes={{primary: classes.itemPrimary}}>
                    {route.name}
                  </ListItemText>
                </ListItem>
              </Link>
            }
            return null;
          }))}

          {Groups.map((group, gIndex) => (
            <Fragment key={gIndex}>
              <ListItem className={classes.categoryHeader}>
                <ListItemText
                  classes={{primary: classes.categoryHeaderPrimary}}>
                  {group.name}
                </ListItemText>
              </ListItem>
              {Routes.map((route, rIndex) => (
                <Fragment key={rIndex}>
                  {route.group === group.name && route.showOnSidebar &&
                  <Link to={route.path} className={classes.link}>
                    <ListItem button
                              dense
                              className={classNames(
                                classes.item,
                                classes.itemActionable,
                                isRouteActive(route.path) && classes.itemActiveItem)}
                    >
                      <ListItemIcon><Icon>{route.icon}</Icon></ListItemIcon>
                      <ListItemText classes={{
                        primary: classes.itemPrimary,
                        textDense: classes.textDense
                      }}>
                        {route.name}
                      </ListItemText>
                    </ListItem>
                  </Link>}
                </Fragment>
              ))}
              <Divider className={classes.divider}/>
            </Fragment>
          ))}
        </List>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '0 10px'
        }}>
          <Button style={{color: grey[50]}} onClick={props.onClose}>
            <Icon style={{marginRight: 10}}>keyboard_arrow_left</Icon></Button>
        </div>
      </div>
    </Drawer>
  );
}

// $FlowFixMe
export default Navigator;
